import React from "react"
import { observer } from "mobx-react"
import {
  VENDOR_SETUP_URL,
  VENDOR_SETUP_DETAIL_URL,
  VENDOR_SETUP_PACKAGE_URL,
  VENDOR_SETUP_UPLOAD_URL,
  VENDOR_SETUP_LISTING_DETAIL_URL,
} from "../../utils/constants"
import PrivateRoute from "../../components/PrivateRoute"
import PublicRoute from "../../components/PublicRoute"
import AccountSignup from "./AccountSignup"
import Details from "./Details"
import Uploads from "./Uploads"
import ListingDetails from "./ListingDetails"
import { ParamVendorProvider } from "../../Providers"
import VendorPackage from "./VendorPackage"
import { withProvider } from "../../Providers/RouterProviders"

const VendorSetup = observer(() => {
  return (
    <>
      <PublicRoute exact path={VENDOR_SETUP_URL} component={AccountSignup} />
      <PrivateRoute exact path={VENDOR_SETUP_DETAIL_URL} component={Details} />
      <PrivateRoute exact path={VENDOR_SETUP_PACKAGE_URL} component={Details} />
      <PrivateRoute
        exact
        path={VENDOR_SETUP_UPLOAD_URL(":id")}
        component={withProvider(Uploads, ParamVendorProvider)}
      />
      <PrivateRoute
        exact
        path={VENDOR_SETUP_LISTING_DETAIL_URL(":id")}
        component={withProvider(ListingDetails, ParamVendorProvider)}
      />
      <VendorPackage />
    </>
  )
})

export default VendorSetup
