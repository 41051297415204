import React, { useEffect, useState } from "react"
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core"
import moment from "moment"
import { debounce } from "lodash"
import { GridColumns } from "@mui/x-data-grid"
import { VendorAvatar } from "../../../components/shared/UserAvatar"
import { IVendor, Vendor } from "../../../stores"
import { del, get, hashToParamString, put } from "../../../utils"
import AppMenu from "../../../components/shared/AppMenu"
import AppTable from "../../../components/shared/AppTable"
import AppDialog from "../../../components/shared/AppDialog"
import { ISearchFilter, ItemAttribute, PAGE_LIMIT_OPTION } from "../"
import { Images } from "../../../components/Images"
import { toast } from "react-toastify"
import { VendorForm } from "./VendorForm"

type Props = {
  limit?: number
  height?: number | string
}

const Actions = ({ item, onReload }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  return (
    <>
      <AppDialog
        open={showDetails}
        title='Vendor Details'
        onClose={() => setShowDetails(false)}
      >
        <VendorDetail vendor={item} />
      </AppDialog>
      <AppDialog
        open={showForm}
        title='Edit'
        onClose={() => setShowForm(false)}
      >
        <VendorForm
          item={item}
          onSubmit={(vendor) => {
            put(`/api/vendors/${item.id}`, vendor, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then(() => {
              setShowForm(false)
              onReload()
            })
          }}
        />
      </AppDialog>
      <AppMenu
        options={[
          {
            label: "Show Detail",
            onSelect: () => {
              setShowDetails(true)
            },
          },
          {
            label: "Edit",
            onSelect: () => {
              setShowForm(true)
            },
          },
          {
            label: "Remove",
            onSelect: () => {
              const confirmed = confirm(
                `Are you sure you want to remove ${item.name}?`
              )
              confirmed &&
                del(`/api/vendors/${item.id}`)
                  .then(onReload)
                  .then(() => toast("Removed"))
            },
          },
        ]}
      />
    </>
  )
}
export default function Vendors({
  limit = PAGE_LIMIT_OPTION[1],
  height,
}: Props) {
  const [rows, setRows] = useState<FIXME[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [filter, setFilter] = useState<ISearchFilter>({
    page: 0,
    page_size: limit,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const loadList = debounce(() => {
    if (loading) {
      return
    }
    setLoading(true)
    get(`/api/admins/vendors?${hashToParamString(filter)}`)
      .then(({ data: { list, row_count } }) => {
        setRowCount(row_count)
        setRows(
          list
            .map((item) => Vendor.create(item))
            .map((item) => ({
              id: item.id,
              name: item.name,
              vendor_type_name: item.vendor_type_name,
              created_at: moment(item.created_at).fromNow(),
              item,
            }))
        )
        setLoading(false)
      })
      .catch((err) => {
        console.log({ err })
        setLoading(false)
      })
  }, 500)

  useEffect(() => {
    loadList()
  }, [filter])

  const updateFilter = (name, value) => {
    if (loading) {
      return
    }
    setFilter({ ...filter, [name]: value })
  }

  const handleSortChange = (model, details) => {
    if (loading) {
      return
    }
    setFilter({
      ...filter,
      order_by: model[0].field,
      order_direction: model[0].sort,
    })
  }
  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    { field: "name", headerName: "Name", minWidth: 150 },
    { field: "vendor_type_name", headerName: "Type", minWidth: 150 },
    { field: "created_at", headerName: "Created", minWidth: 150 },
    {
      field: "actions",
      headerName: " ",
      minWidth: 150,
      renderCell: ({ row: { item } }) => (
        <Actions item={item} onReload={loadList} />
      ),
    },
  ]

  return (
    <>
      <AppTable
        pagination
        rowCount={rowCount}
        page={filter.page}
        pageSize={filter.page_size}
        rowsPerPageOptions={PAGE_LIMIT_OPTION}
        onPageSizeChange={(pageSize: number, details?: any) =>
          updateFilter("page_size", pageSize)
        }
        onPageChange={(page) => {
          updateFilter("page", page)
        }}
        rows={rows}
        columns={columns}
        onSortModelChange={handleSortChange}
        filterMode='server'
        paginationMode='server'
        sortingMode='server'
        loading={loading}
        {...(height ? { style: { height } } : {})}
      />
    </>
  )
}

const VendorDetail = ({ vendor }: { vendor: IVendor }) => {
  const classes = useStyles()

  return (
    <Grid item>
      <Grid container spacing={2} direction='column'>
        {[
          "id",
          "name",
          "description",
          "address_1",
          "address_2",
          "city",
          "state",
          "zip",
          "created_at",
          "updated_at",
          "kind",
          "comments_count",
          "lat",
          "latitude",
          "listing_title",
          "long",
          "longitude",
          "place_id",
          "ratings_amount",
          "ratings_count",
          "tag_list",
          "vibe",
        ].map((name) => (
          <ItemAttribute label={name} value={vendor[name]} />
        ))}
        {/* <Grid item>{vendor.id}</Grid> */}
        <Grid item>
          <Images imageable={vendor} />
        </Grid>
        <Grid item>{vendor.name}</Grid>
        <Grid item className={classes.description}>
          {vendor.description}
        </Grid>
        <Grid item>
          Created {moment(vendor.created_at).fromNow()}
          {/* by */}
          {/* {vendor.owners.map((o) => o.user_name).join(", ")} */}
        </Grid>
      </Grid>
    </Grid>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      minHeight: "3.5rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  })
)
