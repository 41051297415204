import React, { useContext } from "react"
import { observer } from "mobx-react"
import { useHistory } from "react-router-dom"

import {
  AppBar,
  Badge,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
  InputBase,
  Button,
  Avatar,
} from "@material-ui/core"

import { flow } from "lodash"
import { AccountCircle } from "@material-ui/icons"
import { AuthContext, useAuth } from "../../Providers"
import {
  DIY_EVENTS_URL,
  PROFILE_URL,
  VENDORS_URL,
  VENDOR_PACKAGE_REQUESTS_URL,
  EVENT_DASHBOARD_URL,
  DASHBOARD_URL,
} from "../../"
import { ADMIN_DASHBOARD_URL, VENDOR_DASHBOARD_URL } from "../../utils"

export const UserNav = observer(({}) => {
  const { loggedIn, loginProfile, logout } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const history = useHistory()
  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const goTo = (url) => () => {
    history.push(url)
    handleMenuClose()
  }

  return (
    <>
      <Button
        startIcon={
          loginProfile.avatar_url ? (
            <Avatar src={loginProfile.avatar_url} />
          ) : (
            <AccountCircle />
          )
        }
        onClick={handleProfileMenuOpen}
      >
        {loginProfile && <Typography>{loginProfile.fullName()}</Typography>}
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {loginProfile &&
          loggedIn && [
            <MenuItem onClick={goTo(PROFILE_URL)} key='my account'>
              My account
            </MenuItem>,
            ...(loginProfile.role === "admin"
              ? [
                  <MenuItem onClick={goTo(ADMIN_DASHBOARD_URL)} key='admin'>
                    Admin
                  </MenuItem>,
                ]
              : []),
            <MenuItem onClick={goTo(DASHBOARD_URL)} key='Dashboard'>
              Dashboard
            </MenuItem>,
            ...(loginProfile.diy_events_count > 0
              ? [
                  // <MenuItem
                  //   onClick={goTo(EVENT_DASHBOARD_URL)}
                  //   key='Event Dashboard'
                  // >
                  //   Event Dashboard
                  // </MenuItem>,
                  <MenuItem onClick={goTo(DIY_EVENTS_URL)} key='Events'>
                    Events
                  </MenuItem>,
                ]
              : []),
            // ...(loginProfile.vendors_count > 0
            //   ? [
            //       <MenuItem
            //         onClick={goTo(VENDOR_DASHBOARD_URL)}
            //         key='Vendor Dashboard'
            //       >
            //         Vendor Dashboard
            //       </MenuItem>,
            //     ]
            //   : []),
            <MenuItem
              onClick={goTo(VENDOR_PACKAGE_REQUESTS_URL)}
              key='Vendor Packages'
            >
              Requests
            </MenuItem>,
            <MenuItem onClick={goTo(VENDORS_URL)} key='Vendors'>
              Vendors
            </MenuItem>,
            <MenuItem
              onClick={() => {
                logout()
                handleMenuClose()
                // history.push("/")
              }}
              key='logout'
            >
              Logout
            </MenuItem>,
          ]}
        {!loggedIn && [
          <MenuItem onClick={handleMenuClose} key='login'>
            Login
          </MenuItem>,
          <MenuItem onClick={handleMenuClose} key='signup'>
            Signup
          </MenuItem>,
        ]}
      </Menu>
    </>
  )
})

// export default flow(observer)(UserNav)
