import React from "react"
import {
  ROOT_URL,
  GROUPED_VEDNDORS_URL,
  PLACEHOLDER_LANDING_PAGE_URL,
} from "../../utils/constants"
import PublicRoute from "../../components/PublicRoute"
import Home from "./Home"
import PlaceholderLanding from "./PlaceholderLanding"
import GroupedVendors from "./GroupedVendors"
import { withProvider } from "../../Providers/RouterProviders"
import { VendorTypesProvider } from "../../Providers/VendorTypesProvider"

const HomeRoutes = () => {
  return (
    <>
      <PublicRoute
        exact
        path={PLACEHOLDER_LANDING_PAGE_URL}
        component={PlaceholderLanding}
        providers={[VendorTypesProvider]}
      />
      <PublicRoute exact path={ROOT_URL} component={Home} />
      <PublicRoute
        exact
        path={GROUPED_VEDNDORS_URL}
        component={GroupedVendors}
        providers={[VendorTypesProvider]}
      />
    </>
  )
}

export default HomeRoutes
