import React from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Card, CardContent, Typography } from "@material-ui/core"
import { IComment } from "../../stores/helpers"
import ReplyIcon from "@material-ui/icons/Reply"
import Comments from "./Comments"
const CommentListItem = ({ comment, replyable = true }: Props) => {
  const classes = useStyles()
  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.details}>
        {/* <CardContent className={classes.content}> */}
        <Typography variant='body1'>{comment.user_name}</Typography>
        <Typography variant='caption'>
          {comment.createdAt.fromNow()}
          {/* / rating: {comment.rating} */}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {comment.body}
        </Typography>
        {/* </CardContent> */}
        {replyable && (
          <div className={classes.controls}>
            <Comments
              commentable={comment}
              label={<ReplyIcon />}
              preLoad={false}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

interface Props {
  comment: IComment
  replyable?: boolean
}

export default CommentListItem

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // minWidth: "25em",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
)
