import { Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { Route } from "react-router-dom"
import { useAuth } from "../Providers"
import Login from "./Login"
import { AppPageContent } from "./shared"
import { ProgressLoader } from "./shared/ProgressLoader"

const PrivateRoute = ({ role = "user", component: Component, ...rest }) => {
  const { loggedIn, loginProfile, loaded } = useAuth()

  if (!loaded) {
    return <></>
  }

  if (!loggedIn) {
    return <></>
  }

  const isAllowed =
    role === "user"
      ? true
      : role === "admin" && loginProfile.role === "admin"
      ? true
      : false
  return (
    <Route
      {...rest}
      render={() => (isAllowed ? <Component /> : <NotAuthorized />)}
    />
  )
}
export default PrivateRoute

const NotAuthorized = () => (
  <AppPageContent>
    <Typography variant='h5' align='center'>
      <p>Oops...</p>
      <p>Looks like you hit a wall.</p>
      <p>You need more access.</p>
    </Typography>
  </AppPageContent>
)
