import React, { useContext, useEffect, useState } from "react"
import { IDiyEvent } from "../stores/DiyEvents"
import { useParams, withRouter } from "react-router"
import { useAuth, DiyEventsContext } from "./"

export const ParamDiyEventProvider = withRouter(({ children }) => {
  const { loadDiyEvent } = useContext(DiyEventsContext)
  const [diy_event, setdiy_event] = useState<IDiyEvent>()
  const [loadingDiyEvent, setloading] = useState(false)
  const { loginProfile } = useAuth()

  let { id: idString } = useParams<{ id: string }>()
  const id = idString && parseInt(idString, 10)
  const isOwner = !!(diy_event && loginProfile) // &&
  // diy_event.owners.map<string | number>((o) => o.user_id)
  //   .includes(loginProfile.id)

  console.log({ id, idString })
  useEffect(() => {
    if (!loadingDiyEvent && loadDiyEvent) {
      setloading(true)
      loadDiyEvent(id).then((v) => {
        v && setdiy_event(v)
        setloading(false)
      })
    }
  }, [])

  return (
    <ParamDiyEventContext.Provider
      value={{ loadingDiyEvent, diy_event, isOwner }}
    >
      {children}
    </ParamDiyEventContext.Provider>
  )
})

export default ParamDiyEventProvider
interface ContextProps {
  loadingDiyEvent?: boolean
  diy_event: IDiyEvent
  isOwner: boolean
}
export const ParamDiyEventContext = React.createContext<ContextProps>(undefined)
export const useParamDiyEvent = () => useContext(ParamDiyEventContext)
