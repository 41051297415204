import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Container,
  Paper,
} from "@material-ui/core"
import { IVendor } from "../../stores/Vendors"
import { useParams, useHistory } from "react-router"
import Comments from "../../components/Comments/Comments"
import VendorPackages from "./VendorPackages/VendorPackages"
import { Images, DetailImages } from "../../components/Images"
import { useAuth, VendorsContext } from "../../Providers"
import EditIcon from "@material-ui/icons/Edit"
import ReactMarkdown from "react-markdown"
import { VENDOR_EDIT_URL } from "../.."
import { IUserVendor } from "../../stores"
import { MessagableUser } from "../../components/shared"
import OrderWindow from "./OrderWindow"
import { useParamVendor, NewConversationsContext } from "../../Providers"
import { observer } from "mobx-react"
// import { Loader } from "@googlemaps/js-api-loader"
import GoogleMapReact from "google-map-react"
// import Axios from "axios"
// import { get } from "../../utils"
import RoomIcon from "@material-ui/icons/Room"
import {
  VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL,
  VENDOR_SETUP_LISTING_DETAIL_URL,
  VENDOR_SETUP_UPLOAD_URL,
  VENDOR_URL,
} from "../../utils"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { ProgressLoader } from "../../components/shared/ProgressLoader"
import AddIcon from "@material-ui/icons/Add"
import AppMap from "../../components/shared/AppMap"

const GOOGLE_API_KEY = "AIzaSyDWU1mx7B51W1vnayMdukFLJxk_7NMJHeQ"
const AnyReactComponent = ({ lat, lng }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-30%, -100%)",
    }}
  >
    <RoomIcon />
  </div>
)

// const getLongLat = (address) => {
//   // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=GOOGLE_API_KEY
//   return Axios.get(
//     `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`
//   )
// }

const VendorImages = ({
  vendor,
  isOwner,
}: {
  vendor: IVendor
  isOwner?: boolean
}) => {
  const history = useHistory()
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DetailImages imageable={vendor} deletable={isOwner} />
        {/* <Button
          onClick={() => history.push(VENDOR_SETUP_UPLOAD_URL(vendor.id))}
        >
          Upload
        </Button> */}
      </Grid>
    </Grid>
  )
}
const VendorLocation = ({ vendor }: { vendor: IVendor }) => {
  const { t } = useTranslation()
  if (!vendor.latitude || !vendor.longitude) {
    return <></>
  }
  const defaultProps = {
    center: {
      lat: parseFloat(vendor?.latitude),
      lng: parseFloat(vendor?.longitude),
    },
    zoom: 14,
  }
  return (
    <div>
      <Typography variant='h5'>{t("common.location")}</Typography>
      {vendor.latitude && vendor.longitude && (
        <div style={{ height: "19rem", width: "100%" }}>
          <AppMap {...defaultProps} />
        </div>
      )}
    </div>
  )
}

const AppMetaTag = ({
  title,
  description,
  image,
  author,
  link,
}: {
  title?: string
  description?: string
  image?: string
  author?: string
  link?: string
}) => {
  return (
    <Helmet titleTemplate='Zinnia - %s'>
      {title && <title>{title}</title>}
      <meta name='og:site_name' content={"Zinnia"} />
      {title && <meta name='title' content={`Zinnia - ${title}`} />}
      {title && <meta name='og:title' content={`Zinnia - ${title}`} />}
      {description && <meta name='description' content={description} />}
      {description && <meta name='og:description' content={description} />}
      {image && (
        <meta name='og:image' content={`${window.app_domain}${image}`} />
      )}
      {image && <meta name='image' content={`${window.app_domain}${image}`} />}

      {author && <meta name='author' content={author} />}
      {link && <link rel='canonical' href={`${window.app_domain}${link}`} />}
      {link && <meta name='og:url' content={`${window.app_domain}${link}`} />}
    </Helmet>
  )
}

export const Vendor = observer(() => {
  const { loadVendor } = useContext(VendorsContext)
  // const [vendor, setvendor] = useState<IVendor>()
  // const [loading, setloading] = useState(false)
  const history = useHistory()
  const { vendor, loadingVendor: loading, isOwner } = useParamVendor()
  const auth = useAuth()
  console.log({ auth, vendor, loading })

  // useEffect(() => {
  //   if (vendor) {
  //     let lookupAddress = (vendor.address_1 || "").replace(" ", "+")
  //     if (vendor.city) {
  //       lookupAddress += `,+${(vendor.city || "").replace(" ", "+")}`
  //     }
  //     if (vendor.state) {
  //       lookupAddress += `,+${(vendor.state || "").replace(" ", "+")}`
  //     }
  //     if (vendor.zip) {
  //       lookupAddress += `,+${(vendor.zip || "").replace(" ", "+")}`
  //     }
  //     // getLongLat(lookupAddress)
  //     //   .then((resp) => console.log({ resp }))
  //     //   .then((error) => console.log({ error }))
  //   }
  // }, [vendor])

  console.log({ vendor })

  if (!vendor) {
    return <ProgressLoader />
  }

  // let { id } = useParams<{ id: string }>()
  // useEffect(() => {
  //   if (!loading && loadVendor) {
  //     setloading(true)
  //     loadVendor(parseInt(id, 10)).then((v) => {
  //       v && setvendor(v)
  //       setloading(false)
  //     })
  //   }
  // }, [])

  // const loader = new Loader({
  //   apiKey: "AIzaSyAo4NwGMeOBH5RJU3DDdLKjkTXIoP7S-os",
  //   version: "weekly",
  //   // ...additionalOptions,
  // });

  // loader.load().then(() => {
  //   const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
  //     center: { lat: -34.397, lng: 150.644 },
  //     zoom: 8,
  //   });
  // });

  return (
    <Container style={{ maxWidth: "1130px" }}>
      <AppMetaTag
        title={vendor?.name}
        description={vendor?.name}
        image={vendor?.images[0]?.url}
        author={vendor?.owners[0]?.user_name}
        link={VENDOR_URL(vendor.id)}
      />

      <Button onClick={() => history.goBack()}>Back</Button>
      <div>
        {loading || !vendor ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VendorImages vendor={vendor} isOwner={isOwner} />
              {isOwner && (
                <IconButton
                  aria-label='edit'
                  onClick={() =>
                    history.push(VENDOR_SETUP_UPLOAD_URL(vendor.id))
                  }
                >
                  <EditIcon />
                </IconButton>
              )}
              {/* <Images imageable={vendor} sm={3} variant='detail' /> */}
              {/* <Button
                onClick={() =>
                  history.push(VENDOR_SETUP_UPLOAD_URL(vendor.id))
                }
              >
                Upload
              </Button> */}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant='h4'>{vendor?.name}</Typography>
                      {isOwner && (
                        <IconButton
                          aria-label='edit'
                          onClick={() =>
                            history.push(VENDOR_EDIT_URL(vendor.id))
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Grid>
                    {/* <Grid item xs={12}>
                    Rating: {vendor?.average_rating}
                  </Grid> */}
                    <Grid item>
                      <Typography variant='body1' component='span'>
                        <ReactMarkdown>{vendor?.description}</ReactMarkdown>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      Vendor{vendor.owners.length > 1 && "s"}:{" "}
                      {vendor.owners.map((o) => (
                        <MessagableUser key={o.id} user={o} />
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <VendorPackages vendor={vendor} />

                      {isOwner && (
                        <>
                          <Button
                            endIcon={<EditIcon />}
                            onClick={() =>
                              history.push(
                                VENDOR_SETUP_LISTING_DETAIL_URL(vendor.id)
                              )
                            }
                          >
                            Edit Listing
                          </Button>

                          <Button
                            endIcon={<AddIcon />}
                            onClick={() =>
                              history.push(
                                VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL(vendor.id)
                              )
                            }
                          >
                            Add Service
                          </Button>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <VendorLocation vendor={vendor} />
                    </Grid>

                    {/* <Grid item xs={12}>
                    {vendor && (
                      <Comments
                        commentable={vendor}
                        label='Comment on Vendor'
                      />
                    )}
                  </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <OrderWindow />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  )
})

export default Vendor
