import { types, Instance } from "mobx-state-tree"
import { toast } from "react-toastify"
import i18n, { languageOptions } from "../utils/i18n"
import moment from "moment"
import localResource from "../utils/locale"

// const AppMessage = types.model("AppMessage", {
//   id: types.identifierNumber,
//   content: types.string,
// })
const AppUtil = types
  .model("AppUtil", {
    identifier: types.optional(types.identifier, "AppUtil"),
    // list: types.optional(types.array(AppMessage), []),
    locale: types.optional(types.string, languageOptions[0].value),
  })
  // .actions((self) => ({
  //   removeMessage: (id) => {
  //     self.list.replace(self.list.filter((m) => m.id !== id))
  //   },
  // }))
  .actions((self) => ({
    changeLocale: (locale: string) => {
      i18n.changeLanguage(locale, (err, t) => {
        if (err) return console.log("something went wrong loading", err)
        if (localResource[locale].translation) {
          moment.updateLocale(
            locale,
            localResource[locale].translation.momentjs
          )
        }
        localStorage.setItem("APP_LOCALE", locale)
        self.locale = locale
      })
    },
    showMessage: (content: string) => {
      toast(content)
    },
    showError: (content: string) => {
      toast.error(content)
    },
    showSuccess: (content: string) => {
      toast.success(content)
    },
    afterCreate() {
      const locale = localStorage.getItem("APP_LOCALE")
      if (locale) {
        if (localResource[locale].momentjs) {
          moment.updateLocale(
            locale,
            localResource[locale].translation.momentjs
          )
        }
        i18n.changeLanguage(locale)
        self.locale = locale
      }
    },
  }))

// export interface IAppMessage extends Instance<typeof AppMessage> {}
export interface IAppUtil extends Instance<typeof AppUtil> {}
export default AppUtil
