import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"

interface IOptions {
  config?: AxiosRequestConfig
  skipAuth?: boolean
}

const DEFAULT_TIMEOUT = 10000
let _client: undefined | AxiosInstance
const STORAGE_LOGIN_PROFILE = "STORAGE_LOGIN_PROFILE"

//  Profile getter/setter
export const getLoginProfile = (): ILoginProfile | undefined | null =>
  JSON.parse(localStorage.getItem(STORAGE_LOGIN_PROFILE))
export const setLoginProfile = (data?: ILoginProfile | undefined | null) =>
  data
    ? localStorage.setItem(STORAGE_LOGIN_PROFILE, JSON.stringify(data))
    : localStorage.removeItem(STORAGE_LOGIN_PROFILE)

export function getClient(skipAuth?: boolean): AxiosInstance {
  if (!_client) {
    _client = axios.create({
      baseURL: "/",
      timeout: DEFAULT_TIMEOUT,
    })
    // // Add a request interceptor
    _client.interceptors.request.use(
      (config) => {
        if (!skipAuth) {
          const loginProfile = getLoginProfile()
          if (loginProfile) {
            config.headers["X-User-Email"] = loginProfile.email
            config.headers["X-User-Token"] = loginProfile.authentication_token
          }
        }
        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )
  }
  return _client
}

export function get<T = FIXME>(
  url: string,
  options: IOptions = {}
): AxiosPromise<T> {
  return getClient(options.skipAuth).get(url, options.config)
}

export function post<T = FIXME>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  options: IOptions = {}
): AxiosPromise<T> {
  return getClient(options.skipAuth).post(url, data, config)
}

export function put<T = FIXME>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): AxiosPromise<T> {
  return getClient().put(url, data, config)
}

export function del<T = FIXME>(
  url: string,
  config?: AxiosRequestConfig,
  options: IOptions = {}
): AxiosPromise<T> {
  return getClient(options.skipAuth).delete(url, config)
}

const api_http = {
  get,
  post,
  put,
  delete: del,
}

export default api_http
