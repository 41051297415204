import { inject, observer } from "mobx-react"
import React, { ReactElement } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Nav } from "./Nav"
import { flow } from "lodash"
import { Footer } from "./Footer"
import { createStyles, makeStyles, Theme } from "@material-ui/core"

interface Props {
  children: any
}

function AppContainer({ children }: Props): ReactElement {
  const classes = useStyles()
  return (
    <div>
      <Nav />
      <div className={classes.content}>{children}</div>
      <ToastContainer position={"bottom-right"} />
      <Footer />
    </div>
  )
}

export default flow(observer)(AppContainer)
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      minHeight: "calc(100vh - 29rem)",
      display: "flex",
      // justifyContent: ''
      // width: "100%",
      // justifyContent: ''
    },
  })
)
