import React from "react"
import { Provider } from "mobx-react"
import store from "../stores"
import Routes from "../routes"
import WebpackerReact from "webpacker-react"
import {
  AuthProvider,
  ToastProvider,
  VendorsProvider,
  VendorPackageRequestsProvider,
  DiyEventsProvider,
  ConversationsProvider,
} from "./"
// import { DiyEventsProvider } from "../components/DiyEvents"
// import { VendorPackageRequestsProvider } from "./components/VendorPackageRequests/VendorPackageRequestsProvider"
import { NotificationsProvider } from "../components/Notifications"
import { DefaultTheme } from "../themes/DefaultTheme"
import { ThemeProvider, Typography } from "@material-ui/core"
import { I18nextProvider } from "react-i18next"
import i18n from "../utils/i18n"

export const RootProvider = ({ children }) => (
  <I18nextProvider i18n={i18n}>
    <Provider {...store}>
      <ThemeProvider theme={DefaultTheme}>
        <Typography component='div'>
          <AuthProvider>
            <ToastProvider>
              <NotificationsProvider>
                <ConversationsProvider>
                  <VendorsProvider listName='root'>
                    <DiyEventsProvider listName='root'>
                      <VendorPackageRequestsProvider listName='root'>
                        {children}
                      </VendorPackageRequestsProvider>
                    </DiyEventsProvider>
                  </VendorsProvider>
                </ConversationsProvider>
              </NotificationsProvider>
            </ToastProvider>
          </AuthProvider>
        </Typography>
      </ThemeProvider>
    </Provider>
  </I18nextProvider>
)
