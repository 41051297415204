import { types, Instance } from "mobx-state-tree"
import { Commentable } from "../helpers/Commentable"
import { IdedObject } from "../helpers/IdedObject"
import { Imageable } from "../helpers/Imageable"
import { LoadableStatus } from "../helpers/LoadableStatus"

export const Notification = types.compose(
  "Notification",
  IdedObject,
  LoadableStatus,
  types.model({
    type: types.string,
    body: types.string,
  })
)

export interface INotification extends Instance<typeof Notification> {}
export default Notification
