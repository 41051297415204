import React from "react"
import { inject, observer } from "mobx-react"
import { IStore } from "../stores"
import { ILoginProfile, IUser } from "../stores/auth"
import { flow } from "lodash"
import { INotification, INotifications } from "../stores/Notifications"

const AuthProvider = (props: Props & PropsInjected) => {
  const {
    loaded,
    logout,
    loginProfile,
    loggedIn,
    login,
    children,
    loadNotifications,
    notifications,
    loginWithGoogle,
    subscribe,
  } = props

  return (
    <AuthContext.Provider
      value={{
        loaded,
        logout,
        loginProfile,
        loggedIn,
        login,
        loadNotifications,
        notifications,
        loginWithGoogle,
        subscribe,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

interface Props {
  children: React.ReactNode | React.ReactNode[]
}
interface PropsInjected {
  loaded: boolean
  loggedIn: boolean
  loginProfile: ILoginProfile
  notifications: INotification[]
  loadNotifications(listName?: string, args?: FIXME): Promise<FIXME>
  logout(): void
  loginWithGoogle(): Promise<FIXME>
  login: (email: string, password: string) => Promise<boolean>
  subscribe: (data: FIXME) => Promise<FIXME>
}

export const AuthContext = React.createContext<PropsInjected>(undefined)
export const useAuth = () => React.useContext(AuthContext)

export default flow(
  observer,
  inject<PropsInjected, Props>(
    ({
      auth: {
        loggedIn,
        logout,
        loginProfile,
        loaded,
        login,
        loginWithGoogle,
        subscribe,
      },
      notifications,
    }: IStore) => {
      return {
        loggedIn,
        loginProfile,
        logout,
        loaded,
        login,
        loginWithGoogle,
        notifications: notifications.list,
        loadNotifications: notifications.loadList,
        subscribe,
      }
    }
  )
)(AuthProvider)
