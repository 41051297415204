import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import React from "react"

type Props = {}

export default function OurStory({}: Props) {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Typography variant='h4'>Our Story</Typography>
      <Typography variant='h4'>...</Typography>
    </Container>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "1rem auto",
    },
  })
)
