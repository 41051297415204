import React from "react"

import { AppBackButton } from "../../components/shared"

export const pageWrapper =
  (Comp, Wrapper, title = undefined, extraProps = undefined) =>
  (props) => {
    return (
      <Wrapper {...props} title={title}>
        <AppBackButton />
        <Comp {...props} {...(extraProps ? extraProps : {})} />
      </Wrapper>
    )
  }
export default pageWrapper
