import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core"
import React from "react"
import CloseIcon from "@material-ui/icons/Close"

interface Props extends DialogProps {
  title: string
  actions?: FIXME
  onClose?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: string)
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
)
export const AppDialog = ({
  open,
  onClose,
  title,
  actions,
  children,
  ...rest
}: Props) => {
  const classes = useStyle()
  return (
    <Dialog maxWidth='md' fullWidth open={open} onClose={onClose} {...rest}>
      <DialogTitle disableTypography className={classes.root}>
        {title}
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={(e) => onClose(e, "backdropClick")}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && (
        <>
          <Divider style={{ margin: "2rem" }} />
          <DialogActions>{actions}</DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default AppDialog
