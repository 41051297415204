import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { IStore, IVendorPackageRequest } from "../stores"
import { flow } from "lodash"

export const VendorPackageRequestContext = React.createContext<
  IVendorPackageRequest | undefined
>(undefined)

interface Props {
  listName: string
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  loadVendorPackageRequestsList(
    name: string,
    args?: any
  ): Promise<IVendorPackageRequest[]>
  loadVendorPackageRequest(id: number): Promise<IVendorPackageRequest>
}

interface ContextProps {
  loadingVendorPackageRequests?: boolean
  vendor_package_requests: IVendorPackageRequest[]
  loadVendorPackageRequests(args?: any)
  loadVendorPackageRequest(id: number): Promise<IVendorPackageRequest>
}

const VendorPackageRequestsProviderBase = (props: Props & PropsInjected) => {
  const {
    loadVendorPackageRequest,
    loadVendorPackageRequestsList,
    listName,
    children,
  } = props
  const [vendor_package_requests, setvendor_package_requests] = useState<
    IVendorPackageRequest[]
  >([])
  const [loadingVendorPackageRequests, setLoadingVendorPackageRequests] =
    useState<boolean>(false)
  const loadVendorPackageRequests = (args) => {
    if (loadingVendorPackageRequests) {
      return
    }
    setLoadingVendorPackageRequests(true)
    loadVendorPackageRequestsList(listName, args)
      .then((list) => {
        setvendor_package_requests(list)
        setLoadingVendorPackageRequests(false)
      })
      .catch(() => {
        setLoadingVendorPackageRequests(false)
      })
  }

  return (
    <VendorPackageRequestsContext.Provider
      value={{
        vendor_package_requests,
        loadVendorPackageRequests,
        loadingVendorPackageRequests,
        loadVendorPackageRequest,
      }}
    >
      {children}
    </VendorPackageRequestsContext.Provider>
  )
}

export const VendorPackageRequestsContext =
  React.createContext<ContextProps>(undefined)

export const VendorPackageRequestsProvider = flow(
  observer,
  inject<PropsInjected, Props>(
    ({
      vendor_package_requests: {
        list,
        list_ids,
        loadList,
        loadingIds,
        loadedIds,
        loadById,
      },
    }: IStore) => {
      return {
        loadVendorPackageRequest: loadById,
        loadVendorPackageRequestsList: (listName, args) => {
          if (!loadingIds.includes(listName)) {
            return loadList(listName, args).then(() => {
              const ids = list_ids.get(listName) || []
              return list.filter((v) =>
                ids.includes(v.id)
              ) as IVendorPackageRequest[]
            })
          } else {
            const ids = list_ids.get(listName) || []
            return Promise.reject(
              list.filter((v) => ids.includes(v.id)) as IVendorPackageRequest[]
            )
          }
        },
      }
    }
  )
)(VendorPackageRequestsProviderBase)
