import { Button } from "@material-ui/core"
import React from "react"
import { useAuth } from "../../Providers"

type Props = {}

export default function GoogleLogin({}: Props) {
  const { loginWithGoogle } = useAuth()
  return (
    <Button
      variant='outlined'
      onClick={() => {
        // location.href = "/users/auth/google_oauth2"
        loginWithGoogle()
      }}
    >
      Login with Google
    </Button>
  )
}
