import { inject, observer } from "mobx-react"
import React, { Component } from "react"
import { Button, Card, CardContent, Container } from "@material-ui/core"
import { IStore } from "../../stores"
import { IUser } from "../../stores/auth"
import { withRouter, RouteComponentProps } from "react-router"
import { flow } from "lodash"
import { PROFILE_URL } from "../../utils"

interface Props {
  onUpdate()
  onCancel()
}
interface State {
  data: any
  errors?: string | string[]
}

class EditProfile extends Component<
  Props & PropsInjected & RouteComponentProps,
  State
> {
  state = {
    data: {},
    errors: undefined,
  }

  handleChange =
    (name: string) =>
    ({ target: { value } }) => {
      this.setState({ data: { ...this.state.data, [name]: value } })
    }

  handleUpdate = () => {
    const { updateProfile, showError, showSuccess, onUpdate, history } =
      this.props
    const { data } = this.state
    updateProfile(data)
      .then(() => {
        showSuccess("Profile Updated")
        onUpdate ? onUpdate() : history.push(PROFILE_URL)
      })
      .catch((errors) => {
        showError(errors)
      })
  }

  render() {
    const { errors } = this.state
    const { loginProfile, onCancel } = this.props

    return (
      <Container>
        {/* <Card.Group> */}
        <Card>
          <CardContent>
            <form onSubmit={this.handleUpdate}>
              {/* <Form> */}
              <div>
                <label>First Name</label>
                <input
                  autoFocus
                  defaultValue={loginProfile.first_name}
                  onChange={this.handleChange("first_name")}
                  placeholder='First Name'
                />
              </div>
              <div>
                <label>Last Name</label>
                <input
                  autoFocus
                  defaultValue={loginProfile.last_name}
                  onChange={this.handleChange("last_name")}
                  placeholder='Last Name'
                />
              </div>
              {/* </Form> */}
              <Button type='submit'>Update</Button>
              <Button
                onClick={() =>
                  onCancel ? onCancel() : this.props.history.goBack()
                }
              >
                Cancel
              </Button>
            </form>
          </CardContent>
        </Card>
        {/* </Card.Group> */}
      </Container>
    )
  }
}

interface PropsInjected {
  loggedIn: boolean
  loginProfile: IUser
  updateProfile(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
}

export default flow(
  withRouter,
  observer,
  inject<PropsInjected, Props>(
    ({
      auth: { loggedIn, updateProfile, loginProfile },
      app_util: { showError, showSuccess },
    }: IStore) => {
      return {
        loginProfile,
        loggedIn,
        updateProfile,
        showError,
        showSuccess,
      }
    }
  )
)(EditProfile)
