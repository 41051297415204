import React from "react"
import { Button, Grid } from "@material-ui/core"
import { IFormFieldInfo } from "../"
import { IAdminVendorType, IVendor } from "../../../stores"
import { useForm } from "react-hook-form"
import { ItemAttributeEdit } from "./ItemAttributeEdit"

export const ModelForm = ({
  item,
  onSubmit,
  fieldInfos,
  modelName,
}: {
  item?: IVendor | IAdminVendorType
  modelName: string
  fieldInfos: IFormFieldInfo[]
  onSubmit(data: FIXME)
}) => {
  const { handleSubmit, errors, formState, control, reset } = useForm({
    mode: "onChange",
  })
  const { isDirty, isValid, isSubmitting } = formState
  const handleForm = handleSubmit(({ images_attributes, ...val }: any) => {
    const data = new FormData()
    images_attributes &&
      images_attributes.forEach(({ file }) => {
        if (file) {
          data.append(`[${modelName}]images_attributes[]file`, file)
        }
      })

    for (const key in val) {
      data.append(`[${modelName}]${key}`, val[key])
    }
    onSubmit(data)
  })
  return (
    <form onSubmit={handleForm}>
      <Grid item>
        <Grid container spacing={2} direction='column'>
          {fieldInfos.map((fieldInfo) => (
            <ItemAttributeEdit
              key={fieldInfo.name}
              item={item}
              fieldInfo={fieldInfo}
              errors={errors}
              control={control}
            />
          ))}
        </Grid>
      </Grid>
      <Button type='submit' disabled={!isDirty || !isValid || isSubmitting}>
        Submit
      </Button>
    </form>
  )
}
