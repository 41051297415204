import React, { useState } from "react"
import { useAuth } from "../../Providers"
import { AppPageContent } from "../../components/shared"
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import Users from "./Users"
import { useHistory } from "react-router"
import {
  ADMIN_USERS_URL,
  ADMIN_VENDORS_URL,
  ADMIN_VENDOR_REQUESTS_URL,
  ADMIN_VENDOR_TYPES_URL,
  post,
} from "../../utils"
import BoxedContent from "../../components/shared/BoxedContent"
import Vendors from "./Vendors"
import VendorRequests from "./VendorRequests"
import AppMap from "../../components/shared/AppMap"
import VendorTypes, { VENDOR_TYPE_FIELD_INFOS } from "./VendorTypes"
import AppDialog from "../../components/shared/AppDialog"
import { PAGE_LIMIT_OPTION } from "."
import { ModelForm } from "./shared/ModelForm"

interface Props {}

export const Dashboard = (props: Props) => {
  const { loginProfile } = useAuth()
  const history = useHistory()
  const classes = useStyles()
  const [showForm, setShowForm] = useState<boolean>(false)
  return (
    <AppPageContent title='Admin Dashboard'>
      <Typography variant='h5'>Welcome {loginProfile.fullName()}!</Typography>

      {/* <AppMap /> */}

      <AppDialog
        open={showForm}
        title='New Vendor Type'
        onClose={() => setShowForm(false)}
      >
        <ModelForm
          fieldInfos={VENDOR_TYPE_FIELD_INFOS}
          modelName='vendor_type'
          onSubmit={(vendor_type) => {
            post("/api/admins/vendor_types", vendor_type, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then(() => setShowForm(false))
          }}
        />
      </AppDialog>
      <BoxedContent
        title='Vendor Types'
        className={classes.boxedContent}
        menuOptions={[
          {
            label: "Show More",
            onSelect: () => {
              history.push(ADMIN_VENDOR_TYPES_URL)
            },
          },
          {
            label: "Add New Type",
            onSelect: () => {
              setShowForm(true)
            },
          },
        ]}
      >
        {!showForm && <VendorTypes limit={PAGE_LIMIT_OPTION[0]} />}
      </BoxedContent>

      <BoxedContent
        title='Users'
        className={classes.boxedContent}
        menuOptions={[
          {
            label: "Show More",
            onSelect: () => {
              history.push(ADMIN_USERS_URL)
            },
          },
        ]}
      >
        <Users limit={PAGE_LIMIT_OPTION[0]} />
      </BoxedContent>

      <BoxedContent
        title='Vendors'
        className={classes.boxedContent}
        menuOptions={[
          {
            label: "Show More",
            onSelect: () => {
              history.push(ADMIN_VENDORS_URL)
            },
          },
        ]}
      >
        <Vendors limit={PAGE_LIMIT_OPTION[0]} />
      </BoxedContent>

      <BoxedContent
        title='Requests'
        className={classes.boxedContent}
        menuOptions={[
          {
            label: "Show More",
            onSelect: () => {
              history.push(ADMIN_VENDOR_REQUESTS_URL)
            },
          },
        ]}
      >
        <VendorRequests limit={PAGE_LIMIT_OPTION[0]} />
      </BoxedContent>
    </AppPageContent>
  )
}
export default Dashboard
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxedContent: {
      margin: "1rem 0",
    },
  })
)
