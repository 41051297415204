import React from "react"
import { Grid, Paper, Typography } from "@material-ui/core"
import { flow } from "lodash"
import { observer } from "mobx-react"
import Comments from "../../Comments/Comments"
import ReactMarkdown from "react-markdown"
import { IVendorPackageRequest } from "../../../stores"

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const VendorPackageRequest = ({ vendorPackageRequest }: Props) => {
  return (
    <Paper>
      <Grid container spacing={1}>
        {/* <Grid item xs={12}>
          <Typography variant='h5'>{vendorPackageRequest?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          {formatter.format(vendorPackageRequest?.price)} / {vendorPackageRequest?.quantity}{" "}
          left / rating: {vendorPackageRequest.average_rating}
        </Grid> */}
        <Grid item xs={12}>
          Vendor Name: {vendorPackageRequest?.vendor_package.vendor_name}
        </Grid>
        <Grid item xs={12}>
          Note:
          <ReactMarkdown>{vendorPackageRequest?.note}</ReactMarkdown>
        </Grid>
        <Grid item xs={12}>
          {vendorPackageRequest && (
            <Comments
              commentable={vendorPackageRequest}
              preLoad={false}
              label={"Comment on Package Request"}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

interface Props {
  vendorPackageRequest: IVendorPackageRequest
}

export default flow(observer)(VendorPackageRequest)
