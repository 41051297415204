import React from "react"
import { Button } from "@material-ui/core"
import { useHistory } from "react-router"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"

export const AppBackButton = () => {
  const history = useHistory()
  return (
    <>
      {history.action !== "POP" && (
        <Button
          size='small'
          variant='outlined'
          style={{ maxWidth: "6rem", maxHeight: "4rem", marginBottom: "1rem" }}
          onClick={() => history.goBack()}
          startIcon={<KeyboardBackspaceIcon />}
        >
          Back
        </Button>
      )}
    </>
  )
}
export default AppBackButton
