import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slider,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core"
import { TextFieldControl } from "mui-hook-form-control"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { zod, zodResolver } from "../.."
import Vendors from "../Vendors/Vendors"
import SearchIcon from "@material-ui/icons/Search"
import { useVendors } from "../../Providers"
import {
  createStyles,
  makeStyles,
  Theme,
  InputAdornment,
} from "@material-ui/core"
import { DarkTheme, DefaultTheme } from "../../themes/DefaultTheme"
import DateRangeIcon from "@material-ui/icons/DateRange"
import FilterListIcon from "@material-ui/icons/FilterList"
import AppDialog from "../shared/AppDialog"
import moment from "moment"

export const SearchFilterDialog = ({ Icon = FilterListIcon }) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState<number>(30)

  const priceLimitMin = 1
  const priceLimitMax = 1500

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number)
  }
  const show = (val = true) => {
    console.log({ val })
    setOpen(val)
  }
  const formMethods = useForm({
    mode: "onChange",
  })
  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
  } = formMethods
  console.log({ isValid, errors })
  const handleLogin = (data) => {
    console.log({ data })
    show(false)
  }
  function valuetext(value: number) {
    if (value === priceLimitMax) {
      return "any"
    }
    return `$${value}`
  }

  return (
    <>
      <IconButton>
        <Icon onClick={() => setOpen(true)} />
      </IconButton>
      <ThemeProvider theme={DefaultTheme}>
        <form id='filter-form' onSubmit={handleSubmit(handleLogin)}>
          <AppDialog
            title='Filters'
            open={open}
            onClose={() => show(false)}
            // actions={
            //   <Button variant='contained' color='secondary' type='submit'>
            //     Filter
            //   </Button>
            // }
          >
            {/* <DialogContentText>test</DialogContentText> */}
            {/* <Slider
              color='secondary'
              value={value}
              onChange={handleChange}
              aria-labelledby='continuous-slider'
            />
            <Slider
              value={[20, 37]}
              // onChange={handleChange}
              valueLabelDisplay='auto'
              aria-labelledby='range-slider'
              getAriaValueText={valuetext}
            /> */}
            <Controller
              name='price_range'
              control={control}
              defaultValue={[priceLimitMin, priceLimitMax]}
              render={(p) => {
                return (
                  <>
                    <Typography variant='h6' style={{ marginBottom: "1rem" }}>
                      Price Range
                    </Typography>
                    {/* <Grid item xs={10}> */}
                    <Slider
                      min={priceLimitMin}
                      step={1}
                      max={priceLimitMax}
                      value={p.value}
                      onChange={(event: any, newValue: number | number[]) => {
                        p.onChange(newValue)
                      }}
                      valueLabelDisplay='auto'
                      aria-labelledby='range-slider'
                      getAriaValueText={valuetext}
                      color='secondary'
                    />
                    {/* </Grid> */}
                    <Grid container spacing={2} justifyContent='space-between'>
                      <Grid item xs={6}>
                        <TextField
                          variant='filled'
                          value={p.value[0]}
                          onChange={(event) => {
                            p.onChange([event.currentTarget.value, p.value[1]])
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <TextField
                          variant='filled'
                          value={
                            p.value[1] === priceLimitMax ? "Any" : p.value[1]
                          }
                          onChange={(event) => {
                            p.onChange([p.value[0], event.currentTarget.value])
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )
              }}
            />
            <Divider style={{ margin: "2rem" }} />
            <DialogActions>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                form='filter-form'
              >
                Filter
              </Button>
            </DialogActions>
          </AppDialog>
        </form>
      </ThemeProvider>
    </>
  )
}
