import React from "react"
import { observer } from "mobx-react"
import { VENDOR_PACKAGE_REQUESTS_URL } from "../../utils/constants"
import PrivateRoute from "../../components/PrivateRoute"
import { VendorPackageRequests } from "./VendorPackageRequests"

const VendorPackageRequestsRoutes = observer(() => {
  return (
    <>
      <PrivateRoute
        exact
        path={VENDOR_PACKAGE_REQUESTS_URL}
        component={VendorPackageRequests}
      />
    </>
  )
})

export default VendorPackageRequestsRoutes
