import { types, Instance } from "mobx-state-tree"
import { get, post, put } from "../../utils"
import { Commentable } from "../helpers/Commentable"
import { IdedObject } from "../helpers/IdedObject"
import { Imageable } from "../helpers/Imageable"
import { LoadableStatus } from "../helpers/LoadableStatus"
import Image from "../Image"
import { VendorPackage } from "../Vendors"
import { DiyEvent } from "../DiyEvents"
import { User } from "../auth"
import Editable from "../helpers/Editable"

export const VendorPackageRequest = types
  .compose(
    "VendorPackageRequest",
    IdedObject,
    Commentable,
    Editable,
    types.model({
      // name: types.string,
      note: types.maybeNull(types.string),
      accepted_at: types.maybeNull(types.string),
      canceled_at: types.maybeNull(types.string),
      denied_at: types.maybeNull(types.string),
      user: User,
      vendor_package: VendorPackage,
      diy_event: types.late(() => DiyEvent),
      // price: types.number,
      // quantity: types.maybeNull(types.number),
      // average_rating: types.number,
    })
  )
  .actions((self) => ({
    getStatus: () => {
      return self.accepted_at
        ? "accepted"
        : self.denied_at
        ? "rejected"
        : self.canceled_at
        ? "cancelled"
        : "not responded"
    },
    getCommentableType: () => {
      return "vendor_package_requests"
    },
    accept() {
      return put(`/api/vendor_package_requests/${self.id}/accept`)
        .then(({ data }) => {
          self.updateMe(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    reject() {
      return put(`/api/vendor_package_requests/${self.id}/reject`)
        .then(({ data }) => {
          self.updateMe(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      return put(`/api/vendor_package_requests/${self.id}/cancel`)
        .then(({ data }) => {
          self.updateMe(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  }))

export interface IVendorPackageRequest
  extends Instance<typeof VendorPackageRequest> {}
export default VendorPackageRequest
