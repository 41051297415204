import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { IImage } from "../../stores/Image"

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    minHeight: 140,
  },
})
interface Props {
  image: IImage
  style?: React.CSSProperties
  onDelete?(val: FIXME)
}

export function Image({ image, style, onDelete }: Props) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image?.url || "/Placeholder_view_vector.svg"}
          title='Contemplative Reptile'
          style={style}
        />
        {/* <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            Lizard
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent> */}
      </CardActionArea>
      <CardActions>
        {/* <Button size='small' color='primary'>
          Share
        </Button> */}
        {/* <Button size='small' color='primary'>
          Learn More
        </Button> */}
        {onDelete && (
          <Button size='small' onClick={() => onDelete(image)}>
            Delete
          </Button>
        )}
      </CardActions>
    </Card>
  )
}
