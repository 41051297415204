import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  CardActionArea,
  Badge,
} from "@material-ui/core"
import { IStore } from "../../stores"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { zod, zodResolver } from "../../utils"
import { TextFieldControl } from "mui-hook-form-control"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { SIGNUP_EVENT_URL, VENDOR_SETUP_URL } from "../../utils/constants"
import { MarrageIcon } from "./shared/MarrageIcon"
import { VendorIcon } from "./shared/VendorIcon"

export const SignupPage = (props) => {
  const [selected, setselected] = useState("")
  const history = useHistory()
  return (
    <Container style={{ paddingTop: "2rem" }}>
      <Typography variant='h4'>Create an account</Typography>
      <Typography variant='h6'>
        Yay! We are happy you are here! Are you planning your wedding or are you
        a vendor?
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Badge
            badgeContent={
              selected === "vendor" ? <CheckCircleIcon /> : undefined
            }
          >
            <Card onClick={() => setselected("vendor")}>
              <CardActionArea>
                <div style={{ minHeight: "11rem", padding: "0 2rem" }}>
                  <VendorIcon />
                </div>

                <CardContent style={{ paddingTop: 0 }}>
                  <Typography variant='h6' align='center'>
                    I’m a<br />
                    vendor
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Badge>
        </Grid>
        <Grid item>
          <Badge
            badgeContent={
              selected === "event" ? <CheckCircleIcon /> : undefined
            }
          >
            <Card onClick={() => setselected("event")}>
              <CardActionArea>
                <div style={{ minHeight: "11rem", padding: "0 2rem" }}>
                  <MarrageIcon />
                </div>
                <CardContent style={{ paddingTop: 0 }}>
                  <Typography variant='h6' align='center'>
                    I’m getting
                    <br />
                    married
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Badge>
        </Grid>
      </Grid>

      <Grid container justifyContent='flex-end' spacing={2}>
        <Grid item>
          <Button variant='outlined' onClick={() => history.goBack()}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='secondary'
            disabled={selected === ""}
            onClick={() => {
              if (selected === "vendor") {
                history.push(VENDOR_SETUP_URL)
              } else {
                history.push(SIGNUP_EVENT_URL)
              }
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
