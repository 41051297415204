import React from "react"
import { inject, observer } from "mobx-react"
import { Button, Container, Typography, Grid } from "@material-ui/core"
import { IStore } from "../../stores"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { zod, zodResolver } from "../../utils"
import { CheckboxControl, TextFieldControl } from "mui-hook-form-control"
import { VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL } from "../../utils/constants"
import { useParamVendor } from "../../Providers"

function SignupEventPage({
  createVendor,
  showError,
  showSuccess,
}: PropsInjected) {
  const history = useHistory()
  const { control, handleSubmit, errors } = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  })
  const { vendor } = useParamVendor()
  console.log({ vendor })
  if (!vendor) {
    return <></>
  }

  const handleLogin = (data) => {
    console.log({ data, vendor })
    vendor
      ?.updateVendor(data)
      .then(() => showSuccess("Updated!"))
      .then(() => history.push(VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL(vendor.id)))
      .catch(showError)
  }

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Container>
        <div style={{ paddingTop: "2rem", maxWidth: "400px" }}>
          <Typography variant='h4' style={{ marginBottom: "2rem" }}>
            Let’s work on your listing
          </Typography>
          <Typography variant='h6' style={{ marginBottom: "1rem" }}>
            Title for your listing
          </Typography>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <TextFieldControl
                label='Title'
                name='listing_title'
                defaultValue={vendor?.listing_title}
                error={errors?.listing_title}
                control={control}
                variant='outlined'
              />
            </Grid>
            <Typography variant='h6' style={{ marginBottom: "1rem" }}>
              Using our techno-wizardry we came up with the below summary.
            </Typography>
            <Grid item>
              <TextFieldControl
                label='Summary'
                name='description'
                error={errors?.description}
                defaultValue={vendor?.description}
                control={control}
                variant='outlined'
                multiline={true}
                rows={5}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Button variant='outlined' onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='secondary' type='submit'>
              Next
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}

interface PropsInjected {
  loggedIn: boolean
  createVendor(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup },
    vendors: { createVendor },
    app_util: { showError, showSuccess },
  }: IStore) => {
    return {
      loggedIn,
      createVendor,
      showError,
      showSuccess,
    }
  }
)(observer(SignupEventPage))

//  Validations
const signupSchema = zod.object({
  listing_title: zod.string().min(2),
  description: zod.string().min(2),
})

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
