import React from "react"
import VendorsSearch from "../../components/VendorsSearch"
import Vendors from "../../components/Vendors/Vendors"
import TitledVendors from "../../components/Vendors/TitledVendors"
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import SubForm from "./SubForm"
interface Props {}

export const PlaceholderLanding = (props: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.heroContainer}>
        <Grid container justifyContent='flex-end'>
          <Grid item className={classes.rightContent} sm={9}>
            <div className={classes.logoContainer}>
              <img src={"/white_logo.png"} className={classes.logo} />
            </div>
            <Typography variant='h3'>Be the first to know</Typography>
            <Typography variant='body1' className={classes.description}>
              Subscribe now to join us in creating the future of wedding
              planning. Zinnia will revolutionize the wedding industry by
              offering a bookable marketplace to help meet the needs of vendors
              and couples.
            </Typography>
            <SubForm />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
export default PlaceholderLanding

const waveRadius = 40

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: "100%",
      flex: 12,
      marginBottom: "-2rem",
    },
    logoContainer: {
      marginBottom: "3rem",
      // width: "350px",
    },
    logo: {
      width: "100%",
      height: "auto",
      maxWidth: "350px",
    },
    rightContent: {
      background: "rgba(49, 167, 173, 0.9)",
      // marginLeft: "auto",
      minHeight: "calc(100vh - 9rem)",
      color: "#ffffff",
      padding: "5rem",
      [theme.breakpoints.down("xs")]: {
        marginTop: "5rem",
        padding: "2rem",
        paddingBottom: "5rem",
      },
    },
    description: {
      maxWidth: "520px",
      fontFamily: "'Libre Baskerville'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      marginTop: "1rem",
    },
    heroContainer: {
      display: "flex",
      flexDirection: "column",
      // padding: "2rem 1rem 4rem 1rem",
      minHeight: "calc(100vh - 20rem)",
      alignItems: "center",
      backgroundImage: "url(/landing_banner.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#000000",
      "-webkit-mask": "url(/landing_banner.png) top/100% 100%",
      border: "none",
    },
    header: {
      textAlign: "center",
    },
    headerImage: {
      maxWidth: "280px",
      height: "auto",
      margin: "2rem auto",
    },
  })
)
