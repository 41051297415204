import { types } from "mobx-state-tree"
import { IdedObject } from "./IdedObject"
import { LoadableStatus } from "./LoadableStatus"

export const ListIdManager = types
  .compose(
    "ListIdManager",
    LoadableStatus,
    types.model({
      list: types.array(IdedObject),
      list_ids: types.optional(
        types.map(types.array(types.union(types.number, types.string))),
        {}
      ),
    })
  )
  .actions((self) => ({
    clear: () => {
      self.list.clear()
      self.list_ids.clear()
      self.loadedIds.clear()
      self.loadingIds.clear()
    },
    setList: (name, list) => {
      const list_ids = list.map((l) => l.id)
      self.list.replace([
        ...self.list.filter((v) => !list_ids.includes(v.id)),
        ...list,
      ])
      self.list_ids.set(name, list_ids)
    },
  }))
  .actions((self) => ({
    unloadedIds: (ids) => {
      const loadedIds = self.list.map((l) => l.id)
      return ids.filter((id) => !loadedIds.includes(id))
    },
    getList(name) {
      if (!self.list_ids.has(name)) {
        self.list_ids.set(name, [])
      }
      const list_ids = self.list_ids.get(name)
      return self.list.filter((l) => list_ids.includes(l.id)) as any
    },
  }))
