import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core"
import { observer } from "mobx-react"
import { SelectControl, TextFieldControl } from "mui-hook-form-control"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ProgressLoader } from "../../components/shared/ProgressLoader"
import { useAuth } from "../../Providers"
import { useVendorTypes } from "../../Providers/VendorTypesProvider"
import { zod, zodResolver } from "../../utils"

type Props = {}

export default observer(function SubForm({}: Props) {
  const { handleSubmit, errors, formState, watch, control, reset } = useForm({
    mode: "onBlur",
    resolver: zodResolver(subSchema),
  })
  const { vendorTypes } = useVendorTypes()
  const { t } = useTranslation()
  const { subscribe } = useAuth()

  const handleLogin = handleSubmit(({ vendor_type, ...data }) => {
    subscribe({
      ...data,
      ...(vendor_type && vendor_type !== -1
        ? { vendor_type_id: vendor_type }
        : {}),
    }).then(() => reset())
  })

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <form onSubmit={handleLogin}>
        <Grid
          container
          spacing={3}
          // direction='column'
          style={{ paddingTop: "1rem" }}
        >
          <Grid item xs={12} md={6}>
            <TextFieldControl
              label={t("common.firstName")}
              error={errors?.first_name}
              control={control}
              // required={true}
              name='first_name'
              variant='outlined'
              className={classes.field}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldControl
              label={t("common.lastName")}
              error={errors?.last_name}
              control={control}
              name='last_name'
              variant='outlined'
              className={classes.field}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldControl
              label={t("common.email")}
              error={errors?.email}
              control={control}
              // required={true}
              name='email'
              variant='outlined'
              className={classes.field}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {vendorTypes.length > 0 ? (
              <Controller
                name='vendor_type'
                control={control}
                render={(p) => {
                  return (
                    <FormControl variant='outlined' style={{ width: "100%" }}>
                      <InputLabel id='business_type_label'>
                        {t("common.businessType")}
                      </InputLabel>
                      <Select
                        labelId='business_type_label'
                        id='business_type_select'
                        value={p.value}
                        onChange={p.onChange}
                        onBlur={p.onBlur}
                        label={t("common.businessType")}
                        error={errors?.vendor_type}
                        className={classes.field}
                      >
                        {Array.from(vendorTypes).map((vendorType) => (
                          <MenuItem key={vendorType.id} value={vendorType.id}>
                            {vendorType.name}
                          </MenuItem>
                        ))}
                        <MenuItem key={"other"} value={-1}>
                          Other
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )
                }}
              />
            ) : (
              <ProgressLoader />
            )}
            {/* </Grid> */}
          </Grid>
          {watch("vendor_type") === -1 && (
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextFieldControl
                    label={t("common.other")}
                    error={errors?.other}
                    control={control}
                    placeholder='Please specify'
                    name='other'
                    variant='outlined'
                    className={classes.field}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item style={{ textAlign: "center" }} xs={12} md={6}>
            <Button
              size='large'
              type='submit'
              variant='contained'
              // color='secondary'
              fullWidth
              style={{ backgroundColor: "#eeeeee" }}
            >
              Join us
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
    },
    field: {
      ["& .MuiInputBase-input"]: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #787A8F",
      },
      ["& .MuiFormLabel-root.Mui-focused"]: {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },
  })
)
const subSchema = zod
  .object({
    first_name: zod.string().min(1),
    last_name: zod.string().min(1),
    email: zod.string().email(),
    vendor_type: zod.number(),
    other: zod.string(),
  })
  .refine(
    (data) => {
      return (
        data.vendor_type > 0 || (data.vendor_type === -1 && data.other !== "")
      )
    },
    {
      message: "Please specify",
      path: ["other"],
    }
  )
  .refine(
    (data) => {
      return data.vendor_type !== undefined
    },
    {
      message: "Please specify",
      path: ["vendor_type"],
    }
  )
