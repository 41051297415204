import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  CardActionArea,
  Badge,
  Checkbox,
} from "@material-ui/core"
import { IStore } from "../../../stores"
import {
  useForm,
  Controller,
  useFormContext,
  FormProvider,
} from "react-hook-form"
import { useHistory } from "react-router"
import { zod, zodResolver } from "../../../utils"
import { CheckboxControl, TextFieldControl } from "mui-hook-form-control"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { DETAIL_EVENT_URL } from "../../../utils/constants"

function EventProcessPage({
  updateProfile,
  showError,
  showSuccess,
}: PropsInjected) {
  const history = useHistory()
  const formMethods = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  })
  const { control, handleSubmit, errors } = formMethods

  const handleLogin = (data) => {
    // console.log({ data })
    updateProfile(data)
      .then(() => showSuccess("Updated"))
      .then(() => history.push(DETAIL_EVENT_URL))
      .catch(showError)
  }

  return (
    <FormProvider {...formMethods}>
      <Container>
        <form onSubmit={handleSubmit(handleLogin)}>
          <div style={{ paddingTop: "2rem", maxWidth: "800px" }}>
            <Typography variant='h4' style={{ marginBottom: "2rem" }}>
              Tell us a bit about where you are in the process.
            </Typography>
            <Typography variant='h6' style={{ marginBottom: "2rem" }}>
              Where are you in the process of wedding planning?
            </Typography>
            <Grid container direction='column' spacing={3}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <RadioBox
                      name='event_process'
                      value='not_started'
                      content={
                        <>
                          <Typography variant='h6' align='center'>
                            I haven’t started
                          </Typography>
                          <Typography variant='subtitle1' align='center'>
                            But I am starting now!
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RadioBox
                      name='event_process'
                      value='just_started'
                      content={
                        <>
                          <Typography variant='h6' align='center'>
                            Just started
                          </Typography>
                          <Typography variant='subtitle1' align='center'>
                            I have a date, but still a<br />
                            way to go.
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RadioBox
                      name='event_process'
                      value='all_done'
                      content={
                        <>
                          <Typography variant='h6' align='center'>
                            All done
                          </Typography>
                          <Typography variant='subtitle1' align='center'>
                            I just have one or two
                            <br />
                            things left to book.
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CheckboxControl
                  name='scooping'
                  control={control}
                  error={errors?.scooping}
                  label={<span>I’m not engaged, just scoping things out.</span>}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container justifyContent='flex-end' spacing={2}>
            <Grid item>
              <Button
                variant='outlined'
                onClick={() => history.push(DETAIL_EVENT_URL)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='secondary' type='submit'>
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </FormProvider>
  )
}
const RadioBox = ({ name, value, content }) => {
  const { control, watch } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        // console.log({ value, checked: props.value === value })
        return (
          <Badge
            badgeContent={
              watch(name) === value ? <CheckCircleIcon /> : undefined
            }
          >
            <Card onClick={() => props.onChange(value)}>
              <CardActionArea>
                <CardContent style={{ paddingTop: 0 }}>{content}</CardContent>
              </CardActionArea>
            </Card>
          </Badge>
        )
      }}
    />
  )
}

interface PropsInjected {
  loggedIn: boolean
  updateProfile(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup, updateProfile },
    app_util: { showError, showSuccess },
  }: IStore) => {
    return {
      loggedIn,
      updateProfile,
      showError,
      showSuccess,
    }
  }
)(observer(EventProcessPage))

//  Validations
const signupSchema = zod.object({
  event_process: zod.string().min(2),
  scooping: zod.boolean(),
})

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
