import Auth, { IAuth } from "./auth"
import AppUtil, { IAppUtil } from "./app_util"
import Vendors, { IVendors } from "./Vendors"
import DiyEvents, { IDiyEvents } from "./DiyEvents"
import Root from "./Root"
import VendorPackageRequests, {
  IVendorPackageRequests,
} from "./VendorPackageRequests"
import { Notifications, INotifications } from "./Notifications"
import { Conversations, IConversations } from "./Conversations"
export * from "./Vendors"
export * from "./Notifications"
export * from "./Conversations"
export * from "./DiyEvents"
export * from "./Root"
import { connectReduxDevtools } from "mst-middlewares"

const app_util = AppUtil.create({})
const auth = Auth.create({})
const vendors = Vendors.create({})
const notifications = Notifications.create({})
const conversations = Conversations.create({})
const diy_events = DiyEvents.create({})
const vendor_package_requests = VendorPackageRequests.create({})

export interface IStore {
  auth: IAuth
  app_util: IAppUtil
  notifications: INotifications
  conversations: IConversations
  vendors: IVendors
  diy_events: IDiyEvents
  vendor_package_requests: IVendorPackageRequests
}

const root = Root.create({
  auth,
  app_util,
  vendors,
  notifications,
  conversations,
  diy_events,
  vendor_package_requests,
} as FIXME)

if (process.env.NODE_ENV !== "production") {
  connectReduxDevtools(require("remotedev"), root)
}

export default root
