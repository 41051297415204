import React from "react"
import VendorsSearch from "../../components/VendorsSearch"
import Vendors from "../../components/Vendors/Vendors"
import TitledVendors from "../../components/Vendors/TitledVendors"
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core"
interface Props {}

export const Home = (props: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.heroContainer}>
        <div className={classes.header}>
          <img src='/header.png' className={classes.headerImage} />
        </div>

        <div>
          <VendorsSearch />
        </div>
        <div className={classes.maskedBottom}></div>
      </div>
      <TitledVendors name='home' filters={{ limit: 20 }} />
      {/* <TitledVendors title='Home2' filters={{ limit: 3, q: "dfg" }} /> */}
    </div>
  )
}
export default Home

const waveRadius = 40

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: "100%",
      flex: 12,
    },
    maskedBottom: {
      // border: "none",
      // background: "url(/hero_banner.png)",
      // backgroundColor: "#000000",
      // "-webkit-mask-box-image": "url(/wave_top.png) 0 0 100 100 round round",
      // height: "5rem",
      // width: "100%",
    },
    heroContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "2rem 1rem 4rem 1rem",
      minHeight: "20rem",
      alignItems: "center",
      backgroundImage: "url(/hero_banner.png)",
      backgroundColor: "#000000",
      "-webkit-mask": "url(/wave_top.png) top/100% 100%",
      // mask: "url(/wave_top.png) top/100% calc(100% - 40px) no-repeat",
      // "-webkit-mask-box-image": "url(/wave_top.png) 0 0 100 100",
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "center",
      // backgroundSize: "cover",
      // background:
      //   "linear-gradient(red 0 0) top/100% calc(100% - 40px) no-repeat,\
      // radial-gradient(circle 20px at 75% 100%,green 98%,blue) 50% calc(100% - 20px)/80px 20px no-repeat,\
      // radial-gradient(circle closest-side at 25% 50%,blue 99%,green 101%) bottom/80px 40px no-repeat;",
      // background: `linear-gradient(red 0 0) top/100% calc(100% - 40px) no-repeat, radial-gradient(circle 20px at 75% 100%,#0000 98%,red) 50% calc(100% - 20px)/80px 20px repeat-x, radial-gradient(circle closest-side at 25% 50%,red 99%,#0000 101%) bottom/80px 40px repeat-x`,

      // mask: `\
      // linear-gradient(red 0 0) top/100% calc(100% - 40px) no-repeat, \
      // radial-gradient(circle 20px at 75% 100%,#0000 98%,red) 50% calc(100% - 20px)/160px 20px repeat-x, \
      // radial-gradient(circle closest-side at 25% 50%,red 99%,#0000 101%) bottom/160px 40px repeat-x`,

      border: "none",
      // background: "url(/hero_banner.png)",
      // "-webkit-mask":
      //   "linear-gradient(red 0 0) top/100% calc(100% - 80px) no-repeat, radial-gradient(circle 40px at 75% 100%,#0000 98%,red) 50% calc(100% - 40px)/160px 40px repeat-x, radial-gradient(circle closest-side at 25% 50%,red 99%,#0000 101%) bottom/160px 80px repeat-x",
      // mask: `linear-gradient(red 0 0) top/100% calc(100% - 40px) no-repeat, radial-gradient(circle 20px at 75% 100%,#0000 98%,red) 50% calc(100% - 20px)/80px 20px repeat-x, radial-gradient(circle closest-side at 25% 50%,red 99%,#0000 101%) bottom/80px 40px repeat-x`,
      // mask: `linear-gradient(red 0 0) top/100% calc(100% - ${
      //   waveRadius * 2 + 10
      // }px) no-repeat, \
      // radial-gradient(circle ${waveRadius}px at 75% 100%,#0000 98%,red) 50% calc(100% - ${waveRadius}px)/${
      //   waveRadius * 8
      // }px ${waveRadius}px repeat-x, \
      // radial-gradient(circle closest-side at 25% 50%,red 99%,#0000 101%) bottom/${
      //   waveRadius * 8
      // }px ${waveRadius * 2}px repeat-x`,
      // mask: `linear-gradient(red 0 0) top/100% calc(100% - ${
      //   waveRadius * 2
      // }px) no-repeat, radial-gradient(circle ${waveRadius}px at 75% 100%,#0000 98%,red) 50% calc(100% - ${waveRadius}px)/${
      //   waveRadius * 4
      // }px ${waveRadius}px repeat-x, radial-gradient(circle closest-side at 25% 50%,red 99%,#0000 101%) bottom/${
      //   waveRadius * 4
      // }px ${waveRadius * 2}px repeat-x`,
    },
    header: {
      textAlign: "center",
    },
    headerImage: {
      maxWidth: "280px",
      height: "auto",
      margin: "2rem auto",
    },
  })
)
