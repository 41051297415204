import { types, Instance } from "mobx-state-tree"
import { post } from "../../utils"
import { User } from "../auth"
import { IdedObject } from "../helpers/IdedObject"
import { LoadableStatus } from "../helpers/LoadableStatus"

export const Message = types.compose(
  "Message",
  IdedObject,
  LoadableStatus,
  types.model({
    subject: types.string,
    body: types.string,
    sender_id: types.number,
  })
)

export const Conversation = types
  .compose(
    "Conversation",
    IdedObject,
    LoadableStatus,
    types.model({
      subject: types.string,
      messages: types.array(Message),
      recipients: types.array(User),
    })
  )
  .actions((self) => ({
    reply: (body) => {
      return post(`/api/conversations/${self.id}/reply_to_message`, {
        body,
      }).then(({ data }) => {
        console.log({ data })
        self.updateSelf(data)
      })
    },
  }))

export interface IMessage extends Instance<typeof Message> {}
export interface IConversation extends Instance<typeof Conversation> {}
export default Conversation
