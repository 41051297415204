import Axios from "axios"
import { types, Instance } from "mobx-state-tree"
import { get, post } from "../../utils"
import { ListIdManager } from "../helpers/ListIdManager"
import { queryString } from "../utils"
import { Vendor, VendorType } from "./vendor"

export const Vendors = types
  .compose(
    "Vendors",
    ListIdManager,
    types.model({
      list: types.optional(types.array(Vendor), []),
      vendorTypes: types.optional(types.array(VendorType), []),
    })
  )
  .actions((self) => ({
    setVendorTypes: (val) => {
      self.vendorTypes.replace(val)
    },
  }))
  .actions((self) => ({
    loadList(listName, args) {
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/vendors?${queryString(args)}`)
        .then(({ data: { list } }) => {
          self.stopLoading(listName)
          return self.setList(listName, list)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    loadById(id: number): Promise<any> {
      if (self.isLoading(id)) {
        return Promise.reject(undefined)
      }
      self.startLoading(id)
      return get(`/api/vendors/${id}`)
        .then(({ data }) => {
          self.stopLoading(id)
          self.setList(id, [data])
          return self.list.find((v) => v.id === id)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(id)
        })
    },
    loadTypes(): Promise<any> {
      const listName = "vendor_types"
      if (self.isLoading(listName) || self.isLoaded(listName)) {
        return Promise.reject(undefined)
      }
      self.startLoading(listName)
      return get("/api/vendor_types")
        .then(({ data }) => {
          self.stopLoading(listName)
          self.setVendorTypes(data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    createVendor: (vendor) => {
      return post("/api/vendors", { vendor })
        .then(({ data }) => {
          self.setList(data.id, [data])

          return data
        })
        .catch((err) => {
          console.error({ err })
          throw err.response.data.error
        })
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // self.loadList("list")
      self.loadTypes()
    },
  }))

export interface IVendors extends Instance<typeof Vendors> {}
export default Vendors
