import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  CardActionArea,
  Badge,
  Checkbox,
  CardMedia,
  makeStyles,
  createStyles,
  Theme,
  TextField,
} from "@material-ui/core"
import { IStore } from "../../../stores"
import {
  useForm,
  Controller,
  useFormContext,
  FormProvider,
} from "react-hook-form"
import { useHistory } from "react-router"
import { zod, zodResolver } from "../../../utils"
import { CheckboxControl, TextFieldControl } from "mui-hook-form-control"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { EVENT_DASHBOARD_URL } from "../../../utils/constants"

import moment from "moment"
function EventDetailPage({
  updateProfile,
  showError,
  showSuccess,
  createDiyEvent,
}: PropsInjected) {
  const history = useHistory()
  const formMethods = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
    mode: "onChange",
  })
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
  } = formMethods

  const handleLogin = (data) => {
    // console.log({ data })
    createDiyEvent(data)
      .then(() => showSuccess("Created Event"))
      .then(() => history.push(EVENT_DASHBOARD_URL))
      .catch(showError)
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Container>
          <div style={{ paddingTop: "2rem", maxWidth: "800px" }}>
            <Typography variant='h4' style={{ marginBottom: "2rem" }}>
              Tell us a bit about the wedding you are thinking of having.
            </Typography>
            <Grid container spacing={2} direction='column'>
              <Grid item>
                <TextFieldControl
                  label='Name'
                  name='name'
                  error={errors?.name}
                  control={control}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <TextFieldControl
                  label='Description'
                  name='description'
                  error={errors?.description}
                  control={control}
                  variant='outlined'
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item>
                <Typography variant='h6'>What’s your wedding date?</Typography>

                <Controller
                  name='wedding_date'
                  control={control}
                  defaultValue={moment().add(6, "months").toDate()}
                  render={(p) => {
                    return (
                      <TextField
                        label='Wedding Date'
                        name='wedding_date'
                        type='date'
                        value={p.value}
                        onChange={p.onChange}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant='h6'>What’s your budget?</Typography>
                <TextFieldControl
                  label='Budget'
                  name='budget'
                  type='number'
                  error={errors?.budget}
                  control={control}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Typography variant='h6' style={{ margin: "2rem 0 1rem 0" }}>
              Do you have a style?
            </Typography>
            <Grid container direction='column' spacing={3}>
              <Grid item>
                <Grid container spacing={2}>
                  {/* <Grid item xs={4}>
                    <Typography variant='h6'>
                      What’s your wedding date?
                    </Typography>
                    <TextFieldControl
                      label='Wedding Date'
                      name='wedding_date'
                      error={errors?.wedding_date}
                      control={control}
                      variant='outlined'
                    />
                  </Grid> */}
                  <Grid item xs={4}>
                    <RadioBox
                      name='style'
                      value='romantic'
                      title='Romantic'
                      description='Pastels, soft hues, delicate lighting, and plenty of florals'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RadioBox
                      name='style'
                      value='rustic'
                      title='Rustic'
                      description='String lights, mason jars, twine, lace, and wood.'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CheckboxControl
                  name='no_style'
                  control={control}
                  error={errors?.no_style}
                  label={<span>I don’t have a style yet.</span>}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container justifyContent='flex-end' spacing={2}>
            <Grid item>
              <Button variant='outlined' onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                disabled={!isValid}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  )
}
const RadioBox = ({ name, value, description, title }) => {
  const { control, watch } = useFormContext()
  const classes = useStyles()
  const {
    mediaUrl = undefined,
    // title = undefined,
    // description = undefined,
  } = {}
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        // console.log({ value, checked: props.value === value })
        return (
          <Badge
            badgeContent={
              watch(name) === value ? <CheckCircleIcon /> : undefined
            }
          >
            <Card onClick={() => props.onChange(value)}>
              <CardActionArea>
                {mediaUrl && (
                  <CardMedia className={classes.media} image={mediaUrl} />
                )}
                <CardContent>
                  {title && (
                    <Typography
                      gutterBottom
                      variant='h5'
                      component='h2'
                      align='center'
                    >
                      {title}
                    </Typography>
                  )}
                  {description && (
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      component='p'
                      align='center'
                    >
                      {description}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </Badge>
        )
      }}
    />
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "21rem",
    },
    media: {
      height: "8.75rem",
    },
    cardActions: {
      justifyContent: "center",
      marginBottom: ".5rem",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
)
interface PropsInjected {
  loggedIn: boolean
  updateProfile(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
  createDiyEvent(data: FIXME)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup, updateProfile },
    app_util: { showError, showSuccess },
    diy_events: { createDiyEvent },
  }: IStore) => {
    return {
      loggedIn,
      updateProfile,
      showError,
      showSuccess,
      createDiyEvent,
    }
  }
)(observer(EventDetailPage))

//  Validations
const signupSchema = zod.object({
  name: zod.string(),
  description: zod.string(),
  wedding_date: zod.string(),
  budget: zod.string(),
  style: zod.string(),
  no_style: zod.boolean(),
})

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
