import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Container,
  Paper,
  TextField,
} from "@material-ui/core"
import { IVendor } from "../../stores/Vendors"
import { useParams, useHistory } from "react-router"
import Comments from "../../components/Comments/Comments"
import VendorPackages from "./VendorPackages/VendorPackages"
import { Images } from "../../components/Images"
import {
  useAuth,
  useToast,
  VendorsContext,
  useVendor,
  useVendors,
  useParamVendor,
  NewConversationsContext,
} from "../../Providers"
import EditIcon from "@material-ui/icons/Edit"
import ReactMarkdown from "react-markdown"
import { VENDOR_EDIT_URL } from "../.."
import { IUserVendor } from "../../stores"
import { MessagableUser } from "../../components/shared"
import { SelectControl, TextFieldControl } from "mui-hook-form-control"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react"
import { flow } from "lodash"
import moment from "moment"

export const OrderWindow = observer(() => {
  const { vendor } = useParamVendor()
  // const { loadVendor } = useVendors()
  const {
    handleSubmit,
    errors,
    formState: { isValid },
    control,
    watch,
  } = useForm({
    mode: "onChange",
  })
  const { t } = useTranslation()
  const { loginProfile } = useAuth()
  const { showSuccess } = useToast()
  // useEffect(() => {
  //   if (
  //     vendor &&
  //     !vendor.isLoading("vendor_packages") &&
  //     !vendor.isLoaded("vendor_packages") &&
  //     vendor.vendor_packages.length === 0
  //   ) {
  //     vendor.loadPackages()
  //   }
  // }, [vendor])
  // let { id } = useParams()
  // useEffect(() => {
  //   if (!loading && loadVendor) {
  //     setloading(true)
  //     loadVendor(parseInt(id, 10)).then((v) => {
  //       v && setvendor(v)
  //       setloading(false)
  //     })
  //   }
  // }, [])

  if (!vendor) return <div>Loading</div>
  // if (!vendor) return <CircularProgress />

  const book = handleSubmit((data) => {
    // console.log({ data })
    const vendorPackage = vendor.vendor_packages.find(
      (vp) => vp.id === data.vendorPackage
    )
    vendorPackage
      ?.requestPackage(
        loginProfile.diy_events.find((de) => de.id === data.diyEvent)
      )
      .then(() => {
        showSuccess("Requested")
      })
  })

  const selectedPackage = vendor.vendor_packages.find(
    (vp) => vp.id === watch("vendorPackage")
  )

  const closestEvent = loginProfile?.diy_events.filter(
    (de) => de.wedding_date && moment(de.wedding_date).isAfter(moment())
  )[0]

  console.log({ closestEvent, loginProfile })

  return (
    <Paper style={{ padding: "1rem" }}>
      <form onSubmit={book}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SelectControl
              label={t("common.vendorPackage")}
              error={errors?.vendorPackage}
              control={control}
              name='vendorPackage'
              rules={{ required: true }}
              options={vendor.vendor_packages.map((vendorPackage) => ({
                label: vendorPackage.name,
                value: vendorPackage.id,
              }))}
            />
          </Grid>
          {loginProfile && loginProfile.diy_events.length > 0 && (
            <Grid item xs={12}>
              <SelectControl
                rules={{ required: true }}
                label={t("common.diyEvent")}
                error={errors?.diyEvent}
                control={control}
                defaultValue={closestEvent?.id}
                name='diyEvent'
                options={loginProfile.diy_events.map((diyEvent) => ({
                  label: diyEvent.name,
                  value: diyEvent.id,
                }))}
              />
            </Grid>
          )}
          <Grid item>
            {selectedPackage && (
              <Typography>&#36;{selectedPackage.price}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='date'
              control={control}
              defaultValue={
                closestEvent
                  ? moment(closestEvent.wedding_date).format("YYYY-MM-DD")
                  : moment().add(6, "months").format("YYYY-MM-DD")
              }
              render={(p) => {
                console.log({ p })
                return (
                  <TextField
                    label='Date'
                    name='date'
                    type='date'
                    value={p.value}
                    onChange={p.onChange}
                    // className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              label='Start Hour'
              name='start_hour'
              // type='number'
              error={errors?.start_hour}
              control={control}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              label='End Hour'
              name='end_hour'
              // type='number'
              error={errors?.end_hour}
              control={control}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              fullWidth
              disabled={!isValid}
            >
              Book now
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
})

export default OrderWindow
