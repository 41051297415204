import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Container,
} from "@material-ui/core"
import { useParams, useHistory, withRouter } from "react-router"
import EditIcon from "@material-ui/icons/Edit"

import { IDiyEvent } from "../../stores/DiyEvents"
import Comments from "../Comments/Comments"
import { Images } from "../Images"
import { DiyEventsContext } from "../../Providers"
import ReactMarkdown from "react-markdown"
import VendorPackageRequests from "./VendorPackageRequests/VendorPackageRequests"
import { DIY_EVENT_EDIT_URL } from "../../"
import moment from "moment"
import { ProgressLoader } from "../shared/ProgressLoader"

export const DiyEvent = () => {
  const { loadDiyEvent } = useContext(DiyEventsContext)
  const [diy_event, setdiy_event] = useState<IDiyEvent>()
  const [loading, setloading] = useState(false)
  const history = useHistory()

  let { id } = useParams<{ id: string }>()
  useEffect(() => {
    if (!loading && loadDiyEvent) {
      setloading(true)
      loadDiyEvent(parseInt(id, 10)).then((v) => {
        v && setdiy_event(v)
        setloading(false)
      })
    }
  }, [])
  if (!diy_event) {
    return <ProgressLoader />
  }

  return (
    <Container>
      <Button onClick={() => history.goBack()}>Back</Button>
      <div>
        {loading || !diy_event ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h4'>{diy_event?.name}</Typography>
              <IconButton
                aria-label='edit'
                onClick={() => history.push(DIY_EVENT_EDIT_URL(diy_event.id))}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Images imageable={diy_event} />
            </Grid>
            <Grid item xs={12}>
              {moment(diy_event.wedding_date).format("LL")}
            </Grid>
            <Grid item>
              <ReactMarkdown>{diy_event?.description}</ReactMarkdown>
            </Grid>
            <Grid item xs={12}>
              {diy_event && <VendorPackageRequests diy_event={diy_event} />}
            </Grid>
            {/* <Grid item xs={12}>
              {diy_event && (
                <Comments commentable={diy_event} label='Comment on DiyEvent' />
              )}
            </Grid> */}
          </Grid>
        )}
      </div>
    </Container>
  )
}

export default withRouter(DiyEvent)
