import { ThemeOptions, createTheme } from "@material-ui/core"
// import { createTheme } from "@material-ui/core/styles"

const palette = {
  zinniaBay: {
    // Zinnia Bay
    main: "#ffffff",
    contrastText: "#212121",
  },

  charcoal: {
    // Charcoal
    main: "#474747",
    contrastText: "#ffffff",
  },

  husk: {
    // Husk (gold)
    main: "#b39d5a",
    contrastText: "#ffffff",
  },
  iron: {
    main: "#d6d6d6",
    contrastText: "#000000",
  },
  aluminum: {
    // Aluminum
    main: "#9B9B9B",
    contrastText: "#ffffff",
  },
}

export const DefaultThemeData: ThemeOptions = {
  typography: { fontFamily: "Noto Serif" },
  palette: {
    secondary: {
      ...palette.iron,
    },
    primary: {
      ...palette.zinniaBay,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        // borderRadius: "1px",
        borderRadius: "8px 0px",
      },
      containedSecondary: {
        color: "#FFFFFF",
        backgroundColor: "#212121",
      },
    },
    MuiCard: {
      root: {
        // boxShadow:
        //   "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
        borderRadius: "4px",
      },
    },
    MuiPaper: {
      root: {
        // boxShadow:
        //   "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
        borderRadius: "4px",
      },
      elevation1: {
        boxShadow:
          "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
      },
    },
    // MuiPaper
  },
}
export const DefaultTheme = createTheme(DefaultThemeData)
export const DarkTheme = createTheme({
  ...DefaultThemeData,
  palette: { ...DefaultThemeData.palette, type: "dark" },
})
