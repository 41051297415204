import React, { useContext, useEffect } from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import VendorPackageRequest from "./VendorPackageRequest"
import { VendorPackageRequestsContext } from "../../Providers"
import { ProgressLoader } from "../../components/shared/ProgressLoader"
import { AppPageContent } from "../../components/shared"

export const VendorPackageRequests = ({}: Props) => {
  const {
    loadingVendorPackageRequests,
    vendor_package_requests,
    loadVendorPackageRequests,
  } = useContext(VendorPackageRequestsContext)
  useEffect(() => {
    loadVendorPackageRequests()
  }, [])

  if (!vendor_package_requests) return <CircularProgress />
  return (
    <AppPageContent
      title={`Requests (
      ${
        loadingVendorPackageRequests
          ? "Loading..."
          : vendor_package_requests.length
      }
      )`}
    >
      {loadingVendorPackageRequests && <ProgressLoader />}
      <Grid container spacing={2}>
        {vendor_package_requests.map((vendorPackageRequest) => (
          <Grid item key={vendorPackageRequest.id} xs={12} md={6}>
            <VendorPackageRequest vendorPackageRequest={vendorPackageRequest} />
          </Grid>
        ))}
      </Grid>
    </AppPageContent>
  )
}

interface Props {}
