import React, { useEffect, useState } from "react"
import GoogleMapReact from "google-map-react"
import RoomIcon from "@material-ui/icons/Room"

const GOOGLE_API_KEY = "AIzaSyDWU1mx7B51W1vnayMdukFLJxk_7NMJHeQ"
const AnyReactComponent = ({ lat, lng }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-30%, -100%)",
    }}
  >
    <RoomIcon />
  </div>
)

const AppMap = (props: {
  center?: { lat: number; lng: number }
  zoom?: number
}) => {
  const { center = { lat: 40.0, lng: -89.0 } } = props
  // if (!props) {
  //   return <></>
  // }
  // const [center, setCenter] = useState<FIXME>(props.center)

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position: GeolocationPosition) => {
  //         const pos = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         }
  //         console.log({ pos })
  //         setCenter(pos)

  //         // infoWindow.setPosition(pos)
  //         // infoWindow.setContent("Location found.")
  //         // infoWindow.open(map)
  //         // map.setCenter(pos)
  //       },
  //       () => {
  //         // handleLocationError(true, infoWindow, map.getCenter()!)
  //       }
  //     )
  //   } else {
  //     // Browser doesn't support Geolocation
  //     // handleLocationError(false, infoWindow, map.getCenter()!)
  //   }
  // }, [])

  return (
    <div>
      {center && (
        <div style={{ height: "19rem", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_API_KEY,
            }}
            defaultCenter={center}
            defaultZoom={props.zoom || 6}
          >
            {center && <AnyReactComponent lat={center.lat} lng={center.lng} />}
          </GoogleMapReact>
        </div>
      )}
    </div>
  )
}

export default AppMap
