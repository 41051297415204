import React, { useEffect } from "react"
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { flow } from "lodash"
import { observer, inject } from "mobx-react"
import { IDiyEvent, IStore } from "../../../stores"
import { IVendor, IVendorPackage } from "../../../stores/Vendors"
import { withRouter, RouteComponentProps } from "react-router"
import Comments from "../../Comments/Comments"
import VendorPackageRequest from "./VendorPackageRequest"

const VendorPackages = ({ diy_event }: Props) => {
  // useEffect(() => {
  //   if (
  //     diy_event &&
  //     !diy_event.isLoading("event_package_requests") &&
  //     !diy_event.isLoaded("event_package_requests") &&
  //     diy_event.event_package_requests.length === 0
  //   ) {
  //     diy_event.loadPackages()
  //   }
  // }, [diy_event])
  if (!diy_event) return <CircularProgress />
  return (
    <div>
      Package Requests ({diy_event.event_package_requests.length})
      <div>
        <Grid container spacing={2}>
          {diy_event.event_package_requests.map((vendorPackageRequest) => (
            <Grid item key={vendorPackageRequest.id} xs={12} md={6}>
              <VendorPackageRequest
                vendorPackageRequest={vendorPackageRequest}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

interface Props {
  diy_event: IDiyEvent
}

export default flow(observer)(VendorPackages)
