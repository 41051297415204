export * from "./constants"
export * from "./http"
export * from "./zodResolver"
export { default } from "./i18n"
export const dataValue = (name, d, o, defaultValue = "") => {
  return d && d[name] !== undefined && d[name] !== null
    ? d[name]
    : o && o[name] !== undefined && o[name] !== null
    ? o[name]
    : defaultValue
}
export * as zod from "zod"
Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
  enumerable: false,
})

export const hashToParamString = (params: FIXME) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&")

import "./global"
