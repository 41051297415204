import { Button } from "@material-ui/core"
import React from "react"

type Props = {}

export default function FacebookLogin({}: Props) {
  return (
    <Button
      variant='outlined'
      onClick={() => {
        location.href = "/users/auth/facebook"
      }}
    >
      Login with Facebook
    </Button>
  )
}
