import React from "react"
import {
  Container,
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core"
import AppMenu from "./AppMenu"

interface BoxedMenuOption {
  label: string
  onSelect()
}

const BoxedContent = ({ children, menuOptions, className, title }: Props) => {
  const classes = useStyles()

  return (
    <Paper
      variant='outlined'
      color='secondary'
      elevation={1}
      className={className}
    >
      {menuOptions && (
        <div className={classes.menuButton}>
          <AppMenu options={menuOptions} position='left' />
        </div>
      )}
      {title && (
        <Typography variant='h6' className={classes.title}>
          {title}
        </Typography>
      )}
      <div className={classes.root}>
        {children && <Container>{children}</Container>}
      </div>
    </Paper>
  )
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  menuOptions?: BoxedMenuOption[]
  className?: string
  title?: string
}

export default BoxedContent

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem 0",
    },
    title: {
      margin: ".5rem 0 0 1rem",
    },
    menuButton: {
      top: "2rem",
      float: "right",
      position: "relative",
      margin: "-1.5rem .5rem 0 0",
    },
  })
)
