import { Button, Grid } from "@material-ui/core"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import { AppPageContent } from "../../components/shared"
import UserAvatar from "../../components/shared/UserAvatar"
import { IUser } from "../../stores/auth"
import Skeleton from "@material-ui/lab/Skeleton"
import AppMenu from "../../components/shared/AppMenu"
import { useNewConversations } from "../../Providers"
import { toast } from "react-toastify"
import AppTable from "../../components/shared/AppTable"
import { debounce } from "lodash"
import { GridColumns } from "@mui/x-data-grid"
import { get, hashToParamString } from "../../utils"
import { ISearchFilter, PAGE_LIMIT_OPTION } from "."

type Props = {
  limit?: number
  height?: number | string
}

const columns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 50,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "avatar",
    headerName: "Avatar",
    renderCell: ({ row }) => {
      return <UserAvatar user={row.user as FIXME} />
    },
    minWidth: 120,
    align: "center",
    headerAlign: "center",
  },
  { field: "name", headerName: "Name", minWidth: 150 },
  { field: "email", headerName: "Email", minWidth: 200 },
  { field: "created_at", headerName: "Joined", minWidth: 150 },
  {
    field: "actions",
    headerName: " ",
    minWidth: 150,
    renderCell: ({ row: { user } }) => <Actions item={user} />,
  },
]
const Actions = ({ item }) => {
  const { sendMessageTo } = useNewConversations()
  return (
    <AppMenu
      options={[
        {
          label: "Show Detail",
          onSelect: () => {
            toast("Detail coming soon...")
          },
        },
        {
          label: "Message User",
          onSelect: () => {
            sendMessageTo([item.id])
          },
        },
        {
          label: "Masquerade as User",
          onSelect: () => {
            toast("Masquerade coming soon...")
          },
        },
      ]}
    />
  )
}
export default function Users({ limit = PAGE_LIMIT_OPTION[1], height }: Props) {
  // const [users, setUsers] = useState<IUser[]>([])
  const [rows, setRows] = useState<FIXME[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [filter, setFilter] = useState<ISearchFilter>({
    page: 0,
    page_size: limit,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const { sendMessageTo } = useNewConversations()
  const loadList = debounce(() => {
    if (loading) {
      return
    }
    setLoading(true)
    get(`/api/admins/users?${hashToParamString(filter)}`)
      .then(({ data: { list, row_count } }) => {
        setRowCount(row_count)
        setRows(
          list.map((user) => ({
            id: user.id,
            user: user,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            created_at: moment(user.created_at).fromNow(),
            actions: (
              <AppMenu
                options={[
                  {
                    label: "Message User",
                    onSelect: () => {
                      sendMessageTo([user.id])
                    },
                  },
                  {
                    label: "Masquerade as User",
                    onSelect: () => {
                      toast("Masquerade coming soon...")
                    },
                  },
                ]}
              />
            ),
          }))
        )
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, 500)

  useEffect(() => {
    loadList()
  }, [filter])

  const updateFilter = (name, value) => {
    if (loading) {
      return
    }
    setFilter({ ...filter, [name]: value })
  }

  const handleSortChange = (model, details) => {
    if (loading) {
      return
    }
    setFilter({
      ...filter,
      order_by: model[0].field,
      order_direction: model[0].sort,
    })
  }

  return (
    <AppTable
      pagination
      rowCount={rowCount}
      page={filter.page}
      pageSize={filter.page_size}
      rowsPerPageOptions={PAGE_LIMIT_OPTION}
      onPageSizeChange={(pageSize: number, details?: any) =>
        updateFilter("page_size", pageSize)
      }
      onPageChange={(page) => {
        updateFilter("page", page)
      }}
      rows={rows}
      columns={columns}
      onSortModelChange={handleSortChange}
      filterMode='server'
      paginationMode='server'
      sortingMode='server'
      loading={loading}
      {...(height ? { style: { height } } : {})}
    />
  )
  // return (
  // <AppPageContent title='Users'>
  // <Grid container direction='column'>
  //   {loading ? (
  //     <Skeleton variant='rect' style={{ minHeight: "15rem" }} />
  //   ) : (
  //     <>
  //       {users.map((user) => (
  //         <UserListItem key={user.id} user={user} />
  //       ))}
  //     </>
  //   )}
  // </Grid>
  // </AppPageContent>
  // )
}

const UserListItem = ({ user }: { user: IUser }) => {
  const { sendMessageTo } = useNewConversations()

  return (
    <Grid item>
      <Grid container direction='row' spacing={2}>
        <Grid item sm={1}>
          <AppMenu
            options={[
              {
                label: "Message User",
                onSelect: () => {
                  sendMessageTo([user.id])
                },
              },
              {
                label: "Masquerade as User",
                onSelect: () => {
                  toast("Masquerade coming soon...")
                },
              },
            ]}
          />
          {user.id}
        </Grid>
        <Grid item sm={2}>
          <UserAvatar user={user} />
        </Grid>
        <Grid item sm={3}>
          {user.first_name} {user.last_name}
        </Grid>
        <Grid item sm={3}>
          {user.email}
        </Grid>
        <Grid item sm={3}>
          Joined {moment(user.created_at).fromNow()}
        </Grid>
      </Grid>
    </Grid>
  )
}
