import React from "react"
import { Conversations } from "../Conversations"
import { DiyEvents } from "../../components/DiyEvents"
// import Vendors from "../Vendors/Vendors"

interface Props {}

export const VendorDashboard = (props: Props) => {
  return (
    <div>
      Vendor Dashboard
      {/* <Vendors /> */}
      {/* <Conversations hideIfEmpty={true} /> */}
    </div>
  )
}
export default VendorDashboard
