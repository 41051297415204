import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { IImage } from "../../stores/Image"
import { CircularProgress, Grid, GridSize } from "@material-ui/core"
import { IImageable } from "../../stores/helpers/Imageable"
import { flow } from "lodash"
import { observer } from "mobx-react"
import { Image } from "./"

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    // height: 140,
  },
})
interface Props {
  imageable: IImageable
  loadImages?: boolean
  xs?: GridSize
  sm?: GridSize
  deletable?: boolean
}

export const DetailImages = observer(
  ({
    imageable,
    loadImages = true,
    xs = 6,
    sm = 6,
    deletable = false,
  }: Props) => {
    const classes = useStyles()
    if (!imageable) {
      return <></>
    }
    useEffect(() => {
      if (
        loadImages &&
        imageable &&
        !imageable.isLoading("images") &&
        !imageable.isLoaded("images") &&
        imageable.images.length === 0
      ) {
        imageable.loadImages()
      }
    }, [loadImages, imageable])
    if (!imageable) return <CircularProgress />
    const firstImage = imageable.images[0]
    const otherImages = imageable.images.slice(1)
    return (
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={6}>
            <Image
              image={firstImage}
              style={{ minHeight: 300 }}
              onDelete={
                deletable
                  ? () => {
                      imageable.deleteImage(firstImage.id)
                    }
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} justifyContent='flex-start'>
              {otherImages.map((image, i) => {
                return (
                  <Grid
                    item
                    key={image.id}
                    {...{
                      xs: xs,
                      sm: sm,
                    }}
                  >
                    <Image
                      image={image}
                      onDelete={
                        deletable
                          ? () => {
                              imageable.deleteImage(image.id)
                            }
                          : undefined
                      }
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)

export default DetailImages
