import React from "react"
import {
  SIGNUP_URL,
  SIGNUP_EVENT_URL,
  PROCESS_EVENT_URL,
  DETAIL_EVENT_URL,
} from "../../utils/constants"
import PrivateRoute from "../../components/PrivateRoute"
import PublicRoute from "../../components/PublicRoute"
import SignupEventPage from "./Event/SignupEventPage"
import EventProcessPage from "./Event/EventProcessPage"
import EventDetailPage from "./Event/EventDetailPage"
import { SignupPage } from "./SignupPage"

const SignupRoutes = () => {
  return (
    <>
      <PublicRoute exact path={SIGNUP_URL} component={SignupPage} />
      <PublicRoute exact path={SIGNUP_EVENT_URL} component={SignupEventPage} />
      <PublicRoute
        exact
        path={PROCESS_EVENT_URL}
        component={EventProcessPage}
      />
      <PublicRoute exact path={DETAIL_EVENT_URL} component={EventDetailPage} />
    </>
  )
}

export default SignupRoutes
