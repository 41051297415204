import React from "react"
import { VENDOR_URL, VENDOR_EDIT_URL } from "../../utils/constants"
import PrivateRoute from "../../components/PrivateRoute"
import Vendor from "./Vendor"
import { withProvider } from "../../Providers/RouterProviders"
import { ParamVendorProvider } from "../../Providers"
import { VendorEdit } from "../../components/Vendors"
import PublicRoute from "../../components/PublicRoute"

const VendorRoutes = () => {
  return (
    <>
      <PublicRoute
        exact
        path={VENDOR_URL(":id")}
        component={withProvider(Vendor, ParamVendorProvider)}
      />
      <PrivateRoute
        exact
        path={VENDOR_EDIT_URL(":id")}
        component={VendorEdit}
      />
    </>
  )
}

export default VendorRoutes
