import React from "react"
import { inject, observer } from "mobx-react"
import { Button, Container, Typography, Grid, Card } from "@material-ui/core"
import { IStore } from "../../../stores"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router"
import { zod, zodResolver } from "../../../utils"
import {
  CheckboxControl,
  FileButtonControl,
  TextFieldControl,
} from "mui-hook-form-control"
import { VENDOR_URL } from "../../../utils/constants"
import { useParamVendor } from "../../../Providers"
import { Images } from "../../../components/Images"
import { identity, pick, pickBy } from "lodash"
import { AppPageContent } from "../../../components/shared"

function AddVendorPackage({ showError, showSuccess }: PropsInjected) {
  const history = useHistory()
  const {
    control,
    handleSubmit,
    errors,
    reset,
    formState: { dirtyFields },
  } = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  })
  const { package_id } = useParams<{ package_id?: string }>()
  const { vendor, isOwner } = useParamVendor()
  console.log({ vendor })
  if (!vendor) {
    return <></>
  }

  const original =
    package_id &&
    vendor.vendor_packages.find((vp) => vp.id.toString() === package_id)

  const handleLogin = (val) => {
    const data = original
      ? pick(val, Object.keys(pickBy(dirtyFields, identity)))
      : val

    const apiFunc = original ? original.update : vendor?.createVendorPackage
    apiFunc(data)
      .then(() => {
        showSuccess("Saved!")
        reset({ name: "", description: "", price: "" })
        original && history.goBack()
      })
      .catch(showError)
  }

  return (
    <AppPageContent>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div style={{ paddingTop: "2rem", minWidth: "20rem" }}>
          {/* <Container> */}
          <Typography variant='h4' style={{ marginBottom: "2rem" }}>
            Let’s add a service
          </Typography>
          <Grid container spacing={6}>
            {vendor.vendor_packages.map((vp) => (
              <Grid item key={vp.id} xs={12} sm={4}>
                <Card style={{ padding: "1rem" }}>
                  <Images imageable={vp} />
                  <Typography variant='h6'>{vp.name}</Typography>
                  <Typography variant='body2'>{vp.description}</Typography>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12} sm={4}>
              <Card style={{ padding: "1rem" }}>
                <Grid container direction='column' spacing={3}>
                  <Grid item>
                    <TextFieldControl
                      label='Service Name'
                      name='name'
                      error={errors?.name}
                      control={control}
                      variant='outlined'
                      defaultValue={original?.name}
                    />
                  </Grid>

                  <Typography variant='body2' style={{ marginBottom: "1rem" }}>
                    Using our techno-wizardry we came up with the below summary.
                  </Typography>
                  <Grid item>
                    <TextFieldControl
                      label='Summary'
                      name='description'
                      error={errors?.description}
                      control={control}
                      variant='outlined'
                      multiline={true}
                      rows={5}
                      defaultValue={original?.description}
                    />
                  </Grid>
                  <Grid item>
                    <TextFieldControl
                      label='Price'
                      name='price'
                      error={errors?.price}
                      control={control}
                      variant='outlined'
                      defaultValue={
                        original?.price ? original?.price.toString() : undefined
                      }
                    />
                  </Grid>
                  {original && (
                    <Grid item>
                      <Images imageable={original} />
                    </Grid>
                  )}
                  <Grid item>
                    <FileButtonControl
                      label='Upload images'
                      error={errors?.images_attributes}
                      control={control}
                      name={"images_attributes[0].file"}
                      onChange={(image) => {
                        console.log({ image })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='secondary' type='submit'>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {/* <Grid container direction='column' spacing={3}>
            <Grid item>
              <TextFieldControl
                label='Service Name'
                name='name'
                error={errors?.name}
                control={control}
                variant='outlined'
              />
            </Grid>

            <Typography variant='h6' style={{ marginBottom: "1rem" }}>
              Using our techno-wizardry we came up with the below summary.
            </Typography>
            <Grid item>
              <TextFieldControl
                label='Summary'
                name='description'
                error={errors?.description}
                control={control}
                variant='outlined'
                multiline={true}
                rows={5}
              />
            </Grid>
            <Grid item>
              <TextFieldControl
                label='Price'
                name='price'
                error={errors?.price}
                control={control}
                variant='outlined'
              />
            </Grid>
          </Grid> */}
        </div>
        <Grid container justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Button variant='outlined' onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Grid>
          {/* <Grid item>
            <Button variant='contained' color='secondary' type='submit'>
              Next
            </Button>
          </Grid> */}
          <Grid item>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                history.push(VENDOR_URL(vendor.id))
              }}
            >
              Done
            </Button>
          </Grid>
        </Grid>
        {/* </Container> */}
      </form>
    </AppPageContent>
  )
}

interface PropsInjected {
  loggedIn: boolean
  showError(c: string)
  showSuccess(c: string)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup },
    vendors: { createVendor },
    app_util: { showError, showSuccess },
  }: IStore) => {
    return {
      loggedIn,
      showError,
      showSuccess,
    }
  }
)(observer(AddVendorPackage))

//  Validations
const signupSchema = zod.object({
  name: zod.string().min(2),
  price: zod.string().min(2),
  description: zod.string().min(2),
  images_attributes: zod.any(),
})

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
