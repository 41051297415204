import React, { useState, useContext } from "react"
import { inject, observer } from "mobx-react"
import { IStore, IVendorType } from "../stores"
import { flow } from "lodash"
import { Typography } from "@material-ui/core"

export const VendorTypeContext = React.createContext<IVendorType | undefined>(
  undefined
)
export const useVendorType = () => useContext(VendorTypeContext)
interface Props {
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  vendorTypes: IVendorType[]
}

interface ContextProps {
  vendorTypes: IVendorType[]
}

const VendorTypesProviderBase = (props: Props & PropsInjected) => {
  const { children, vendorTypes } = props

  return (
    <VendorTypesContext.Provider value={{ vendorTypes }}>
      {children}
    </VendorTypesContext.Provider>
  )
}

export const VendorTypesContext = React.createContext<ContextProps>(undefined)
export const useVendorTypes = () => useContext(VendorTypesContext)
export const VendorTypesProvider = flow(
  observer,
  inject<PropsInjected, Props>(
    ({ vendors: { vendorTypes, loadTypes, shouldLoad } }: IStore) => {
      shouldLoad("vendor_types", loadTypes)
      return {
        vendorTypes,
      }
    }
  )
)(VendorTypesProviderBase)
