import React from "react"
import { HashRouter, BrowserRouter } from "react-router-dom"
import Dashboard from "./containers/Dashboard"
import PrivateRoute from "./components/PrivateRoute"
import AppContainer from "./components/AppContainer"
import { observer } from "mobx-react"
import VendorPackageRequests from "./containers/VendorPackageRequests"
import { Vendors, VendorEdit } from "./components/Vendors"
import { DiyEvents, DiyEvent, DiyEventEdit } from "./components/DiyEvents"
import { Profile } from "./components/Profile"
import SignupEventPage from "./containers/Signup/Event/SignupEventPage"
import VendorSetup from "./containers/VendorSetup"
import EventProcessPage from "./containers/Signup/Event/EventProcessPage"
import {
  ROOT_URL,
  PROFILE_URL,
  VENDORS_URL,
  VENDOR_PACKAGE_REQUESTS_URL,
  VENDOR_URL,
  VENDOR_EDIT_URL,
  DIY_EVENTS_URL,
  DIY_EVENT_URL,
  DIY_EVENT_EDIT_URL,
  NOTIFICATIONS_URL,
  CONVERSATIONS_URL,
  EDIT_PROFILE_URL,
} from "./"
import { Notifications } from "./components/Notifications"
import { Conversations } from "./containers/Conversations"
import Vendor from "./containers/Vendor"
import EditProfile from "./components/Profile/EditProfile"
import HomeRoutes from "./containers/Home"
import PublicRoute from "./components/PublicRoute"
import { Typography } from "@material-ui/core"
import {
  CONTACT_US_URL,
  DETAIL_EVENT_URL,
  EVENT_DASHBOARD_URL,
  FAQS_URL,
  OUR_STORY_URL,
  PACKAGES_URL,
  POLICIES_URL,
  PRESS_URL,
  PROCESS_EVENT_URL,
  SIGNUP_EVENT_URL,
  SIGNUP_URL,
  VENDOR_SETUP_URL,
} from "./utils"
import EventDetailPage from "./containers/Signup/Event/EventDetailPage"
import { withProvider } from "./Providers/RouterProviders"
import { ParamVendorProvider } from "./Providers"
import OurStory from "./containers/StaticPages/OurStory"
import Press from "./containers/StaticPages/Press"
import Policies from "./containers/StaticPages/Policies"
import ContactUs from "./containers/StaticPages/ContactUs"
import FAQs from "./containers/StaticPages/FAQs"
import Packages from "./containers/StaticPages/Packages"
import SignupRoutes from "./containers/Signup"
import ParamDiyEventProvider from "./Providers/ParamDiyEventProvider"
import AdminRoutes from "./containers/Admin"

const Routes = (props) => {
  return (
    <BrowserRouter>
      <AppContainer>
        <HomeRoutes />
        <SignupRoutes />
        <VendorSetup />
        {/* <PublicRoute exact path={SIGNUP_URL} component={SignupPage} /> */}
        {/* <PublicRoute path={VENDOR_SETUP_URL} component={SignupVendorPage} /> */}
        <PublicRoute path={OUR_STORY_URL} component={OurStory} />
        <PublicRoute path={PRESS_URL} component={Press} />
        <PublicRoute path={POLICIES_URL} component={Policies} />
        <PublicRoute path={CONTACT_US_URL} component={ContactUs} />
        <PublicRoute path={FAQS_URL} component={FAQs} />
        <PublicRoute path={PACKAGES_URL} component={Packages} />
        {/* <PublicRoute
          exact
          path={SIGNUP_EVENT_URL}
          component={SignupEventPage}
        />
        <PublicRoute
          exact
          path={PROCESS_EVENT_URL}
          component={EventProcessPage}
        />
        <PublicRoute
          exact
          path={DETAIL_EVENT_URL}
          component={EventDetailPage}
        /> */}
        <Dashboard />
        <AdminRoutes />

        <PrivateRoute exact path={PROFILE_URL} component={Profile} />
        <PrivateRoute exact path={EDIT_PROFILE_URL} component={EditProfile} />
        <PublicRoute exact path={VENDORS_URL} component={Vendors} />
        <PrivateRoute
          exact
          path={NOTIFICATIONS_URL}
          component={Notifications}
        />
        <PrivateRoute
          exact
          path={CONVERSATIONS_URL}
          component={Conversations}
        />
        <VendorPackageRequests />
        <Vendor />
        <PrivateRoute exact path={DIY_EVENTS_URL} component={DiyEvents} />
        <PrivateRoute exact path={DIY_EVENT_URL(":id")} component={DiyEvent} />
        <PrivateRoute
          exact
          path={DIY_EVENT_EDIT_URL(":id")}
          component={withProvider(DiyEventEdit, ParamDiyEventProvider)}
        />
      </AppContainer>
    </BrowserRouter>
  )
}

export default observer(Routes)

const PlaceholderPage = ({
  children,
  title,
}: {
  children?: any
  title?: string
}) => (
  <div>
    {title && <Typography>{title}</Typography>}
    <div>{children}</div>
  </div>
)
