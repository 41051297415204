import { types, Instance } from "mobx-state-tree"
import { get, put } from "../../utils"
import { Commentable } from "../helpers/Commentable"
import Editable from "../helpers/Editable"
import { IdedObject } from "../helpers/IdedObject"
import { Imageable } from "../helpers/Imageable"
import { LoadableStatus } from "../helpers/LoadableStatus"
import Image from "../Image"
import VendorPackageRequest from "../VendorPackageRequests/vendor_package_request"
import { VendorPackage } from "../Vendors/vendor"

export const DiyEvent = types
  .compose(
    "DiyEvent",
    IdedObject,
    Commentable,
    LoadableStatus,
    Imageable,
    Editable,
    types.model({
      name: types.string,
      description: types.string,
      wedding_date: types.maybeNull(types.string),
      budget: types.maybeNull(types.string),
      style: types.maybeNull(types.string),
      no_style: types.maybeNull(types.boolean),
      event_package_requests: types.array(VendorPackageRequest),
    })
  )
  .actions((self) => ({
    getCommentableType: () => {
      return "diy_events"
    },
    getImageableType: () => {
      return "diy_events"
    },
    setPackageRequests: (val) => {
      self.event_package_requests.replace(val)
    },
  }))
  .actions((self) => ({
    updateDiyEvent: ({ images_attributes, ...rest }) => {
      console
      const diy_event = new FormData()
      for (const property in rest) {
        console.log(`${property}: ${rest[property]}`)
        diy_event.append(`[diy_event]${property}`, rest[property])
      }

      // diy_event.append("[diy_event]name", name)
      // diy_event.append("[diy_event]description", description)
      images_attributes &&
        images_attributes.forEach(({ file }) => {
          diy_event.append("[diy_event]images_attributes[]file", file)
        })

      return put(`/api/diy_events/${self.id}`, diy_event, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(({ data }) => {
          self.updateMe(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadPackageRequests: () => {
      const listName = "event_package_requests"
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/diy_events/${self.id}/vendor_package_requests`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setPackageRequests(data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
  }))

export interface IVendorPackageRequest
  extends Instance<typeof VendorPackageRequest> {}
export interface IDiyEvent extends Instance<typeof DiyEvent> {}
export default DiyEvent
