import { types, Instance, getRoot } from "mobx-state-tree"
import { DiyEvent, Vendor } from "."
import api_http, { getLoginProfile, setLoginProfile } from "../utils/http"
import { IdedObject, Toastable } from "./helpers"
import { IRoot } from "./Root"

// const User = types.model("User", {
//   id: types.identifierNumber,
//   email: types.string,
//   name: types.maybeNull(types.string),
//   role: types.maybeNull(types.string),
//   vendors_count: types.maybeNull(types.number),
//   diy_events_count: types.maybeNull(types.number),
// })
export const User = types
  .compose(
    "User",
    IdedObject,
    types.model({
      email: types.string,
      first_name: types.maybeNull(types.string),
      last_name: types.maybeNull(types.string),
      role: types.maybeNull(types.string),
      avatar_url: types.maybeNull(types.string),
      created_at: types.maybeNull(types.string),
    })
  )
  .actions((self) => ({
    fullName: () => `${self.first_name} ${self.last_name}`,
  }))
export const LoginProfile = types
  .compose(
    "LoginProfile",
    User,
    types.model({
      email: types.string,
      // name: types.maybeNull(types.string),
      role: types.maybeNull(types.string),
      vendors_count: types.maybeNull(types.number),
      diy_events_count: types.maybeNull(types.number),
      diy_events: types.array(types.late(() => DiyEvent)),
      vendors: types.array(types.late(() => Vendor)),
    })
  )
  .actions((self) => ({
    afterCreate: () => {
      const { notifications, conversations }: IRoot = getRoot(self)
      notifications.loadEverything()
      conversations.loadEverything()
    },
  }))

export const Auth = types
  .compose(
    "Auth",
    Toastable,
    types.model({
      identifier: types.optional(types.identifier, "Auth"),
      loggedIn: types.optional(types.boolean, false),
      loaded: types.optional(types.boolean, false),
      email: types.maybeNull(types.string),
      loginProfile: types.maybe(LoginProfile),
    })
  )
  .actions((self) => ({
    setLoggedIn: (val: boolean = true) => {
      self.loggedIn = val
    },
    setLoaded: (val: boolean = true) => {
      self.loaded = val
    },
  }))
  .actions((self) => ({
    clear: () => {
      setLoginProfile()
      self.setLoggedIn(false)
      self.loginProfile = undefined
      self.email = undefined
    },
    setLoginProfile: (loginProfile = null) => {
      try {
        if (loginProfile) {
          const { authentication_token } = getLoginProfile() || {}
          const newProfile = { authentication_token, ...loginProfile }
          self.loginProfile = newProfile
          self.email = newProfile.email
          setLoginProfile(newProfile)
          self.setLoggedIn(true)
        } else {
          const root: any = getRoot(self)
          root.clear()
        }
      } catch (error) {
        console.error({ error })
      }
    },
  }))
  .actions((self) => ({
    checkAuth: () => {
      return api_http
        .get("/api/profile/auth")
        .then(({ data: loginProfile }) => {
          self.setLoginProfile(loginProfile)
          console.log({ loginProfile })
          return true
        })
        .catch((err) => {
          throw err.response.data.error
        })
    },
    logoutApi: () => {
      return api_http
        .get("/api/profile/signout")
        .then(() => {
          self.setLoginProfile()
          return true
        })
        .catch((err) => {
          throw err.response.data.error
        })
    },
  }))
  .actions((self) => ({
    loginWithGoogle: async () => {
      // location.href = "/users/auth/google_oauth2"
      const googleUser = await window.googleAuth.signIn() // wait for the user to authorize through the modal
      const { access_token } = googleUser.getAuthResponse()
      const data = new FormData()
      data.append("access_token", access_token)
      api_http
        .post("/users/auth/google_oauth2/callback", data)
        .then(({ data }) => {
          self.setLoginProfile(data)
        })
        .catch(({ response: { data, status } }) => {
          if (status === 422) {
            self.showError(data)
          } else {
            self.showError("Error Logging In")
          }
        })
    },
  }))
  .actions((self) => ({
    logout: () => {
      return self.logoutApi()
    },
    login: (email: string, password: string) => {
      return api_http
        .post("/api/profile/login", { email, password })
        .then(({ data: loginProfile }) => {
          self.setLoginProfile(loginProfile)

          return true
        })
        .catch((err) => {
          throw err.response.data.error
        })
    },
    signup: (
      data: any
      // email: string,
      // password: string,
      // password_confirmation: string
    ) => {
      return api_http
        .post("/api/profile/signup", data)
        .then(({ data: loginProfile }) => {
          self.setLoginProfile(loginProfile)

          return true
        })
        .catch((err) => {
          console.error({ err })
          throw err.response.data.error
        })
    },
    updateProfile: (user) => {
      return api_http
        .put("/api/profile/" + self.loginProfile.id, { user })
        .then(({ data: loginProfile }) => {
          self.setLoginProfile(loginProfile)

          return true
        })
        .catch((err) => {
          console.error({ err })
          throw err.response.data.error
        })
    },
    subscribe: (subscription) => {
      return api_http
        .post("/api/subscriptions", { subscription })
        .then((resp) => {
          self.showSuccess("Subscribed")

          return true
        })
        .catch((err) => {
          console.error({ err })
          throw err.response.data.error
        })
    },
    afterCreate: () => {
      // const loginProfile = getLoginProfile()
      // if (loginProfile) {
      //   self.setLoginProfile(loginProfile)
      // }
      // self.setLoaded(true)
      self.checkAuth().finally(() => self.setLoaded(true))
    },
  }))

export interface ILoginProfile extends Instance<typeof LoginProfile> {}
export interface IUser extends Instance<typeof User> {}
export interface IAuth extends Instance<typeof Auth> {}
export default Auth
