import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { IStore, INotification } from "../../stores"
import { flow } from "lodash"

export const NotificationContext = React.createContext<
  INotification | undefined
>(undefined)
interface Props {
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  loadingNotifications: boolean
  unreadCount: number
  notifications: INotification[]
  loadNotifications(listName?: string, args?: FIXME): Promise<FIXME>
}

// interface ContextProps {
//   loadingNotifications?: boolean
//   notifications: INotification[]
//   loadNotifications(args?: any)
//   loadNotification(id: number): Promise<INotification>
// }

const NotificationsProviderBase = (props: Props & PropsInjected) => {
  const {
    loadingNotifications,
    unreadCount,
    loadNotifications,
    notifications,
    children,
  } = props

  return (
    <NotificationsContext.Provider
      value={{
        loadingNotifications,
        unreadCount,
        loadNotifications,
        notifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export const NotificationsContext =
  React.createContext<PropsInjected>(undefined)

export const NotificationsProvider = flow(
  observer,
  inject<PropsInjected, Props>(({ notifications }: IStore) => {
    return {
      loadingNotifications:
        notifications.loadingIds.includes("notification_count"),
      unreadCount: notifications.unreadCount,
      notifications: notifications.list,
      loadNotifications: notifications.loadList,
    }
  })
)(NotificationsProviderBase)
