import React from "react"
import { Conversations } from "../Conversations"
import { DiyEvents } from "../../components/DiyEvents"
import { VendorPackageRequests } from "../VendorPackageRequests/VendorPackageRequests"
import { useAuth } from "../../Providers"
import { AppPageContent } from "../../components/shared"

interface Props {}

export const Dashboard = (props: Props) => {
  const { loginProfile } = useAuth()
  return (
    <AppPageContent title='Dashboard'>
      <Conversations hideIfEmpty={true} />
      {loginProfile.diy_events_count > 0 && <DiyEvents />}
      <VendorPackageRequests />
    </AppPageContent>
  )
}
export default Dashboard
