import React from "react"
import {
  Badge,
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core"
import { flow } from "lodash"
import { observer } from "mobx-react"
import Comments from "../../components/Comments/Comments"
import ReactMarkdown from "react-markdown"
import { IVendorPackageRequest } from "../../stores"
import { ProgressLoader } from "../../components/shared/ProgressLoader"
import moment from "moment"
import { MessagableUser } from "../../components/shared"
import { useAuth } from "../../Providers"
import BoxedContent from "../../components/shared/BoxedContent"
import { useHistory } from "react-router"
import { DIY_EVENT_URL, VENDOR_URL } from "../.."

const VendorPackageRequest = ({ vendorPackageRequest }: Props) => {
  const { loginProfile } = useAuth()
  const classes = useStyles()
  const { user, diy_event, vendor_package } = vendorPackageRequest
  const history = useHistory()
  const status = vendorPackageRequest.accepted_at
    ? "Accepted"
    : vendorPackageRequest.denied_at
    ? "Rejected"
    : vendorPackageRequest.canceled_at
    ? "Cancelled"
    : "Not responded"
  const statusAge =
    (vendorPackageRequest.accepted_at &&
      moment(vendorPackageRequest.accepted_at).fromNow()) ||
    (vendorPackageRequest.denied_at &&
      moment(vendorPackageRequest.denied_at).fromNow()) ||
    (vendorPackageRequest.canceled_at &&
      moment(vendorPackageRequest.canceled_at).fromNow())

  console.log({ user, loginProfile })
  const isRequestor = user.id === loginProfile.id
  const isActive =
    !vendorPackageRequest.accepted_at &&
    !vendorPackageRequest.denied_at &&
    !vendorPackageRequest.canceled_at
  return (
    <BoxedContent
      menuOptions={[
        {
          label: "Open Event",
          onSelect: () => {
            const win = window.open(DIY_EVENT_URL(diy_event.id), "_blank")
            win.focus()
          },
        },
        {
          label: "Open Vendor",
          onSelect: () => {
            const win = window.open(
              VENDOR_URL(vendor_package.vendor_id),
              "_blank"
            )
            win.focus()
          },
        },
        ...(!isRequestor && isActive
          ? [
              {
                label: "Accept",
                onSelect: () => {
                  vendorPackageRequest.accept()
                },
              },
              {
                label: "Reject",
                onSelect: () => {
                  vendorPackageRequest.reject()
                },
              },
            ]
          : []),
        ...(isRequestor && isActive
          ? [
              {
                label: "Cancel",
                onSelect: () => {
                  vendorPackageRequest.cancel()
                },
              },
            ]
          : []),
      ]}
    >
      <Grid container spacing={1}>
        {/* <Grid item xs={12}>
            Vendor Name: {vendor_package.vendor_name}
          </Grid> */}
        <Grid item xs={12}>
          <Typography variant='body1'>
            Status: {status} {statusAge}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            Package: {vendor_package.name}
          </Typography>
        </Grid>
        {user && (
          <Grid item xs={12}>
            <Typography variant='body1'>
              Requestor:{" "}
              <MessagableUser user={user}>{user.fullName()}</MessagableUser>
            </Typography>
          </Grid>
        )}
        {vendor_package && (
          <Grid item xs={12}>
            <Typography variant='body1'>
              Price: {vendor_package.price}
            </Typography>
          </Grid>
        )}
        {diy_event && (
          <Grid item xs={12}>
            <Typography variant='body1'>
              Event: {diy_event.name} on{" "}
              {moment(diy_event.wedding_date).format("LL")} (
              {moment(diy_event.wedding_date).fromNow()})
            </Typography>
          </Grid>
        )}
        {vendorPackageRequest?.note && (
          <Grid item xs={12}>
            Note:
            <ReactMarkdown>{vendorPackageRequest?.note}</ReactMarkdown>
          </Grid>
        )}
        <Grid item xs={12}>
          {vendorPackageRequest && (
            <Comments
              commentable={vendorPackageRequest}
              preLoad={false}
              label={"Request message"}
              replyable={false}
            />
          )}
        </Grid>
      </Grid>
      {!isRequestor && isActive && (
        <Grid item xs={12} className={classes.actionContainer}>
          {!vendorPackageRequest.accepted_at &&
            !vendorPackageRequest.denied_at && (
              <>
                <Button
                  onClick={() => {
                    vendorPackageRequest.accept()
                  }}
                  variant='contained'
                  color='secondary'
                >
                  Accept
                </Button>
                <Button
                  onClick={() => {
                    vendorPackageRequest.reject()
                  }}
                  variant='contained'
                  color='secondary'
                >
                  Reject
                </Button>
              </>
            )}
        </Grid>
      )}
      {isRequestor && isActive && (
        <Grid item xs={12} className={classes.actionContainer}>
          {!vendorPackageRequest.canceled_at && (
            <>
              <Button
                onClick={() => {
                  vendorPackageRequest.cancel()
                }}
                variant='contained'
                color='secondary'
              >
                Cancel
              </Button>
            </>
          )}
        </Grid>
      )}
    </BoxedContent>
  )
}

interface Props {
  vendorPackageRequest: IVendorPackageRequest
}

export default flow(observer)(VendorPackageRequest)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem 0",
    },
    actionContainer: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
)
