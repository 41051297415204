import React, { useContext, useState } from "react"
import { inject, observer } from "mobx-react"
import { IStore, IConversation } from "../stores"
import { flow } from "lodash"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import AddMessage from "../containers/Conversations/Messages/AddMessage"
import AppDialog from "../components/shared/AppDialog"

export const ConversationContext = React.createContext<
  IConversation | undefined
>(undefined)
interface Props {
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  loadingConversations: boolean
  unreadCount: number
  conversations: IConversation[]
  addMessage(receipts: number[], body: string, subject: any)
  loadConversations(listName?: string, args?: FIXME): Promise<FIXME>
}

// interface ContextProps {
//   loadingConversations?: boolean
//   conversations: IConversation[]
//   loadConversations(args?: any)
//   loadConversation(id: number): Promise<IConversation>
// }

const ConversationsProviderBase = (props: Props & PropsInjected) => {
  const {
    addMessage,
    loadingConversations,
    unreadCount,
    loadConversations,
    conversations,
    children,
  } = props
  const [newConvo, setNewConvo] = useState(false)
  const [receipts, setReceipts] = useState([1])
  return (
    <ConversationsContext.Provider
      value={{
        addMessage,
        loadingConversations,
        unreadCount,
        loadConversations,
        conversations,
      }}
    >
      <NewConversationsProvider>{children}</NewConversationsProvider>
    </ConversationsContext.Provider>
  )
}

export const NewConversationsContext = React.createContext<{
  sendMessageTo(val: (number | string)[])
}>(undefined)

export const useNewConversations = () => useContext(NewConversationsContext)

const NewConversationsProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[]
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [receipts, setReceipts] = useState([1])
  const sendMessageTo = (val: number[]) => {
    setReceipts(val)
    setOpenModal(true)
    console.log({ val })
  }
  const close = () => setOpenModal(false)
  return (
    <NewConversationsContext.Provider value={{ sendMessageTo }}>
      {children}
      <AppDialog open={openModal} onClose={close} title='Message User'>
        <AddMessage receipts={receipts} onDone={close} open={true} />
      </AppDialog>
    </NewConversationsContext.Provider>
  )
}

export const ConversationsContext =
  React.createContext<PropsInjected>(undefined)

export const ConversationsProvider = flow(
  observer,
  inject<PropsInjected, Props>(({ conversations }: IStore) => {
    return {
      loadingConversations:
        conversations.loadingIds.includes("conversation_count"),
      unreadCount: conversations.unreadCount,
      conversations: conversations.list,
      loadConversations: conversations.loadList,
      addMessage: conversations.addMessage,
    }
  })
)(ConversationsProviderBase)
