import { Instance, types } from "mobx-state-tree"
import { get, post } from "../../utils"
// import { Comment } from "./Comment"
import { IdedObject } from "./IdedObject"
import { LoadableStatus } from "./LoadableStatus"
import TimeStamped from "./TimeStamped"

export const Commentable = types
  .compose(
    "Commentable",
    IdedObject,
    LoadableStatus,
    types.model({
      comments: types.array(types.late(() => Comment)),
      comments_count: types.optional(types.number, 0),
    })
  )
  .actions((self) => ({
    setComment: (list) => {
      self.comments.replace(list)
    },
    addComment: (val) => {
      self.comments.push(val)
    },
    getCommentableType: () => {
      return "commentable"
    },
    incCommentCount: () => {
      self.comments_count += 1
    },
  }))
  .actions((self) => ({
    loadComments({ reload = false }: { reload?: boolean } = {}) {
      const listName = "comments"
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      self.startLoading(listName)
      return get(`/api/${self.getCommentableType()}/${self.id}/comments`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setComment(data)
        })
        .catch((err) => console.log(err))
    },
  }))
  .actions((self) => ({
    addComment: (body: string) => {
      console.log("self.getCommentableType()")
      console.log(self.getCommentableType())
      return post(`/api/${self.getCommentableType()}/${self.id}/comments`, {
        comment: { body },
      })
        .then(({ data }) => {
          return self.loadComments({ reload: true })
        })
        .catch((err) => console.log(err))
    },
  }))

export const Comment = types
  .compose(
    "Comment",
    IdedObject,
    TimeStamped,
    Commentable,
    types.model({
      body: types.string,
      rating: types.maybeNull(types.number),
      user_name: types.string,
    })
  )
  .actions((self) => ({
    getCommentableType: () => {
      return "comments"
    },
  }))

export interface IComment extends Instance<typeof Comment> {}
export interface ICommentable extends Instance<typeof Commentable> {}
