import React, { useContext, useEffect, useState } from "react"
import { IVendor } from "../stores/Vendors"
import { useParams, withRouter } from "react-router"
import { useAuth, VendorsContext } from "./"

export const ParamVendorProvider = withRouter(({ children }) => {
  const { loadVendor } = useContext(VendorsContext)
  const [vendor, setvendor] = useState<IVendor>()
  const [loadingVendor, setloading] = useState(false)
  const { loginProfile } = useAuth()

  let { id: idString } = useParams<{ id: string }>()
  const id = idString && parseInt(idString, 10)
  const isOwner =
    vendor &&
    loginProfile &&
    vendor.owners
      .map<string | number>((o) => o.user_id)
      .includes(loginProfile.id)

  console.log({ id, idString })
  useEffect(() => {
    if (!loadingVendor && loadVendor) {
      setloading(true)
      loadVendor(id).then((v) => {
        v && setvendor(v)
        setloading(false)
      })
    }
  }, [])

  return (
    <ParamVendorContext.Provider value={{ loadingVendor, vendor, isOwner }}>
      {children}
    </ParamVendorContext.Provider>
  )
})

export default ParamVendorProvider
interface ContextProps {
  loadingVendor?: boolean
  vendor: IVendor
  isOwner: boolean
}
export const ParamVendorContext = React.createContext<ContextProps>(undefined)
export const useParamVendor = () => useContext(ParamVendorContext)
