import React, { useContext } from "react"
import { Button, Card, CardContent, Container, Grid } from "@material-ui/core"
import { TextFieldControl } from "mui-hook-form-control"
import { useForm } from "react-hook-form"
import { AuthContext, ToastContext } from "../../Providers"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import GoogleLogin from "./GoogleLogin"
import FacebookLogin from "./FacebookLogin"

interface Props {}

const Login = ({}: Props) => {
  const { handleSubmit, errors, formState, control } = useForm({
    mode: "onBlur",
  })

  const { loggedIn, login } = useContext(AuthContext)
  const { showError, showSuccess } = useContext(ToastContext)
  const { t } = useTranslation()
  const history = useHistory()

  const handleLogin = handleSubmit(({ email, password }) => {
    login(email, password)
      .then(() => {
        showSuccess("Welcome!")
        history.goBack()
      })
      .catch((errors) => {
        showError(errors)
      })
  })

  return (
    <Container style={{ padding: "1rem" }}>
      {/* <Card>
        <CardContent> */}
      {loggedIn ? (
        <div>Logged in</div>
      ) : (
        <form onSubmit={handleLogin}>
          <Grid
            container
            spacing={3}
            direction='column'
            style={{ padding: "1rem" }}
          >
            <Grid item>
              <TextFieldControl
                label={t("common.email")}
                error={errors?.email}
                control={control}
                name='email'
              />
            </Grid>
            <Grid item>
              <TextFieldControl
                label={t("common.password")}
                error={errors?.password}
                control={control}
                name='password'
                type='password'
              />
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Button
                size='large'
                type='submit'
                variant='contained'
                color='secondary'
                fullWidth
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      <Grid container spacing={3} justifyContent='space-around'>
        <Grid item>
          <GoogleLogin />
        </Grid>
        <Grid item>
          <FacebookLogin />
        </Grid>
      </Grid>
      {/* </CardContent>
      </Card> */}
    </Container>
  )
}

export default Login
