import { Instance, types } from "mobx-state-tree"
import moment from "moment"

export const TimeStamped = types
  .model("TimeStamped", {
    created_at: types.string,
  })
  .views((self) => ({
    get createdAt() {
      return moment(self.created_at)
    },
  }))

export interface ITimeStamped extends Instance<typeof TimeStamped> {}
export default TimeStamped
