import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { IVendorPackageRequest } from "../../stores"
import { get, hashToParamString } from "../../utils"
import AppDialog from "../../components/shared/AppDialog"
import AppMenu from "../../components/shared/AppMenu"
import { GridColumns } from "@mui/x-data-grid"
import { ISearchFilter, ItemAttribute, PAGE_LIMIT_OPTION } from "."
import { debounce, get as getValue } from "lodash"
import AppTable from "../../components/shared/AppTable"

type Props = {
  limit?: number
  height?: number | string
}

const columns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 50,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Package",
    minWidth: 200,
    valueFormatter: ({ row: { item } }) => item?.vendor_package?.name,
  },
  {
    field: "vendor_name",
    headerName: "Vendor",
    minWidth: 200,
  },
  {
    field: "requestor",
    headerName: "Requestor",
    minWidth: 200,
    valueFormatter: ({ row: { item } }) =>
      [item?.user?.last_name, item?.user?.first_name, item?.user?.email].join(
        " "
      ),
  },
  { field: "created_at", headerName: "Created", minWidth: 150 },
  {
    field: "actions",
    headerName: " ",
    minWidth: 150,
    renderCell: ({ row: { item } }) => <Actions item={item} />,
  },
]

const Actions = ({ item }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  return (
    <>
      <AppDialog
        open={showDetails}
        title='Vendor Details'
        onClose={() => setShowDetails(false)}
      >
        <Detail request={item} />
      </AppDialog>
      <AppMenu
        options={[
          {
            label: "Show Detail",
            onSelect: () => {
              // toast("Masquerade coming soon...")
              setShowDetails(true)
            },
          },
        ]}
      />
    </>
  )
}

export default function VendorRequests({
  limit = PAGE_LIMIT_OPTION[1],
  height,
}: Props) {
  // const [vendorRequests, setVendorRequests] = useState<IVendorPackageRequest[]>(
  //   []
  // )
  // const [loading, setLoading] = useState<boolean>(false)
  // useEffect(() => {
  //   setLoading(true)
  //   get(
  //     `/api/admins/vendor_package_requests?order_by=created_at&order_direction=desc&limit=${limit}`
  //   )
  //     .then(({ data: { list, row_count } }) => {
  //       setVendorRequests(list)
  //     })
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }, [])
  const [rows, setRows] = useState<FIXME[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [filter, setFilter] = useState<ISearchFilter>({
    page: 0,
    page_size: limit,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const loadList = debounce(() => {
    if (loading) {
      return
    }
    setLoading(true)
    get(`/api/admins/vendor_package_requests?${hashToParamString(filter)}`)
      .then(({ data: { list, row_count } }) => {
        setRowCount(row_count)
        setRows(
          list.map((item) => ({
            id: item.id,
            // user: item,
            name: item.name,
            vendor_name: item.vendor_package.vendor_name,
            created_at: moment(item.created_at).fromNow(),
            item,
          }))
        )
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, 500)

  useEffect(() => {
    loadList()
  }, [filter])

  const updateFilter = (name, value) => {
    if (loading) {
      return
    }
    setFilter({ ...filter, [name]: value })
  }

  const handleSortChange = (model, details) => {
    if (loading) {
      return
    }
    setFilter({
      ...filter,
      order_by: model[0].field,
      order_direction: model[0].sort,
    })
  }
  return (
    <AppTable
      pagination
      rowCount={rowCount}
      page={filter.page}
      pageSize={filter.page_size}
      rowsPerPageOptions={PAGE_LIMIT_OPTION}
      onPageSizeChange={(pageSize: number, details?: any) =>
        updateFilter("page_size", pageSize)
      }
      onPageChange={(page) => {
        updateFilter("page", page)
      }}
      rows={rows}
      columns={columns}
      onSortModelChange={handleSortChange}
      filterMode='server'
      paginationMode='server'
      sortingMode='server'
      loading={loading}
      {...(height ? { style: { height } } : {})}
    />
  )
}

const Detail = ({ request }: { request: IVendorPackageRequest }) => {
  return (
    <Grid item>
      <Grid container spacing={2} direction='column'>
        {[
          "id",
          "user.email",
          "user.first_name",
          "user.last_name",
          "note",
          "accepted_at",
          "canceled_at",
          "denied_at",
          "diy_event.name",
          "vendor_package.name",
        ].map((name) => (
          <ItemAttribute
            label={name.replace(".", " ")}
            value={getValue(request, name)}
          />
        ))}
      </Grid>
    </Grid>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      minHeight: "3.5rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  })
)
