import React, { useContext, ReactNode, useState, ChangeEvent } from "react"
import { observer } from "mobx-react"
import { Box, Button, Tab, Tabs, Typography } from "@material-ui/core"
import { useHistory } from "react-router"
import { AuthContext } from "../../Providers"

import Login from "../Login"
// import Signup from "./Signup"
import { EDIT_PROFILE_URL } from "../../utils"

export const Profile = ({}: Props) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { loggedIn, loginProfile, logout } = useContext(AuthContext)
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }
  const history = useHistory()
  return (
    <div>
      {!loggedIn && (
        <>
          {/* <Tabs value={tabIndex} onChange={handleChange}> */}
          {/* <Tab label='Login' /> */}
          {/* <Tab label='Sign Up' /> */}
          {/* </Tabs> */}
          <Login />
          {/* <TabPanel value={tabIndex} index={0}>
          <Login />
          </TabPanel> */}
          {/* <TabPanel value={tabIndex} index={1}>
            <Signup />
          </TabPanel> */}
        </>
      )}
      {loggedIn && (
        <div>
          <div>Name: {loginProfile.fullName()}</div>
          <div>Email: {loginProfile.email}</div>
          {loginProfile.vendors_count > 0 && (
            <div>Vendors: {loginProfile.vendors_count}</div>
          )}
          {loginProfile.diy_events_count > 0 && (
            <div>Events: {loginProfile.diy_events_count}</div>
          )}
          <div>
            <Button
              onClick={() => {
                history.push(EDIT_PROFILE_URL)
              }}
            >
              Edit
            </Button>
            {/* <Button
              onClick={() => {
                logout()
              }}
            >
              Logout
            </Button> */}
          </div>
        </div>
      )}
    </div>
  )
}

interface Props {}
interface TabPanelProps {
  children?: ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
