import React, { useContext, useEffect, useState } from "react"
import { Button, Card, CardContent, Container } from "@material-ui/core"
// import { IStore } from "../../stores"
// import i18n from "../../utils/i18n"
// import { withTranslation, WithTranslation } from "react-i18next"
// import { zodResolver, zod } from "../../../utils"
import { FileButtonControl, TextFieldControl } from "mui-hook-form-control"
import { useForm } from "react-hook-form"
import { AuthContext, ToastContext, VendorsContext } from "../../../Providers"
import { useTranslation } from "react-i18next"
import { IVendor } from "../../../stores/Vendors"
import { flow, times } from "lodash"
import { observer, inject } from "mobx-react"
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom"
import { IStore } from "../../../stores"
import { WysiwygControl } from "../../../utils/Fields"
import ReactMarkdown from "react-markdown"
import { Watch } from "@material-ui/icons"

// interface State {
//   email: string
//   password: string
//   loginProfile?: ILoginProfile
//   errors?: string | string[]
// }

export const VendorEdit = ({}: Props & PropsInjected) => {
  // state = { email: "", password: "" }

  const { loadVendor } = useContext(VendorsContext)
  const [vendor, setvendor] = useState<IVendor>()
  const [loading, setloading] = useState(false)

  let { id } = useParams()
  useEffect(() => {
    if (!loading && loadVendor) {
      setloading(true)
      loadVendor(parseInt(id, 10)).then((v) => {
        console.log({ v })
        v && setvendor(v)
        setloading(false)
      })
    }
  }, [])

  const { handleSubmit, errors, formState, control, reset, watch } = useForm({
    mode: "onBlur",
  })

  const { isDirty, isSubmitting, dirtyFields } = formState
  const { loggedIn, login } = useContext(AuthContext)
  const { showError, showSuccess } = useContext(ToastContext)
  const { t } = useTranslation()
  const [imageCount, setimageCount] = useState(2)

  useEffect(() => {
    vendor && reset(vendor)
  }, [vendor, reset])
  const history = useHistory()

  const handleForm = handleSubmit((data: any) => {
    console.log({ data })
    vendor.updateVendor(data).then(() => {
      showSuccess("Saved!")
      history.goBack()
    })
  })
  if (!vendor) {
    return <></>
  }
  return (
    <Container>
      <Button onClick={() => history.goBack()}>Back</Button>
      {/* <Card>
        <CardContent> */}
      <form onSubmit={handleForm}>
        <TextFieldControl
          label='Name'
          error={errors?.name}
          control={control}
          name='name'
        />
        <WysiwygControl
          label='Description'
          error={errors?.description}
          defaultValue={vendor.description}
          control={control}
          name='description'
        />
        {/* {times(imageCount, (iCount) => {
          return (
            <FileButtonControl
              key={iCount}
              label='Images'
              error={errors?.images}
              control={control}
              name={`images_attributes[${iCount}].file`}
              onChange={(image) => {
                console.log({ image })
              }}
            />
          )
        })} */}
        {/* <FileButtonControl
              label='Images'
              error={errors?.images}
              control={control}
              name='images[]'
            /> */}

        <Button type='submit' variant='contained' color='primary'>
          Submit
        </Button>
      </form>
      {/* </CardContent>
      </Card>
      <Card>
        <ReactMarkdown>{watch("description")}</ReactMarkdown>
      </Card> */}
    </Container>
  )
}

// export default Edit
interface PropsInjected {
  // vendor?: IVendor
  // loading: boolean
}
interface Props {}

export default VendorEdit
