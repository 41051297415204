import React from "react"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"

interface Props extends DataGridProps {
  style?: React.CSSProperties
  className?: string
}
export default function AppTable({ style = {}, className, ...props }: Props) {
  return (
    <div
      className={className}
      style={{ height: "calc(100vh / 3)", width: "100%", ...style }}
    >
      <DataGrid {...props} />
    </div>
  )
}
