export const PLACEHOLDER_LANDING_PAGE_URL = "/"
export const ROOT_URL = "/app"
export const GROUPED_VEDNDORS_URL = "/grouped"

//  Static pages
export const OUR_STORY_URL = "/our_story"
export const PRESS_URL = "/press"
export const POLICIES_URL = "/policies"
export const CONTACT_US_URL = "/contact_us"
export const FAQS_URL = "/faq"
export const PACKAGES_URL = "/packages"

//  Signup
export const SIGNUP_URL = "/signup"
export const SIGNUP_EVENT_URL = "/signup/event"
export const VENDOR_SETUP_URL = "/signup/vendor"
export const VENDOR_SETUP_DETAIL_URL = `${VENDOR_SETUP_URL}/details`
export const VENDOR_SETUP_PACKAGE_URL = `${VENDOR_SETUP_URL}/package`
export const VENDOR_SETUP_UPLOAD_URL = (id: string | number) =>
  `${VENDOR_SETUP_DETAIL_URL}/${id}/uploads`
export const VENDOR_SETUP_LISTING_DETAIL_URL = (id: string | number) =>
  `${VENDOR_SETUP_DETAIL_URL}/${id}/listings`
export const VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL = (id: string | number) =>
  `${VENDOR_SETUP_DETAIL_URL}/${id}/service`
export const VENDOR_SETUP_EDIT_VENDOR_PACKAGE_URL = (
  id: string | number,
  package_id: string | number
) => `${VENDOR_SETUP_DETAIL_URL}/${id}/service/${package_id}`
export const PROCESS_EVENT_URL = "/signup/event/process"
export const DETAIL_EVENT_URL = "/signup/event/detail"

//  event
export const DASHBOARD_URL = "/dashboard"
export const EVENT_DASHBOARD_URL = "/event/dashboard"
export const VENDOR_DASHBOARD_URL = "/vendor/dashboard"

//  Profile
export const PROFILE_URL = "/profile"
export const EDIT_PROFILE_URL = "/profile/edit"

export const NOTIFICATIONS_URL = "/notifications"
export const CONVERSATIONS_URL = "/conversations"
export const CONVERSATION_URL = (id: string | number) =>
  `${CONVERSATIONS_URL}/${id}`
export const DIY_EVENTS_URL = "/events"
export const DIY_EVENT_URL = (id: string | number) => `${DIY_EVENTS_URL}/${id}`
export const DIY_EVENT_EDIT_URL = (id: string | number) =>
  `${DIY_EVENTS_URL}/${id}/edit`

export const VENDORS_URL = "/vendors"
export const VENDOR_URL = (id: string | number) => `${VENDORS_URL}/${id}`
export const VENDOR_EDIT_URL = (id: string | number) =>
  `${VENDORS_URL}/${id}/edit`

export const VENDOR_PACKAGE_REQUESTS_URL = "/package_requests"

//  Admin
export const ADMIN_DASHBOARD_URL = "/admins"
export const ADMIN_USERS_URL = "/admins/users"
export const ADMIN_VENDORS_URL = "/admins/vendors"
export const ADMIN_VENDOR_REQUESTS_URL = "/admins/requests"
export const ADMIN_VENDOR_TYPES_URL = "/admins/vendor_types"
