import { getRoot, Instance, types } from "mobx-state-tree"

export const Toastable = types.model("Toastable", {}).actions((self) => ({
  showMessage(data: string) {
    const { app_util } = getRoot(self)
    app_util.showMessage(data)
  },
  showError(data: string) {
    const { app_util } = getRoot(self)
    app_util.showError(data)
  },
  showSuccess(data: string) {
    const { app_util } = getRoot(self)
    app_util.showSuccess(data)
  },
}))

export interface IToastable extends Instance<typeof Toastable> {}
export default Toastable
