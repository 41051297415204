import { CircularProgress } from "@material-ui/core"
import React from "react"

export const ProgressLoader = ({
  show = true,
  size = 80,
}: {
  show?: boolean
  size?: number
}) => {
  return show ? (
    <div
      style={{
        margin: "auto auto",
        width: "100%",
        height: "100%",
        textAlign: "center",
        padding: "2rem",
      }}
    >
      <CircularProgress
        color='secondary'
        size={size}
        style={{ margin: "auto auto" }}
      />
    </div>
  ) : (
    <></>
  )
}
