import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slider,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core"
import { TextFieldControl } from "mui-hook-form-control"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { zod, zodResolver } from "../.."
import Vendors from "../Vendors/Vendors"
import SearchIcon from "@material-ui/icons/Search"
import { useVendors } from "../../Providers"
import {
  createStyles,
  makeStyles,
  Theme,
  InputAdornment,
} from "@material-ui/core"
import { DarkTheme, DefaultTheme } from "../../themes/DefaultTheme"
import DateRangeIcon from "@material-ui/icons/DateRange"
import FilterListIcon from "@material-ui/icons/FilterList"
import AppDialog from "../shared/AppDialog"
import moment from "moment"
import { SearchFilterDialog } from "./SearchFilterDialog"
interface Props {}

const searchSchema = zod.object({
  q: zod.string().optional(),
  location: zod.string().optional(),
  date: zod.string().optional(),
})

type SearchSchema = zod.infer<typeof searchSchema>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    palette: {
      type: "dark",
    },
    root: {
      // marginTop: "1rem",
      // backgroundColor: "#ffffffee",
      margin: "auto auto",
      border: "1px solid #FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px 0px",
      padding: "1.5rem",
      // "& .MuiInputBase-input, .MuiInputBase-root": {
      //   color: "#ffffff",
      // },
    },
  })
)

export const VendorSearch = (props: Props) => {
  const { control, handleSubmit, errors } = useForm<SearchSchema>({
    resolver: zodResolver(searchSchema),
  })

  const { loadVendors } = useVendors()
  const classes = useStyles()

  return (
    <ThemeProvider theme={DarkTheme}>
      <div className={classes.root}>
        {/* <Typography variant='h4'>create your</Typography>
        <Typography variant='h1' className={classes.perfect_day}>
          perfect day
        </Typography> */}
        <form onSubmit={handleSubmit(loadVendors)}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-end'
          >
            <Grid item>
              <TextFieldControl
                label='Search'
                name='q'
                error={errors?.q}
                control={control}
              />
            </Grid>
            <Grid item>
              <TextFieldControl
                label='Location'
                name='location'
                error={errors?.location}
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                name='date'
                control={control}
                defaultValue={moment().add(3, "months").format("YYYY-MM-DD")}
                render={(p) => {
                  return (
                    <TextField
                      label='Wedding date'
                      name='date'
                      type='date'
                      value={p.value}
                      onChange={p.onChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )
                }}
              />
              {/* </Grid> */}
            </Grid>
            <Grid item>
              <SearchFilterDialog />
            </Grid>
            <Grid item>
              <IconButton type='submit'>
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </form>
        {/* <Vendors /> */}
      </div>
    </ThemeProvider>
  )
}
export default VendorSearch
