import React from "react"
import Routes from "../routes"
import WebpackerReact from "webpacker-react"
import { RootProvider } from "../Providers"
import { Helmet } from "react-helmet"

window.app_domain = "http://zinnia-bay.herokuapp.com"

const DIYIDoApp = ({}) => (
  <RootProvider>
    <Helmet>
      <meta charSet='utf-8' />
      <title>Zinnia</title>
      <meta name='title' content='Zinnia' />
      <meta name='description' content='Wedding Vendors' />
      <link rel='canonical' href={window.app_domain} />

      <meta name='keywords' content='wedding, vendor, flowers' />
      <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
      <meta name='language' content='English' />
    </Helmet>
    <Routes />
  </RootProvider>
)

WebpackerReact.setup({ DIYIDoApp })
