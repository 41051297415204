import { Button } from "@material-ui/core"
import { flow } from "lodash"
import { observer } from "mobx-react"
import { TextFieldControl } from "mui-hook-form-control"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { ICommentable } from "../../stores/helpers/Commentable"
import { useTranslation } from "react-i18next"

const AddComment = ({ commentable, label = "Add Comment" }: Props) => {
  const [adding, setadding] = useState(false)
  const { handleSubmit, errors, formState, control, reset } = useForm({
    mode: "onChange",
  })
  const { isDirty, isValid } = formState
  const handleForm = handleSubmit((data: any) => {
    commentable.addComment(data.body).then(() => {
      reset({})
      setadding(false)
    })
  })
  const { t } = useTranslation()

  return (
    <div>
      {!adding && <Button onClick={() => setadding(true)}>{label}</Button>}
      {adding && (
        <form onSubmit={handleForm}>
          <TextFieldControl
            label={t("common.addComment")}
            error={errors?.body}
            control={control}
            name='body'
            multiline={true}
            rules={{ required: true }}
            autoFocus={true}
          />

          <Button
            disabled={!isDirty || !isValid}
            type='submit'
            variant='contained'
            color='primary'
          >
            {t("common.submit")}
          </Button>
          <Button onClick={() => setadding(false)}>Cancel</Button>
        </form>
      )}
    </div>
  )
}

interface Props {
  commentable: ICommentable
  label?: string | React.ReactElement
}

export default flow(observer)(AddComment)
