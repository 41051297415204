import React from "react"
import {
  ADMIN_DASHBOARD_URL,
  ADMIN_USERS_URL,
  ADMIN_VENDORS_URL,
  ADMIN_VENDOR_REQUESTS_URL,
  ADMIN_VENDOR_TYPES_URL,
} from "../../utils/constants"
import PrivateRoute from "../../components/PrivateRoute"
import { Dashboard } from "./Dashboard"
import Users from "./Users"
import Vendors from "./Vendors"
import VendorRequests from "./VendorRequests"
import { Button, Grid } from "@material-ui/core"
import VendorTypes from "./VendorTypes"
import { AppBackButton, AppPageContent } from "../../components/shared"
import { useHistory } from "react-router"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import pageWrapper from "../hoc/pageWrapper"
import { VendorTypesProvider } from "../../Providers/VendorTypesProvider"

export interface ISearchFilter {
  q?: string
  page_size?: number
  page?: number
  order_by?: string
  order_direction?: string
}

const PAGE_CONTENT_HEIGHT = "calc(100vh - 14rem)"
export const PAGE_LIMIT_OPTION = [10, 20, 50]

const AdminRoutes = () => {
  return (
    <>
      <VendorTypesProvider>
        <PrivateRoute
          role='admin'
          exact
          path={ADMIN_DASHBOARD_URL}
          component={Dashboard}
        />
        <PrivateRoute
          role='admin'
          exact
          path={ADMIN_USERS_URL}
          component={pageWrapper(Users, AppPageContent, "Users", {
            height: PAGE_CONTENT_HEIGHT,
          })}
        />
        <PrivateRoute
          role='admin'
          exact
          path={ADMIN_VENDORS_URL}
          component={pageWrapper(Vendors, AppPageContent, "Vendors", {
            height: PAGE_CONTENT_HEIGHT,
          })}
        />
        <PrivateRoute
          role='admin'
          exact
          path={ADMIN_VENDOR_REQUESTS_URL}
          component={pageWrapper(VendorRequests, AppPageContent, "Requests", {
            height: PAGE_CONTENT_HEIGHT,
          })}
        />
        <PrivateRoute
          role='admin'
          exact
          path={ADMIN_VENDOR_TYPES_URL}
          component={pageWrapper(VendorTypes, AppPageContent, "Vendor Types", {
            height: PAGE_CONTENT_HEIGHT,
          })}
        />
      </VendorTypesProvider>
    </>
  )
}

export const ItemAttribute = ({ label, value }) => (
  <Grid item>
    <Grid container spacing={2}>
      <Grid item sm={6} style={{ textAlign: "right" }}>
        {label}
      </Grid>
      <Grid item sm={6}>
        {value}
      </Grid>
    </Grid>
  </Grid>
)

export interface IFormFieldInfo {
  name: string
  label?: string
  inputProps?: FIXME
  type?: string
}

export default AdminRoutes
