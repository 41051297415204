import React from "react"

import { IVendor } from "../../../stores"

import { useVendorTypes } from "../../../Providers/VendorTypesProvider"
import { observer } from "mobx-react"
import { ModelForm } from "../shared/ModelForm"

export const VendorForm = observer(
  ({ item, onSubmit }: { item?: IVendor; onSubmit(data: FIXME) }) => {
    const { vendorTypes } = useVendorTypes()

    return (
      <ModelForm
        fieldInfos={[
          { name: "name" },
          {
            name: "description",
            inputProps: {
              multiline: true,
              rows: 3,
            },
          },
          {
            name: "vendor_type_id",
            label: "Vendor Type",
            type: "select",
            inputProps: {
              options: vendorTypes.map((vt) => ({
                label: vt.name,
                value: vt.id,
              })),
            },
          },
        ]}
        modelName='vendor'
        item={item}
        onSubmit={onSubmit}
      />
    )
  }
)
