import { CircularProgress, Grid } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { AppPageContent } from "../../components/shared"
import { ConversationContext, ConversationsContext } from "../../Providers"
import ConversationListItem from "./ConversationListItem"

export const Conversations = ({ hideIfEmpty }: { hideIfEmpty?: boolean }) => {
  const { loadingConversations, conversations } =
    useContext(ConversationsContext)
  if (hideIfEmpty && conversations.length === 0) {
    return <></>
  }
  return (
    <AppPageContent title={`Conversations (${conversations.length})`}>
      {loadingConversations ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={1}>
          {conversations.map((v) => (
            <Grid item key={v.id} xs={12} sm={12} md={6}>
              <ConversationContext.Provider value={v}>
                <ConversationListItem />
              </ConversationContext.Provider>
            </Grid>
          ))}
        </Grid>
      )}
    </AppPageContent>
  )
}

export default Conversations
