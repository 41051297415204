import { types, Instance } from "mobx-state-tree"
import AppUtil from "./app_util"

import Auth from "./auth"
import DiyEvents from "./DiyEvents"
import Notifications from "./Notifications"
import Conversations from "./Conversations"
import VendorPackageRequests from "./VendorPackageRequests"
import Vendors from "./Vendors"

export const Root = types
  .model("Root", {
    auth: Auth,
    app_util: AppUtil,
    vendors: Vendors,
    notifications: Notifications,
    conversations: Conversations,
    diy_events: DiyEvents,
    vendor_package_requests: VendorPackageRequests,
  })
  .actions((self) => ({
    clear: () => {
      self.vendors.clear()
      self.notifications.clear()
      self.conversations.clear()
      self.vendor_package_requests.clear()
      self.diy_events.clear()
      self.auth.clear()
      // self.auth.
      // self.auth = Auth.create({})
      // self.vendor_package_requests = VendorPackageRequests.create({})
      // self.diy_events = DiyEvents.create({})
      // self.vendors = Vendors.create({})
      // self.notifications = Notifications.create({})
      // self.app_util = AppUtil.create({})
    },
  }))

export interface IRoot extends Instance<typeof Root> {}
export default Root
