import React, { useContext, useState } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { DiyEventContext } from "../../Providers"
import ReactMarkdown from "react-markdown"
import { DIY_EVENT_URL } from "../../"
import moment from "moment"
import BoxedContent from "../shared/BoxedContent"
import { DIY_EVENT_EDIT_URL } from "../../utils"
import AppDrawer from "../shared/AppDrawer"
import EventEdit from "../DiyEvent/Edit/Form"

const DiyEventListItem = ({ history }: Props & RouteComponentProps) => {
  const diy_event = useContext(DiyEventContext)
  const [edit, setEdit] = useState(false)
  const classes = useStyles()
  return (
    <BoxedContent
      menuOptions={[
        {
          label: "Open",
          onSelect: () => history.push(DIY_EVENT_URL(diy_event.id)),
        },
        {
          label: "Edit",
          onSelect: () => setEdit(true),
        },
      ]}
    >
      <AppDrawer title='Edit Event' open={edit} onClose={() => setEdit(false)}>
        <DiyEventContext.Provider value={diy_event}>
          <EventEdit onDone={() => setEdit(false)} />
        </DiyEventContext.Provider>
      </AppDrawer>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            {diy_event.name}
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            {/* <ReactMarkdown>{diy_event.description}</ReactMarkdown> */}

            {moment(diy_event.wedding_date).format("LL")}
          </Typography>
        </CardContent>
      </div>
      {/* <CardMedia
        className={classes.cover}
        image={
          diy_event.images && diy_event.images.length > 0
            ? diy_event.images[0].url
            : "/Placeholder_view_vector.svg"
        }
        title='Live from space album cover'
      /> */}
    </BoxedContent>
  )
}

interface Props {}

export default withRouter(DiyEventListItem)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: "20rem",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
  })
)
