import React, { useContext } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { VendorContext } from "../../Providers"
import ReactMarkdown from "react-markdown"
import { VENDOR_URL } from "../../"
import RoomIcon from "@material-ui/icons/Room"

const VendorListItem = ({ history }: Props & RouteComponentProps) => {
  const vendor = useContext(VendorContext)
  const classes = useStyles()
  return (
    <Card className={classes.root} elevation={8}>
      {/* <CardActionArea> */}
      <CardMedia
        className={classes.media}
        image={
          vendor.images && vendor.images.length > 0
            ? vendor.images[0].url
            : "/Placeholder_view_vector.svg"
        }
        title='Contemplative Reptile'
      />
      <CardContent className={classes.cardContent}>
        <Typography
          gutterBottom
          variant='subtitle2'
          component='div'
          align='center'
          className={classes.title}
        >
          {vendor.listing_title || vendor.name}
        </Typography>
        <Typography
          gutterBottom
          variant='caption'
          component='div'
          align='center'
          className={classes.title}
        >
          {vendor.city && vendor.state && (
            <>
              <RoomIcon style={{ fontSize: ".75rem" }} />
              {(vendor.city as FIXME)?.capitalize()},{" "}
              {vendor.state.length === 2
                ? vendor.state.toLocaleUpperCase()
                : (vendor.state as FIXME)?.capitalize()}
            </>
          )}
        </Typography>
        <Typography
          variant='subtitle2'
          color='textSecondary'
          component='div'
          align='center'
          className={classes.description}
        >
          {vendor?.description && (
            <ReactMarkdown>{vendor?.description || ""}</ReactMarkdown>
          )}
        </Typography>
      </CardContent>
      {/* </CardActionArea> */}
      <CardActions className={classes.cardActions}>
        <Button size='small' variant='outlined' href={VENDOR_URL(vendor.id)}>
          Show me more
        </Button>
      </CardActions>
    </Card>
  )
}

interface Props {}

export default withRouter(VendorListItem)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "21rem",
      margin: "0 auto",
      height: "402px",
    },
    media: {
      height: "217px",
    },
    cardActions: {
      justifyContent: "center",
      marginBottom: ".5rem",
    },
    cardContent: {
      padding: ".5rem",
    },
    title: {
      minHeight: "1rem",
    },
    description: {
      minHeight: "3.5rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  })
)
