import { Button, CircularProgress, Grid, IconButton } from "@material-ui/core"
import { flow } from "lodash"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { ICommentable } from "../../stores/helpers/Commentable"
import AddComment from "./AddComment"
import CommentListItem from "./CommentListItem"
import ChatBubbleIcon from "@material-ui/icons/ChatBubble"
const Comments = observer(
  ({
    commentable,
    label = "Comment",
    preLoad = true,
    replyable = true,
    listHidable = true,
  }: Props) => {
    const loadComments = () => {
      if (
        commentable &&
        !commentable.isLoading("comments") &&
        !commentable.isLoaded("comments") &&
        commentable.comments.length === 0
      ) {
        commentable.loadComments()
      }
    }
    useEffect(() => {
      if (preLoad) {
        loadComments()
      }
    }, [commentable, preLoad, loadComments])
    const [showList, setshowList] = useState(true)
    if (!commentable) return <CircularProgress />
    return (
      <div>
        {listHidable && showList && commentable.comments.length > 0 && (
          <Button variant='outlined' onClick={() => setshowList(false)}>
            Hide
          </Button>
        )}
        {replyable && <AddComment commentable={commentable} label={label} />}
        {((commentable.comments_count > 0 &&
          !commentable.isLoaded("comments")) ||
          !showList) && (
          <IconButton
            onClick={() => {
              if (!showList && commentable.comments.length > 0) {
                setshowList(true)
              } else {
                setshowList(true)
                loadComments()
              }
            }}
          >
            {commentable.comments_count}
            <ChatBubbleIcon color='secondary' />
          </IconButton>
        )}
        {commentable.isLoading("comments") ? (
          <CircularProgress />
        ) : (
          <>
            {showList && (
              <Grid container spacing={1}>
                {commentable.comments.map((v) => (
                  <Grid item key={v.id} xs={12}>
                    <CommentListItem comment={v as any} replyable={replyable} />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </div>
    )
  }
)

interface Props {
  commentable: ICommentable
  label?: string | React.ReactElement
  preLoad?: boolean
  replyable?: boolean
  listHidable?: boolean
}

export default Comments
