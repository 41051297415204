import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { IStore, IMessage } from "../stores"
import { flow } from "lodash"

export const MessageContext = React.createContext<IMessage | undefined>(
  undefined
)
interface Props {
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  messages: IMessage[]
}

// interface ContextProps {
//   loadingMessages?: boolean
//   messages: IMessage[]
//   loadMessages(args?: any)
//   loadMessage(id: number): Promise<IMessage>
// }

export const MessagesProviderBase = (props: Props & PropsInjected) => {
  const { messages, children } = props

  return (
    <MessagesContext.Provider
      value={{
        messages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export const MessagesContext = React.createContext<PropsInjected>(undefined)
