import React from "react"
import { observer } from "mobx-react"
import {
  VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL,
  VENDOR_SETUP_EDIT_VENDOR_PACKAGE_URL,
} from "../../../utils/constants"
import PrivateRoute from "../../../components/PrivateRoute"
import { ParamVendorProvider } from "../../../Providers"
import AddVendorPackage from "./AddVendorPackage"

const withProvider = (Comp, Provider) => (props) =>
  (
    <Provider>
      <Comp {...props} />
    </Provider>
  )

const VendorPackage = observer(() => {
  return (
    <>
      <PrivateRoute
        exact
        path={VENDOR_SETUP_ADD_VENDOR_PACKAGE_URL(":id")}
        component={withProvider(AddVendorPackage, ParamVendorProvider)}
      />
      <PrivateRoute
        exact
        path={VENDOR_SETUP_EDIT_VENDOR_PACKAGE_URL(":id", ":package_id")}
        component={withProvider(AddVendorPackage, ParamVendorProvider)}
      />
    </>
  )
})

export default VendorPackage
