import React, { useContext } from "react"
import { Route } from "react-router-dom"
import { AuthContext } from "../Providers"
import { CircularProgress } from "@material-ui/core"
import { observer } from "mobx-react"
import { withProvider } from "../Providers/RouterProviders"

const PublicRoute = ({ component, providers = [], ...rest }) => {
  // const { loggedIn, loaded } = useContext(AuthContext)

  // if (loggedIn) {
  //   return <CircularProgress />
  // }
  let Component = component
  if (providers.length > 0) {
    providers.forEach((p) => {
      Component = withProvider(Component, p)
    })
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />
}
export default observer(PublicRoute)

// withProvider(GroupedVendors, VendorTypesProvider)
