import React, { useContext, useState } from "react"
// import { useHistory } from "react-router"
import { observer } from "mobx-react"
import {
  AppBar,
  Badge,
  createStyles,
  // fade,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
  InputBase,
  Button,
} from "@material-ui/core"

import { flow } from "lodash"
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  AccountCircle,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
  MoreVert as MoreIcon,
} from "@material-ui/icons"
import { AuthContext, VendorsContext } from "../../Providers"
import {
  DIY_EVENTS_URL,
  PROFILE_URL,
  VENDORS_URL,
  VENDOR_PACKAGE_REQUESTS_URL,
} from "../../"
import { NotificationsContext } from "../Notifications"
import {
  CONVERSATIONS_URL,
  NOTIFICATIONS_URL,
  ROOT_URL,
  SIGNUP_URL,
} from "../../utils"
import { UserNav } from "./UserNav"
import { Link, useHistory } from "react-router-dom"
import AppDialog from "../shared/AppDialog"
import Login from "../Login"
import { useLocation } from "react-router"

interface Props {
  window?: () => Window
  children: React.ReactElement
}

const NotificationIcon = () => {
  const { unreadCount } = useContext(NotificationsContext)
  const history = useHistory()
  return (
    <IconButton
      aria-label={`show #{unreadCount} new notifications`}
      color='inherit'
      onClick={() => history.push(NOTIFICATIONS_URL)}
    >
      <Badge badgeContent={unreadCount} color='secondary'>
        <NotificationsIcon />
      </Badge>
    </IconButton>
  )
}

const companyName = "ZINNIA"

function HideOnScroll(props: Props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

const Nav = (props: Props) => {
  const classes = useStyles()
  const { loaded, loginProfile } = useContext(AuthContext)
  const [search, setSearch] = React.useState<string>("")
  React.useState<null | HTMLElement>(null)
  const { loadVendors } = useContext(VendorsContext)
  const history = useHistory()
  const location = useLocation()
  const loggedIn = !!loginProfile

  if (!loaded || location.pathname === "/") {
    return <></>
  }
  return (
    <>
      <div className={classes.grow}>
        <HideOnScroll {...props}>
          <AppBar>
            <Toolbar>
              {/* <IconButton
                edge='start'
                className={classes.menuButton}
                color='inherit'
                aria-label='open drawer'
              >
                <MenuIcon />
              </IconButton> */}
              {/* <Link to={ROOT_URL}> */}
              <Typography
                className={classes.title}
                variant='h6'
                noWrap
                onClick={() => history.push(ROOT_URL)}
              >
                {companyName}
              </Typography>
              {/* </Link> */}
              {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <form
                  onSubmit={() => {
                    history.push(VENDORS_URL)
                    loadVendors({ q: search })
                    return false
                  }}
                >
                  <InputBase
                    placeholder='Search…'
                    autoFocus
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={({ currentTarget: { value } }) =>
                      setSearch(value)
                    }
                    inputProps={{ "aria-label": "search" }}
                  />
                </form>
              </div> */}
              <div className={classes.grow} />
              <div>
                {/* <Button href='http://localhost:6006/' target='_blank'>
                  Storybook
                </Button>
                <Button href='/api-docs/index.html' target='_blank'>
                  Swagger
                </Button> */}
                {loggedIn ? (
                  <>
                    {/* <IconButton
                      aria-label='show 4 new mails'
                      color='inherit'
                      onClick={() => history.push(CONVERSATIONS_URL)}
                    >
                      <MailIcon />
                    </IconButton> */}
                    {/* <NotificationIcon /> */}
                    <UserNav />
                  </>
                ) : (
                  <>
                    <Button href={SIGNUP_URL}>Sign up</Button>
                    <LoginButton />
                  </>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </div>
      <Toolbar />
    </>
  )
}

const LoginButton = () => {
  const [showLogin, setShowLogin] = useState(false)
  const handleLogin = () => {
    setShowLogin(true)
  }
  return (
    <>
      <AppDialog
        open={showLogin}
        title='Login'
        onClose={() => setShowLogin(false)}
        maxWidth='sm'
      >
        <Login />
      </AppDialog>
      <Button onClick={handleLogin} variant='contained' color='secondary'>
        Login
      </Button>
    </>
  )
}

export default flow(observer)(Nav)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      cursor: "pointer",
      // display: "none",
      // [theme.breakpoints.up("sm")]: {
      //   display: "block",
      // },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      // backgroundColor: fade(theme.palette.common.white, 0.15),
      // "&:hover": {
      //   backgroundColor: fade(theme.palette.common.white, 0.25),
      // },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
)
