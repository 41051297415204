import React from "react"
import TitledVendors from "../../components/Vendors/TitledVendors"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { AppPageContent } from "../../components/shared"
import { useVendorTypes } from "../../Providers/VendorTypesProvider"
import { observer } from "mobx-react"
interface Props {}

export const GroupedVendors = observer((props: Props) => {
  const classes = useStyles()
  const { vendorTypes } = useVendorTypes()
  console.log({ vendorTypes })
  return (
    <div className={classes.root}>
      {vendorTypes.map((vt) => (
        <AppPageContent key={vt.id} title={vt.name}>
          <TitledVendors
            name={vt.name}
            filters={{ limit: 3, vendor_type_id: vt.id }}
          />
        </AppPageContent>
      ))}
    </div>
  )
})
export default GroupedVendors

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 12,
    },
  })
)
