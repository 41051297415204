import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@material-ui/core"
// import { IStore } from "../../stores"
// import i18n from "../../utils/i18n"
// import { withTranslation, WithTranslation } from "react-i18next"
// import { zodResolver, zod } from "../../../utils"
import { FileButtonControl, TextFieldControl } from "mui-hook-form-control"
import { Controller, useForm } from "react-hook-form"
import { AuthContext, ToastContext } from "../../../Providers"
import { useTranslation } from "react-i18next"
import { IDiyEvent } from "../../../stores/DiyEvents"
import { flow, times } from "lodash"
import { observer, inject } from "mobx-react"
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom"
import { IStore } from "../../../stores"
import { DiyEventsContext } from "../../../Providers"
import { WysiwygControl } from "../../../utils/Fields"
import { useParamDiyEvent } from "../../../Providers/ParamDiyEventProvider"
import { ProgressLoader } from "../../shared/ProgressLoader"
import moment from "moment"

// interface State {
//   email: string
//   password: string
//   loginProfile?: ILoginProfile
//   errors?: string | string[]
// }

const Edit = ({}: Props & PropsInjected) => {
  // state = { email: "", password: "" }

  // const { loadDiyEvent } = useContext(DiyEventsContext)
  const { diy_event } = useParamDiyEvent()
  // const [diy_event, setdiy_event] = useState<IDiyEvent>()
  const [loading, setloading] = useState(false)

  // let { id } = useParams()
  // console.log({ loading, loadDiyEvent, id })
  // useEffect(() => {
  //   if (!loading && loadDiyEvent) {
  //     setloading(true)
  //     loadDiyEvent(parseInt(id, 10)).then((v) => {
  //       console.log({ v })
  //       v && setdiy_event(v)
  //       setloading(false)
  //     })
  //   }
  // }, [])

  const { handleSubmit, errors, formState, control, reset } = useForm({
    mode: "onBlur",
  })

  const { isDirty, isSubmitting, dirtyFields } = formState
  const { loggedIn, login } = useContext(AuthContext)
  const { showError, showSuccess } = useContext(ToastContext)
  const { t } = useTranslation()
  const [imageCount, setimageCount] = useState(2)

  useEffect(() => {
    diy_event && reset(diy_event)
  }, [diy_event, reset])

  const handleForm = handleSubmit((data: any) => {
    console.log({ data })
    diy_event.updateDiyEvent(data)
  })
  const history = useHistory()
  if (!diy_event) {
    return <ProgressLoader />
  }
  return (
    <Container>
      <Button onClick={() => history.goBack()}>Back</Button>
      <Card>
        <CardContent>
          <form onSubmit={handleForm}>
            <TextFieldControl
              label='Name'
              error={errors?.name}
              control={control}
              name='name'
            />
            <WysiwygControl
              label='Description'
              error={errors?.description}
              defaultValue={diy_event.description}
              control={control}
              name='description'
            />

            <Typography variant='h6'>What’s your wedding date?</Typography>

            <Controller
              name='wedding_date'
              control={control}
              defaultValue={moment().add(6, "months").toDate()}
              render={(p) => {
                return (
                  <TextField
                    label='Wedding Date'
                    name='wedding_date'
                    type='date'
                    value={p.value}
                    onChange={p.onChange}
                    // className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }}
            />
            {/* <TextFieldControl
              label='Description2'
              error={errors?.description2}
              control={control}
              name='description2[0]'
            />
            <TextFieldControl
              label='Description3'
              error={errors?.description2}
              control={control}
              name='description2[1]'
            /> */}
            {/* {times(imageCount, (iCount) => {
              return (
                <FileButtonControl
                  key={iCount}
                  label='Images'
                  error={errors?.images}
                  control={control}
                  name={`images_attributes[${iCount}].file`}
                  onChange={(image) => {
                    console.log({ image })
                  }}
                />
              )
            })} */}
            {/* <FileButtonControl
              label='Images'
              error={errors?.images}
              control={control}
              name='images[]'
            /> */}

            <Button type='submit' variant='contained' color='primary'>
              Submit
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}

interface PropsInjected {
  // diy_event?: IDiyEvent
  // loading: boolean
}
interface Props {}

export const DiyEventEdit = flow(
  observer,

  inject<PropsInjected, Props & RouteComponentProps>(
    (
      { diy_events: { list, loadById, isLoading, isLoaded } }: IStore,
      {
        match: {
          params: { id: idString },
        },
      }
    ) => {
      // const id = parseInt(idString, 10)
      // const diy_event = list.find((v) => v.id === id) as IDiyEvent | undefined
      // const loading = isLoading(id)
      // if (!diy_event && !loading && !isLoaded(id)) {
      //   loadById(id)
      // }

      return {
        // diy_event,
        // loading,
      }
    }
  ),
  withRouter
)(Edit)
export default DiyEventEdit
