import Axios from "axios"
import { types, Instance } from "mobx-state-tree"
import { get } from "../../utils"
import { ListIdManager } from "../helpers/ListIdManager"
import { queryString } from "../utils"
import { Notification } from "./Notification"

export const Notifications = types
  .compose(
    "Notifications",
    ListIdManager,
    types.model({
      list: types.optional(types.array(Notification), []),
      readCount: types.optional(types.number, 0),
      unreadCount: types.optional(types.number, 0),
    })
  )
  .actions((self) => ({
    setCounts: ({ read, unread }: { read: number; unread: number }) => {
      self.readCount = read
      self.unreadCount = unread
    },
  }))
  .actions((self) => ({
    loadCounts: (args = {}) => {
      const listName = "notification_count"
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/notifications/counts?${queryString(args)}`)
        .then(({ data }) => {
          self.setCounts(data)
          self.stopLoading(listName)
          return
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    loadList(listName = "root", args = {}) {
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/notifications?${queryString(args)}`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setList(listName, data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
  }))
  .actions((self) => ({
    loadEverything: () => {
      return self.loadList().then(() => self.loadCounts())
    },
  }))

export interface INotifications extends Instance<typeof Notifications> {}
export default Notifications
