import Axios from "axios"
import { types, Instance } from "mobx-state-tree"
import { get, post } from "../../utils"
import { ListIdManager } from "../helpers/ListIdManager"
import { queryString } from "../utils"
import { DiyEvent } from "./diy_event"

export const DiyEvents = types
  .compose(
    "DiyEvents",
    ListIdManager,
    types.model({
      list: types.optional(types.array(DiyEvent), []),
    })
  )
  .actions((self) => ({
    loadList(listName, args) {
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/diy_events?${queryString(args)}`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setList(listName, data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    loadById(id: number): Promise<any> {
      if (self.isLoading(id)) {
        return Promise.reject(undefined)
      }
      self.startLoading(id)
      return get(`/api/diy_events/${id}`)
        .then(({ data }) => {
          self.stopLoading(id)
          self.setList(id, [data])
          return self.list.find((v) => v.id === id)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(id)
        })
    },
    createDiyEvent: (diy_event) => {
      return post("/api/diy_events", { diy_event })
        .then(({ data }) => {
          self.setList(data.id, [data])

          return data
        })
        .catch((err) => {
          console.error({ err })
          throw err.response.data.error
        })
    },
  }))

export interface IDiyEvents extends Instance<typeof DiyEvents> {}
export default DiyEvents
