import { Instance, types } from "mobx-state-tree"
import { get, post, del } from "../../utils"
import Image from "../Image"
// import { Comment } from "./Comment"
import { IdedObject } from "./IdedObject"
import { LoadableStatus } from "./LoadableStatus"

export const Imageable = types
  .compose(
    "Imageable",
    IdedObject,
    LoadableStatus,
    types.model({
      images: types.array(Image),
    })
  )
  .actions((self) => ({
    setImages: (list) => {
      self.images.replace(list)
    },
    getImageableType: () => {
      return "imageable"
    },
    removeImageFromList: (id) => {
      self.images.replace(self.images.filter((im) => im.id !== id))
    },
  }))
  .actions((self) => ({
    loadImages({ reload = false }: { reload?: boolean } = {}) {
      const listName = "images"
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      self.startLoading(listName)
      return get(`/api/${self.getImageableType()}/${self.id}/images`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setImages(data)
        })
        .catch((err) => console.log(err))
    },
    deleteImage: (id) => {
      return del(`/api/${self.getImageableType()}/${self.id}/images/${id}`)
        .then(({ data }) => {
          self.removeImageFromList(id)
        })
        .catch((err) => console.log(err))
    },
  }))

export interface IImageable extends Instance<typeof Imageable> {}
