import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { observer } from "mobx-react"
import React, { useContext } from "react"
import {
  ConversationContext,
  MessageContext,
  MessagesContext,
} from "../../../Providers"
import AddMessage from "./AddMessage"
import MessageListItem from "./MessageListItem"

export const Messages = observer(() => {
  const { messages } = useContext(MessagesContext)
  const conversation = useContext(ConversationContext)

  return (
    <div>
      Messages ({messages.length})
      <div>
        <List>
          {messages.map((message) => (
            <ListItem alignItems='flex-start' key={message.id}>
              <ListItemText
                primary={
                  <Typography
                    component='span'
                    variant='body2'
                    color='textPrimary'
                  >
                    {conversation.recipients
                      .find((r) => r.id === message.sender_id)
                      ?.fullName()}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    {/* <Typography
                      component='span'
                      variant='body2'
                      color='textPrimary'
                    >
                      {
                        conversation.recipients.find(
                          (r) => r.id === message.sender_id
                        )?.name
                      }
                    </Typography> */}
                    {message.body}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
          {/* <Divider variant='inset' component='li' /> */}
        </List>
        {/* <Grid container spacing={1}>
          {messages.map((message) => (
            <Grid item key={message.id} xs={12}>
              <MessageContext.Provider value={message}>
                <MessageListItem />
              </MessageContext.Provider>
            </Grid>
          ))}
        </Grid> */}
      </div>
      <AddMessage />
    </div>
  )
})

export default Messages
