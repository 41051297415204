import { Button, Container, Grid } from "@material-ui/core"
import { flow } from "lodash"
import { observer } from "mobx-react"
import { TextFieldControl } from "mui-hook-form-control"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { IConversation } from "../../../stores/Conversations"
import { useTranslation } from "react-i18next"
import { ConversationContext, ConversationsContext } from "../../../Providers"

const AddMessage = ({
  label = "Add Message",
  receipts,
  onDone,
  open = false,
}: Props) => {
  const [adding, setadding] = useState(open)
  const { handleSubmit, errors, formState, control, reset } = useForm({
    mode: "onChange",
  })
  const { isDirty, isValid } = formState
  const conversation = useContext(ConversationContext)
  const { loadConversations, addMessage } = useContext(ConversationsContext)
  const handleForm = handleSubmit((data: any) => {
    if (receipts) {
      addMessage(receipts, data.body, data.subject).then(
        () => onDone && onDone()
      )
    } else {
      conversation.reply(data.body).then(() => {
        reset({})
        setadding(false)
        loadConversations()
      })
    }
  })
  const { t } = useTranslation()

  return (
    <div>
      {!adding && <Button onClick={() => setadding(true)}>{label}</Button>}
      {adding && (
        <form onSubmit={handleForm}>
          <Container>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <TextFieldControl
                  label={t("common.addMessage")}
                  error={errors?.body}
                  control={control}
                  name='body'
                  multiline={true}
                  rules={{ required: true }}
                  autoFocus={true}
                  variant='outlined'
                  rows={3}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={!isDirty || !isValid}
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  {t("common.submit")}
                </Button>
                <Button
                  onClick={() => {
                    setadding(false)
                    onDone && onDone()
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
    </div>
  )
}

interface Props {
  conversation: IConversation
  label?: string | React.ReactElement
  receipts?: number[]
  open?: boolean
  onDone?()
}

export default flow(observer)(AddMessage)
