import React from "react"
import {
  Container,
  createStyles,
  Drawer,
  DrawerProps,
  Fab,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core"

const AppDrawer = ({ anchor = "right", children, title, ...rest }: Props) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor={anchor}
      classes={{
        paperAnchorRight: classes.root,
        paperAnchorLeft: classes.root,
      }}
      {...rest}
    >
      {title && (
        <Container className={classes.title}>
          <Typography variant='h5'>{title}</Typography>
        </Container>
      )}
      {children}
    </Drawer>
  )
}

interface Props extends DrawerProps {
  title?: string
}

export default AppDrawer

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "30%",
    },
    title: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  })
)
