import {
  CircularProgress,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Container,
  Typography,
} from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { VendorsContext, VendorContext, useVendors } from "../../Providers"
import { ProgressLoader } from "../shared/ProgressLoader"
import VendorListItem from "./VendorListItem"

export const Vendors = () => {
  const { loadingVendors, vendors, loadVendors } = useVendors()
  useEffect(() => {
    loadVendors()
  }, [])
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {loadingVendors ? (
        <ProgressLoader />
      ) : (
        <Container className={classes.gridContainer}>
          <Grid
            container
            spacing={3}
            className={classes.grid}
            justifyContent='center'
            alignContent='center'
            alignItems='center'
          >
            {vendors.map((v) => (
              <Grid item key={v.id} xs={12} sm={12} md={4}>
                <VendorContext.Provider value={v}>
                  <VendorListItem />
                </VendorContext.Provider>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </div>
  )
}

export default Vendors
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: "1rem auto",
      // height: "100%",
    },
    gridContainer: {
      maxWidth: "70rem",
      margin: "1rem auto",
    },
    grid: {
      // margin: "1rem auto",
    },
  })
)
