import React from "react"
import {
  SIGNUP_URL,
  SIGNUP_EVENT_URL,
  PROCESS_EVENT_URL,
  EVENT_DASHBOARD_URL,
  VENDOR_DASHBOARD_URL,
  DASHBOARD_URL,
} from "../../utils/constants"
import PrivateRoute from "../../components/PrivateRoute"
import PublicRoute from "../../components/PublicRoute"

import { EventDashboard } from "./EventDashboard"
import { VendorDashboard } from "./VendorDashboard"
import { Dashboard } from "./Dashboard"
import { AppPageContent } from "../../components/shared"

const SignupRoutes = () => {
  return (
    <>
      <PrivateRoute exact path={DASHBOARD_URL} component={Dashboard} />
      <PrivateRoute
        exact
        path={EVENT_DASHBOARD_URL}
        component={EventDashboard}
      />
      <PrivateRoute
        exact
        path={VENDOR_DASHBOARD_URL}
        component={VendorDashboard}
      />
    </>
  )
}

export default SignupRoutes
