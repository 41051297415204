import React from "react"
import { Grid } from "@material-ui/core"
import { IAdminVendorType } from "../../../stores"
import { Image } from "../../../components/Images"
import { ItemAttribute } from "../"

export const Detail = ({ item }: { item: IAdminVendorType }) => {
  return (
    <Grid item>
      <Grid container spacing={2} direction='column'>
        {/* <Images imageable={item} /> */}
        {item.images[0] && (
          <Image
            image={item.images[0]}
            onDelete={(val) => {
              item.deleteImage(val.id)
            }}
            style={{ maxWidth: "10rem" }}
          />
        )}
        {["id", "name", "description", "featured"].map((name) => (
          <ItemAttribute
            key={name}
            label={name}
            value={item[name].toString()}
          />
        ))}
      </Grid>
    </Grid>
  )
}
