import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import { Button, Container, Typography, Grid } from "@material-ui/core"
import { IStore, IVendor } from "../../stores"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router"
import { zod, zodResolver } from "../../utils"
import { FileButtonControl, TextFieldControl } from "mui-hook-form-control"
import {
  PROCESS_EVENT_URL,
  VENDOR_SETUP_LISTING_DETAIL_URL,
} from "../../utils/constants"
import { times } from "lodash"
import { watch } from "fs"
import { useVendor, useVendors } from "../../Providers"
import { Images } from "../../components/Images"

function SignupEventPage({
  createVendor,
  showError,
  showSuccess,
}: PropsInjected) {
  const history = useHistory()
  const { control, handleSubmit, errors, watch } = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  })
  const { id: idString } = useParams<{ id?: string }>()
  const id = idString && parseInt(idString, 10)
  console.log({ id })
  const [imageCount, setImageCount] = useState(1)

  const [vendor, setVendor] = useState<IVendor | undefined>(undefined)

  const { loadVendor, vendors, loadingVendors } = useVendors()

  // const vendor = vendors.find((v) => v.id === id)

  useEffect(() => {
    if (id) {
      loadVendor(id).then((data) => {
        console.log({ vData: data })
        setVendor(data)
      })
    }
  }, [id])

  console.log({ vendor, vendors })

  if (!vendor) {
    return <></>
  }

  const handleLogin = (data) => {
    console.log({ data })
    vendor &&
      vendor
        .updateVendor(data)
        .then(() => showSuccess("Updated!"))
        // .then(() => history.push(PROCESS_EVENT_URL))
        .catch(showError)
  }

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Container>
        <div style={{ paddingTop: "2rem", maxWidth: "400px" }}>
          <Typography variant='h4' style={{ marginBottom: "2rem" }}>
            Upload some photos
          </Typography>
          <Typography variant='h6' style={{ marginBottom: "1rem" }}>
            We need some photos to get your listing up and running
          </Typography>
          <Images imageable={vendor} />
          <Grid container direction='column' spacing={3}>
            {times(imageCount, (iCount) => {
              return (
                <Grid item key={iCount}>
                  <FileButtonControl
                    label='Upload images'
                    error={errors?.images_attributes}
                    control={control}
                    name={`images_attributes[${iCount}].file`}
                    onChange={(image) => {
                      console.log({ image })
                    }}
                  />
                </Grid>
              )
            })}
            {/* {times(imageCount, (num) => {
              const fieldName = `images_attributes[${num}]`
              console.log({
                images: errors?.images_attributes,
                watch: watch(fieldName),
                errors,
              })
              return (
                <Grid item key={num}>
                  <FileButtonControl
                    label='Upload images'
                    name={fieldName}
                    // error={errors?.images_attributes[num]}
                    control={control}
                  />
                </Grid>
              )
            })} */}
          </Grid>
        </div>
        <Grid container justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Button variant='outlined' onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='secondary' type='submit'>
              Upload
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='secondary'
              onClick={() =>
                history.push(VENDOR_SETUP_LISTING_DETAIL_URL(vendor.id))
              }
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}

interface PropsInjected {
  loggedIn: boolean
  createVendor(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup },
    vendors: { createVendor },
    app_util: { showError, showSuccess },
  }: IStore) => {
    return {
      loggedIn,
      createVendor,
      showError,
      showSuccess,
    }
  }
)(observer(SignupEventPage))

//  Validations
const signupSchema = zod.object({
  images_attributes: zod.any(),
})

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
