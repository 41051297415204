import Axios from "axios"
import { types, Instance, getRoot } from "mobx-state-tree"
import { get, post } from "../../utils"
import { ListIdManager } from "../helpers/ListIdManager"
import { IRoot } from "../Root"
import { queryString } from "../utils"
import { Conversation } from "./Conversation"

export const Conversations = types
  .compose(
    "Conversations",
    ListIdManager,
    types.model({
      list: types.optional(types.array(Conversation), []),
      readCount: types.optional(types.number, 0),
      unreadCount: types.optional(types.number, 0),
    })
  )
  .actions((self) => ({
    setCounts: ({ read, unread }: { read: number; unread: number }) => {
      self.readCount = read
      self.unreadCount = unread
    },
  }))
  .actions((self) => ({
    loadCounts: (args = {}) => {
      const listName = "conversation_count"
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/conversations/counts?${queryString(args)}`)
        .then(({ data }) => {
          self.setCounts(data)
          self.stopLoading(listName)
          return
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    loadList(listName = "root", args = {}) {
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/conversations?${queryString(args)}`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setList(listName, data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
  }))
  .actions((self) => ({
    loadEverything: () => {
      return self.loadList().then(() => self.loadCounts())
    },
  }))
  .actions((self) => ({
    addMessage: (recipients: number[], body: string, subject) => {
      return post(`/api/conversations`, { recipients, body, subject }).then(
        () => {
          self.loadEverything()
          const { app_util }: IRoot = getRoot(self)
          app_util.showSuccess("Message Sent!")
        }
      )
    },
  }))

export interface IConversations extends Instance<typeof Conversations> {}
export default Conversations
