import React, { useContext } from "react"
// import { useHistory } from "react-router"
import { observer } from "mobx-react"
import {
  AppBar,
  createStyles,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
  InputBase,
  Button,
  Grid,
  Container,
  GridProps,
} from "@material-ui/core"

import { flow } from "lodash"

import { useAuth } from "../../Providers"
import {
  DIY_EVENTS_URL,
  PROFILE_URL,
  VENDORS_URL,
  VENDOR_PACKAGE_REQUESTS_URL,
} from "../../"
import { NotificationsContext } from "../Notifications"
import {
  CONTACT_US_URL,
  CONVERSATIONS_URL,
  FAQS_URL,
  NOTIFICATIONS_URL,
  OUR_STORY_URL,
  PACKAGES_URL,
  POLICIES_URL,
  PRESS_URL,
  ROOT_URL,
  SIGNUP_URL,
} from "../../utils"
import { Link, useHistory } from "react-router-dom"
import TwitterIcon from "@material-ui/icons/Twitter"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

interface Props {
  window?: () => Window
  children: React.ReactElement
}

function HideOnScroll(props: Props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

const Footer = (props: Props) => {
  const classes = useStyles()
  const { loaded, loginProfile, logout } = useAuth()
  // const [search, setSearch] = React.useState<string>("")
  React.useState<null | HTMLElement>(null)
  // const { loadVendors } = useContext(VendorsContext)
  // const history = useHistory()
  const loggedIn = !!loginProfile

  if (!loaded) {
    return <></>
  }

  const gridLayout: GridProps = { xs: 12, sm: 3, md: 2, lg: 2 }
  return (
    <>
      <div className={classes.root}>
        {/* <div className={classes.wave}></div> */}
        {/* <AppBar> */}

        {/* <Toolbar variant='regular'></Toolbar> */}
        {/* </AppBar> */}
        <Container style={{ margin: "1rem auto" }}>
          <div style={{ maxWidth: "15rem", margin: "0 auto" }}>
            <Grid container direction='row' alignItems='center' spacing={3}>
              <Grid item xs={6}>
                <a href='https://www.instagram.com/planzinnia' target='_blank'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid itemProp=''>
                      <InstagramIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <div style={{ marginTop: "10px" }}>Follow us</div>
                    </Grid>
                  </Grid>
                </a>
              </Grid>
              <Grid item xs={6}>
                <a href='mailto:mindy@planzinnia.com' target='_blank'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <MailOutlineIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <div style={{ marginTop: "10px" }}>Email us</div>
                    </Grid>
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      {/* <Toolbar /> */}
    </>
  )
}

export default flow(observer)(Footer)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      // backgroundColor: "#ff0000",
      // margin: "0 auto",
    },
    root: {
      flexGrow: 1,
      color: "#31A7AD",
      backgroundColor: "#ffffff",
      minHeight: "7em",
      marginTop: "0",
      padding: "2rem",
      "& a": {
        color: "#31A7AD",
        textDecoration: "none",
      },
      // backgroundColor: "#000000",
      "-webkit-mask": "url(/wave_bottom.png) top/100% 100%",
    },
    wave: {
      color: "#ffffff",
      backgroundColor: "#000000de",
      // backgroundImage: "url(/bottom-wave.png)",
      // backgroundAttachment: "fixed",
      // backgroundPosition: "top",
      // height: "19px",
      // backgroundSize: "contain",
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    link: {
      color: "#31A7AD",
    },
    icon: {
      // marginRight: ".5rem",
      // avatar: {
      // backgroundColor: theme.palette.grey[50],
      // border: `1px solid ${theme.palette.info.main}`,
      // borderRadius: "1rem",
      // color: theme.palette.info.main,
      // },
      // border: "2px solid #31A7AD",
      marginTop: "1rem",
      marginRight: ".25rem",
      borderRadius: "19px",
      padding: "5px",
      fontSize: "1.5rem",
    },
    gridItem: {
      // textAlign: "center",
      // maxWidth: "15rem",
    },
  })
)
