import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { IImage } from "../../stores/Image"
import { CircularProgress, Grid, GridSize } from "@material-ui/core"
import { IImageable } from "../../stores/helpers/Imageable"
import { flow } from "lodash"
import { observer } from "mobx-react"
import { Image } from "./"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
})
interface Props {
  imageable: IImageable
  loadImages?: boolean
  xs?: GridSize
  sm?: GridSize
}

export const Images = observer(
  ({ imageable, loadImages = true, xs = 12, sm = 6 }: Props) => {
    const classes = useStyles()
    if (!imageable) {
      return <></>
    }
    useEffect(() => {
      if (
        loadImages &&
        imageable &&
        !imageable?.isLoading("images") &&
        !imageable?.isLoaded("images") &&
        imageable.images.length === 0
      ) {
        imageable.loadImages()
      }
    }, [loadImages, imageable])
    if (!imageable) return <CircularProgress />
    return (
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent='center'>
          {imageable.images.map((image) => (
            <Grid item key={image.id} xs={xs} sm={sm}>
              <Image image={image} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }
)

export default Images
