import React, { useState, useContext } from "react"
import { inject, observer } from "mobx-react"
import { IStore, IVendor } from "../stores"
import { flow } from "lodash"
import { Typography } from "@material-ui/core"

export const VendorContext = React.createContext<IVendor | undefined>(undefined)
export const useVendor = () => useContext(VendorContext)
interface Props {
  listName: string
  filters?: FIXME
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  loadVendorsList(name: string, args?: any): Promise<IVendor[]>
  loadVendor(id: number): Promise<IVendor>
}

interface ContextProps {
  loadingVendors?: boolean
  vendors: IVendor[]
  loadVendors(args?: any)
  loadVendor(id: number): Promise<IVendor>
}

const VendorsProviderBase = (props: Props & PropsInjected) => {
  const {
    loadVendor,
    loadVendorsList,
    listName,
    children,
    filters = {},
  } = props
  const [vendors, setvendors] = useState<IVendor[]>([])
  const [loadingVendors, setLoadingVendors] = useState<boolean>(false)
  const loadVendors = (args) => {
    if (loadingVendors) {
      return
    }
    setLoadingVendors(true)
    loadVendorsList(listName, { ...args, ...filters })
      .then((list) => {
        setvendors(list)
        setLoadingVendors(false)
      })
      .catch(() => {
        setLoadingVendors(false)
      })
  }

  return (
    <VendorsContext.Provider
      value={{ vendors, loadVendors, loadingVendors, loadVendor }}
    >
      {children}
    </VendorsContext.Provider>
  )
}

export const VendorsContext = React.createContext<ContextProps>(undefined)
export const useVendors = () => useContext(VendorsContext)
export const VendorsProvider = flow(
  observer,
  inject<PropsInjected, Props>(
    ({
      vendors: { list, list_ids, loadList, loadingIds, loadedIds, loadById },
    }: IStore) => {
      return {
        loadVendor: loadById,
        loadVendorsList: (listName, args) => {
          if (!loadingIds.includes(listName)) {
            return loadList(listName, args).then(() => {
              const ids = list_ids.get(listName) || []
              return list.filter((v) => ids.includes(v.id)) as IVendor[]
            })
          } else {
            const ids = list_ids.get(listName) || []
            return Promise.reject(
              list.filter((v) => ids.includes(v.id)) as IVendor[]
            )
          }
        },
      }
    }
  )
)(VendorsProviderBase)
