import { types, Instance } from "mobx-state-tree"
import { get, post, put, del } from "../../utils"
import { Commentable } from "../helpers/Commentable"
import { IdedObject } from "../helpers/IdedObject"
import { Imageable } from "../helpers/Imageable"
import { LoadableStatus } from "../helpers/LoadableStatus"
// import Image from "../Image"

export const UserVendor = types.compose(
  "UserVendor",
  IdedObject,
  types.model({
    user_id: types.maybeNull(types.number),
    user_name: types.maybeNull(types.string),
  })
)
export const VendorType = types
  .compose(
    "VendorType",
    IdedObject,
    Imageable,
    types.model({
      name: types.string,
      description: types.maybeNull(types.string),
      featured: types.maybeNull(types.boolean),
      active: types.maybeNull(types.boolean),
    })
  )
  .actions((self) => ({
    getImageableType: () => {
      return "vendor_types"
    },
  }))
export const AdminVendorType = types
  .compose(
    "AdminVendorType",
    VendorType,
    types.model({
      name: types.string,
      description: types.maybeNull(types.string),
      featured: types.maybeNull(types.boolean),
    })
  )
  .actions((self) => ({
    getImageableType: () => {
      return "vendor_types"
    },
  }))
export const VendorPackage = types
  .compose(
    "VendorPackage",
    IdedObject,
    Commentable,
    Imageable,
    types.model({
      name: types.string,
      price: types.maybeNull(types.number),
      vendor_id: types.maybeNull(types.number),
      description: types.maybeNull(types.string),
      quantity: types.maybeNull(types.number),
      average_rating: types.maybeNull(types.number),
      vendor_name: types.maybeNull(types.string),
      address_1: types.maybeNull(types.string),
      vendor_type_id: types.maybeNull(types.number),
      vendor_type_name: types.maybeNull(types.string),
    })
  )
  .actions((self) => ({
    getCommentableType: () => {
      return "vendor_packages"
    },
    requestPackage: (diy_event) => {
      return post(`/api/diy_events/${diy_event.id}/vendor_package_requests`, {
        vendor_package_request: { vendor_package_id: self.id },
      })
    },
    update: (vendor_package) => {
      return put(`/api/vendors/${self.vendor_id}/vendor_packages/${self.id}`, {
        vendor_package,
      })
    },
  }))

export const Vendor = types
  .compose(
    "Vendor",
    IdedObject,
    Commentable,
    LoadableStatus,
    Imageable,
    types.model({
      name: types.string,
      description: types.maybeNull(types.string),
      average_rating: types.maybeNull(types.number),
      vendor_packages: types.array(VendorPackage),
      owners: types.array(UserVendor),
      created_at: types.maybeNull(types.string),
      latitude: types.maybeNull(types.string),
      longitude: types.maybeNull(types.string),
      address_1: types.maybeNull(types.string),
      address_2: types.maybeNull(types.string),
      city: types.maybeNull(types.string),
      state: types.maybeNull(types.string),
      zip: types.maybeNull(types.string),
      place_id: types.maybeNull(types.string),
      long: types.maybeNull(types.string),
      lat: types.maybeNull(types.string),
      kind: types.maybeNull(types.string),
      vibe: types.maybeNull(types.string),
      listing_title: types.maybeNull(types.string),
      vendor_type_name: types.maybeNull(types.string),
      // images: types.array(Image),
    })
  )
  .actions((self) => ({
    getCommentableType: () => {
      return "vendors"
    },
    getImageableType: () => {
      return "vendors"
    },
    setPackages: (val) => {
      self.vendor_packages.replace(val)
    },
    removePackageFromList: (id) => {
      self.vendor_packages.replace(
        self.vendor_packages.filter((vp) => vp.id !== id)
      )
    },
  }))
  .actions((self) => ({
    loadPackages: () => {
      const listName = "vendor_packages"
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/vendors/${self.id}/vendor_packages`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setPackages(data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    deleteVendorPackage: (vendor_package_id) => {
      return del(
        `/api/vendors/${self.id}/vendor_packages/${vendor_package_id}`
      ).then((resp) => {
        self.removePackageFromList(vendor_package_id)
      })
    },
    // updateVendor: (data) => {
    updateVendor: ({ images_attributes, ...rest }) => {
      const vendor = new FormData()
      // console.log({ images_attributes })
      images_attributes &&
        images_attributes.forEach(({ file }) => {
          if (file) {
            vendor.append("[vendor]images_attributes[]file", file)
          }
        })

      for (const key in rest) {
        vendor.append(`[vendor]${key}`, rest[key])
      }
      return put(`/api/vendors/${self.id}`, vendor, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(({ data }) => {
          self.updateSelf(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    createVendorPackage: ({ images_attributes, ...rest }) => {
      const vendor_package = new FormData()
      images_attributes &&
        images_attributes.forEach(({ file }) => {
          if (file) {
            vendor_package.append(
              "[vendor_package]images_attributes[]file",
              file
            )
          }
        })

      for (const key in rest) {
        vendor_package.append(`[vendor_package]${key}`, rest[key])
      }

      return post(`/api/vendors/${self.id}/vendor_packages`, vendor_package)
        .then(({ data }) => {
          // self.setList(data.id, [data])
          self.setPackages([...self.vendor_packages, data])

          return self.vendor_packages.find((v) => v.id === data.id)
        })
        .catch((err) => {
          console.error({ err })
          throw err.response.data.error
        })
    },
  }))

export interface IUserVendor extends Instance<typeof UserVendor> {}
export interface IVendorPackage extends Instance<typeof VendorPackage> {}
export interface IVendor extends Instance<typeof Vendor> {}
export interface IVendorType extends Instance<typeof VendorType> {}
export interface IAdminVendorType extends Instance<typeof AdminVendorType> {}
export default Vendor
