import React from "react"
import { Button, Grid } from "@material-ui/core"
import { IFormFieldInfo } from "../"
import { IAdminVendorType, IVendor } from "../../../stores"
import {
  TextFieldControl,
  CheckboxControl,
  FileButtonControl,
  SelectControl,
} from "mui-hook-form-control"

export const ItemAttributeEdit = ({
  item,
  fieldInfo,
  errors,
  control,
}: {
  errors: FIXME
  control: FIXME
  item?: IVendor | IAdminVendorType
  fieldInfo: IFormFieldInfo
}) => {
  let FieldControl = TextFieldControl
  if (fieldInfo?.type === "checkbox") {
    FieldControl = CheckboxControl
  }
  if (fieldInfo?.type === "image") {
    FieldControl = FileButtonControl
  }
  if (fieldInfo?.type === "select") {
    FieldControl = SelectControl
  }
  return (
    <Grid item>
      {fieldInfo?.type === "image" ? (
        <>
          <FieldControl
            label={fieldInfo.label || fieldInfo.name}
            error={errors[fieldInfo.name]}
            control={control}
            name={fieldInfo.name}
            // defaultValue={item ? item[fieldInfo.name] : undefined}
            {...(fieldInfo.inputProps ? fieldInfo.inputProps : {})}
          />
        </>
      ) : (
        <FieldControl
          label={fieldInfo.label || fieldInfo.name}
          error={errors[fieldInfo.name]}
          control={control}
          name={fieldInfo.name}
          defaultValue={item ? item[fieldInfo.name] : undefined}
          {...(fieldInfo.inputProps ? fieldInfo.inputProps : {})}
        />
      )}
    </Grid>
  )
}
