import { Button, CircularProgress, Grid } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router"
import { DiyEventContext, DiyEventsContext } from "../../Providers"
import { DETAIL_EVENT_URL, PROCESS_EVENT_URL, SIGNUP_EVENT_URL } from "../.."
import DiyEventListItem from "./DiyEventListItem"
import { AppPageContent } from "../shared"
import AppMenu from "../shared/AppMenu"

export const DiyEvents = () => {
  const { loadingDiyEvents, diy_events, loadDiyEvents } =
    useContext(DiyEventsContext)
  useEffect(() => {
    loadDiyEvents()
  }, [])
  const history = useHistory()
  return (
    <AppPageContent
      title={
        <>
          Events ({diy_events.length}){" "}
          <AppMenu
            options={[
              {
                label: "Add New Event",
                onSelect: () => history.push(DETAIL_EVENT_URL),
              },
            ]}
          />
        </>
      }
    >
      <div>
        {loadingDiyEvents ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            {diy_events.map((v) => (
              <Grid item key={v.id} xs={12} sm={12} md={6}>
                <DiyEventContext.Provider value={v}>
                  <DiyEventListItem />
                </DiyEventContext.Provider>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </AppPageContent>
  )
}

export default DiyEvents
