import { Instance, types } from "mobx-state-tree"
import { post, del } from "../utils"
import { IdedObject } from "./helpers"

export const Image = types
  .compose(
    "Image",
    IdedObject,
    types.model({
      url: types.maybeNull(types.string),
    })
  )
  .actions((self) => ({}))

export interface IImage extends Instance<typeof Image> {}
export default Image
