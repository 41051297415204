import React, { useContext } from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import { NotificationContext, NotificationsContext } from "."
import NotificationListItem from "./NotificationListItem"

export const Notifications = () => {
  const { loadingNotifications, notifications } =
    useContext(NotificationsContext)
  return (
    <div>
      Notifications ({notifications.length})
      <div>
        {loadingNotifications ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            {notifications.map((v) => (
              <Grid item key={v.id} xs={12} sm={12} md={6}>
                <NotificationContext.Provider value={v}>
                  <NotificationListItem />
                </NotificationContext.Provider>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default Notifications
