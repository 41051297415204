import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { IStore, IDiyEvent } from "../stores"
import { flow } from "lodash"

export const DiyEventContext = React.createContext<IDiyEvent | undefined>(
  undefined
)

interface Props {
  listName: string
  children: React.ReactNode | React.ReactNode[]
}

interface PropsInjected {
  loadDiyEventsList(name: string, args?: any): Promise<IDiyEvent[]>
  loadDiyEvent(id: number): Promise<IDiyEvent>
}

interface ContextProps {
  loadingDiyEvents?: boolean
  diy_events: IDiyEvent[]
  loadDiyEvents(args?: any)
  loadDiyEvent(id: number): Promise<IDiyEvent>
}

const DiyEventsProviderBase = (props: Props & PropsInjected) => {
  const { loadDiyEvent, loadDiyEventsList, listName, children } = props
  const [diy_events, setdiy_events] = useState<IDiyEvent[]>([])
  const [loadingDiyEvents, setLoadingDiyEvents] = useState<boolean>(false)
  const loadDiyEvents = (args) => {
    if (loadingDiyEvents) {
      return
    }
    setLoadingDiyEvents(true)
    loadDiyEventsList(listName, args)
      .then((list) => {
        setdiy_events(list)
        setLoadingDiyEvents(false)
      })
      .catch(() => {
        setLoadingDiyEvents(false)
      })
  }

  return (
    <DiyEventsContext.Provider
      value={{ diy_events, loadDiyEvents, loadingDiyEvents, loadDiyEvent }}
    >
      {children}
    </DiyEventsContext.Provider>
  )
}

export const DiyEventsContext = React.createContext<ContextProps>(undefined)

export const DiyEventsProvider = flow(
  observer,
  inject<PropsInjected, Props>(
    ({
      diy_events: { list, list_ids, loadList, loadingIds, loadedIds, loadById },
    }: IStore) => {
      return {
        loadDiyEvent: loadById,
        loadDiyEventsList: (listName, args) => {
          if (!loadingIds.includes(listName)) {
            return loadList(listName, args).then(() => {
              const ids = list_ids.get(listName) || []
              return list.filter((v) => ids.includes(v.id)) as IDiyEvent[]
            })
          } else {
            const ids = list_ids.get(listName) || []
            return Promise.reject(
              list.filter((v) => ids.includes(v.id)) as IDiyEvent[]
            )
          }
        },
      }
    }
  )
)(DiyEventsProviderBase)
