import React from "react"
import { Conversations } from "../Conversations"
import { DiyEvents } from "../../components/DiyEvents"
// import Vendors from "../Vendors/Vendors"

interface Props {}

export const EventDashboard = (props: Props) => {
  return (
    <div>
      Event Dashboard
      {/* <Vendors /> */}
      <Conversations hideIfEmpty={true} />
      <DiyEvents />
    </div>
  )
}
export default EventDashboard
