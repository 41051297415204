import { Button, Grid } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { debounce } from "lodash"
import {
  IFormFieldInfo,
  ISearchFilter,
  ItemAttribute,
  PAGE_LIMIT_OPTION,
} from "../"
import { GridColumns } from "@mui/x-data-grid"
import { AdminVendorType, IAdminVendorType } from "../../../stores"
import { del, get, hashToParamString, put } from "../../../utils"
import AppMenu from "../../../components/shared/AppMenu"
import AppTable from "../../../components/shared/AppTable"
import AppDialog from "../../../components/shared/AppDialog"
import { useForm } from "react-hook-form"
import {
  TextFieldControl,
  CheckboxControl,
  FileButtonControl,
} from "mui-hook-form-control"
import { Image, Images } from "../../../components/Images"
import { Detail } from "./Detail"
import { ModelForm } from "../shared/ModelForm"

type Props = {
  limit?: number
  height?: number | string
}

export const VENDOR_TYPE_FIELD_INFOS = [
  { name: "name" },
  {
    name: "description",
    inputProps: {
      multiline: true,
      rows: 3,
    },
  },
  {
    name: "featured",
    type: "checkbox",
  },
  {
    name: "active",
    type: "checkbox",
  },
  {
    label: "Image",
    name: "images_attributes[0].file",
    type: "image",
  },
]

const Actions = ({ item, onReload }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  return (
    <>
      <AppDialog
        open={showDetails}
        title='Vendor Type Detail'
        onClose={() => setShowDetails(false)}
      >
        <Detail item={item} />
      </AppDialog>
      <AppDialog
        open={showForm}
        title='Edit'
        onClose={() => setShowForm(false)}
      >
        <ModelForm
          fieldInfos={VENDOR_TYPE_FIELD_INFOS}
          modelName='vendor_type'
          item={item}
          onSubmit={(vendor_type) => {
            put(`/api/admins/vendor_types/${item.id}`, vendor_type, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then(() => {
              setShowForm(false)
              onReload()
            })
          }}
        />
      </AppDialog>
      <AppMenu
        options={[
          {
            label: "Show Detail",
            onSelect: () => {
              setShowDetails(true)
            },
          },
          {
            label: "Edit",
            onSelect: () => {
              setShowForm(true)
            },
          },
          {
            label: "Remove",
            onSelect: () => {
              del(`/api/admins/vendor_types/${item.id}`).then(onReload)
            },
          },
        ]}
      />
    </>
  )
}
export default function VendorTypes({
  limit = PAGE_LIMIT_OPTION[1],
  height,
}: Props) {
  const [rows, setRows] = useState<FIXME[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [filter, setFilter] = useState<ISearchFilter>({
    page: 0,
    page_size: limit,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const loadList = debounce(() => {
    if (loading) {
      return
    }
    setLoading(true)
    get(`/api/admins/vendor_types?${hashToParamString(filter)}`)
      .then(({ data: { list, row_count } }) => {
        setRowCount(row_count)
        setRows(
          list.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
            featured: item.featured,
            active: item.active,
            created_at: moment(item.created_at).fromNow(),
            item: AdminVendorType.create(item),
          }))
        )
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, 500)

  useEffect(() => {
    loadList()
  }, [filter])

  const updateFilter = (name, value) => {
    if (loading) {
      return
    }
    setFilter({ ...filter, [name]: value })
  }

  const handleSortChange = (model, details) => {
    if (loading) {
      return
    }
    setFilter({
      ...filter,
      order_by: model[0].field,
      order_direction: model[0].sort,
    })
  }

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "image",
      headerName: "Image",
      minWidth: 150,
      renderCell: ({ row: { item } }) =>
        item.images[0] ? (
          <div style={{ minWidth: "5rem", minHeight: "5rem" }}>
            <Image image={item.images[0]} />
          </div>
        ) : (
          <></>
        ),
    },
    { field: "name", headerName: "Name", minWidth: 150 },
    { field: "description", headerName: "Description", minWidth: 150 },
    { field: "featured", headerName: "Featured", minWidth: 150 },
    { field: "active", headerName: "Active", minWidth: 150 },
    {
      field: "actions",
      headerName: " ",
      minWidth: 150,
      renderCell: ({ row: { item } }) => (
        <Actions item={item} onReload={() => loadList()} />
      ),
    },
  ]
  return (
    <>
      <AppTable
        pagination
        rowCount={rowCount}
        page={filter.page}
        pageSize={filter.page_size}
        rowsPerPageOptions={PAGE_LIMIT_OPTION}
        onPageSizeChange={(pageSize: number, details?: any) =>
          updateFilter("page_size", pageSize)
        }
        onPageChange={(page) => {
          updateFilter("page", page)
        }}
        rows={rows}
        columns={columns}
        onSortModelChange={handleSortChange}
        filterMode='server'
        paginationMode='server'
        sortingMode='server'
        loading={loading}
        {...(height ? { style: { height } } : {})}
      />
    </>
  )
}

// export const VendorTypeForm = ({
//   item,
//   onSubmit,
// }: {
//   item?: IAdminVendorType
//   onSubmit(data: FIXME)
// }) => {
//   const { handleSubmit, errors, formState, control, reset } = useForm({
//     mode: "onChange",
//   })
//   const { isDirty, isValid, isSubmitting } = formState
//   const handleForm = handleSubmit(({ images_attributes, ...val }: any) => {
//     const data = new FormData()
//     console.log({ images_attributes })
//     images_attributes &&
//       images_attributes.forEach(({ file }) => {
//         if (file) {
//           data.append("[vendor_type]images_attributes[0]file", file)
//         }
//       })

//     for (const key in val) {
//       data.append(`[vendor_type]${key}`, val[key])
//     }
//     console.log({ data, val })
//     onSubmit(data)
//   })
//   return (
//     <form onSubmit={handleForm}>
//       <Grid item>
//         <Grid container spacing={2} direction='column'>
//           {[
//             { name: "name" },
//             {
//               name: "description",
//               inputProps: {
//                 multiline: true,
//                 rows: 3,
//               },
//             },
//             {
//               name: "featured",
//               type: "checkbox",
//             },
//             {
//               name: "active",
//               type: "checkbox",
//             },
//             {
//               label: "Image",
//               name: "images_attributes[0].file",
//               type: "image",
//             },
//           ].map((fieldInfo) => (
//             <ItemAttributeEdit
//               key={fieldInfo.name}
//               item={item}
//               fieldInfo={fieldInfo}
//               errors={errors}
//               control={control}
//             />
//           ))}
//         </Grid>
//       </Grid>
//       <Button type='submit' disabled={!isDirty || !isValid || isSubmitting}>
//         Submit
//       </Button>
//     </form>
//   )
// }
// const ItemAttributeEdit = ({
//   item,
//   fieldInfo,
//   errors,
//   control,
// }: {
//   errors: FIXME
//   control: FIXME
//   item?: IAdminVendorType
//   fieldInfo: IFormFieldInfo
// }) => {
//   let FieldControl = TextFieldControl
//   if (fieldInfo?.type === "checkbox") {
//     FieldControl = CheckboxControl
//   }
//   if (fieldInfo?.type === "image") {
//     FieldControl = FileButtonControl
//   }
//   return (
//     <Grid item>
//       {fieldInfo?.type === "image" ? (
//         <>
//           <FieldControl
//             label={fieldInfo.label || fieldInfo.name}
//             error={errors[fieldInfo.name]}
//             control={control}
//             name={fieldInfo.name}
//             // defaultValue={item ? item[fieldInfo.name] : undefined}
//             {...(fieldInfo.inputProps ? fieldInfo.inputProps : {})}
//           />
//         </>
//       ) : (
//         <FieldControl
//           label={fieldInfo.label || fieldInfo.name}
//           error={errors[fieldInfo.name]}
//           control={control}
//           name={fieldInfo.name}
//           defaultValue={item ? item[fieldInfo.name] : undefined}
//           {...(fieldInfo.inputProps ? fieldInfo.inputProps : {})}
//         />
//       )}
//     </Grid>
//   )
//   // return <ItemAttribute label={fieldInfo.name} value={item[fieldInfo.name]} />
// }
