import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import {
  Button,
  Container,
  Typography,
  Grid,
  Badge,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core"
import { IStore, IVendorType } from "../../stores"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { zod, zodResolver } from "../../utils"
import { CheckboxControl, TextFieldControl } from "mui-hook-form-control"
import { VENDOR_SETUP_UPLOAD_URL } from "../../utils/constants"
import { useParamVendor } from "../../Providers"
import { AppPageContent } from "../../components/shared"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { Image } from "../../components/Images"

function SignupEventPage({
  vendorTypes,
  createVendor,
  showError,
  showSuccess,
}: PropsInjected) {
  const history = useHistory()
  const { control, handleSubmit, errors, setValue, watch } =
    useForm<SignupSchema>({
      resolver: zodResolver(signupSchema),
    })
  const [selected, setselected] = useState<string | number | undefined>()

  const handleForm = (data) => {
    console.log({ data })
    createVendor(data)
      .then((resp) => {
        history.push(VENDOR_SETUP_UPLOAD_URL(resp?.id))
        showSuccess("Saved!")
      })
      .catch(showError)
  }
  console.log({ vendorTypes: watch("vendor_type_id") })

  return (
    <AppPageContent>
      <form onSubmit={handleSubmit(handleForm)}>
        <Container>
          <div style={{ paddingTop: "2rem", maxWidth: "400px" }}>
            <Typography variant='h4' style={{ marginBottom: "2rem" }}>
              YAY! Glad you’re here.
            </Typography>
            <Typography variant='h6' style={{ marginBottom: "1rem" }}>
              What’s your businesses name?
            </Typography>
            <Grid container direction='column' spacing={3}>
              <Grid item>
                <TextFieldControl
                  label='Name of your business'
                  name='name'
                  error={errors?.name}
                  control={control}
                  variant='outlined'
                />
              </Grid>
              <Typography variant='h6' style={{ marginBottom: "1rem" }}>
                Where are you located?
              </Typography>

              <Grid item>
                <TextFieldControl
                  label='Address Line 1'
                  name='address_1'
                  error={errors?.address_1}
                  control={control}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <TextFieldControl
                  label='Address Line 2'
                  name='address_2'
                  error={errors?.address_2}
                  control={control}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <TextFieldControl
                  label='City'
                  name='city'
                  error={errors?.city}
                  control={control}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <TextFieldControl
                  label='State'
                  name='state'
                  error={errors?.state}
                  control={control}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <TextFieldControl
                  label='Zip code'
                  name='zip'
                  error={errors?.zip}
                  control={control}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </div>

          <Typography
            variant='h6'
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            What kind of business are you?
          </Typography>
          <Controller
            as={<input />}
            name='vendor_type_id'
            control={control}
            defaultValue=''
            type='hidden'
          />
          <Grid container spacing={2}>
            {vendorTypes.map((vendorType) => (
              <Grid item key={vendorType.id}>
                <Badge
                  badgeContent={
                    selected === vendorType.id ? <CheckCircleIcon /> : undefined
                  }
                >
                  <Card
                    onClick={() => {
                      setselected(vendorType.id)
                      setValue("vendor_type_id", vendorType.id)
                    }}
                  >
                    <CardActionArea>
                      <div
                        style={{
                          minWidth: "10rem",
                          minHeight: "11rem",
                          padding: "1rem 2rem 0 2rem",
                        }}
                      >
                        <Image image={vendorType.images[0]} />
                      </div>
                      <CardContent style={{ paddingTop: 0 }}>
                        <Typography variant='h6' align='center'>
                          {vendorType.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Badge>
              </Grid>
            ))}
          </Grid>

          <Grid container justifyContent='flex-end' spacing={2}>
            <Grid item>
              <Button variant='outlined' onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='secondary' type='submit'>
                Next
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </AppPageContent>
  )
}

interface PropsInjected {
  vendorTypes: IVendorType[]
  loggedIn: boolean
  createVendor(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup },
    vendors: { createVendor, shouldLoad, loadTypes, vendorTypes },
    vendors,
    app_util: { showError, showSuccess },
  }: IStore) => {
    shouldLoad("vendor_types", loadTypes)
    return {
      vendorTypes,
      loggedIn,
      createVendor,
      showError,
      showSuccess,
    }
  }
)(observer(SignupEventPage))

//  Validations
const signupSchema = zod.object({
  name: zod.string().min(2),
  address_1: zod.string().min(2),
  address_2: zod.string(),
  city: zod.string().min(2),
  state: zod.string().min(2),
  zip: zod.string().min(2),
  vendor_type_id: zod.number(),
})

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
