import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import React from "react"

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  title?: string | React.ReactNode
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    title: {
      marginBottom: theme.spacing(1),
    },
  })
)
export const AppPageContent = ({ children, title }: Props) => {
  const classes = useStyle()
  return (
    <Container className={classes.root}>
      {title && (
        <Typography variant='h6' className={classes.title}>
          {title}
        </Typography>
      )}
      {children}
    </Container>
  )
}

export default AppPageContent
