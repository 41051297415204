import React, { useState } from "react"
import { Controller } from "react-hook-form"
import { FormHelperText } from "@material-ui/core"
import { Editor as DraftWysiwygEditor } from "react-draft-wysiwyg"
import { convertToRaw, EditorState, convertFromRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js"

export const WysiwygControl = ({
  dynamicTags,
  className,
  label,
  options,
  control,
  error,
  name,
  defaultValue,
  toolbarOnFocus = false,
  ...rest
}: any) => {
  const markdownString = defaultValue
  const rawData = markdownToDraft(markdownString)
  const contentState = convertFromRaw(rawData)
  const newEditorState = EditorState.createWithContent(contentState)

  const [eState, setEditorState] = useState(newEditorState)

  return (
    <Controller
      name={name}
      control={control}
      error={!!error}
      helperText={error?.message}
      defaultValue={defaultValue}
      {...rest}
      render={(props) => {
        return (
          <>
            <div className={className}>
              {label && <div className='label xtra-fat-top'>{label}</div>}
              <DraftWysiwygEditor
                toolbarOnFocus={toolbarOnFocus}
                editorClassName='rdw-editor-textarea'
                editorState={eState}
                onEditorStateChange={(val) => {
                  setEditorState(val)
                  const content = val.getCurrentContent()
                  const rawObject = convertToRaw(content)
                  const markdownString = draftToMarkdown(rawObject)
                  props.onChange(markdownString)
                }}
                toolbar={{
                  options: [
                    "inline",
                    "link",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "remove",
                    "list",
                    "textAlign",
                  ],
                  textAlign: { inDropdown: true },
                  inline: { inDropdown: true },
                  link: { inDropdown: true },
                  list: { inDropdown: true },
                }}
              />
            </div>
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )
      }}
    />
  )
}
