import React, { useContext, useEffect } from "react"
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { flow } from "lodash"
import { observer, inject } from "mobx-react"
import { IStore } from "../../../stores"
import { IVendor, IVendorPackage } from "../../../stores/Vendors"
import { withRouter, RouteComponentProps } from "react-router"
import Comments from "../../../components/Comments/Comments"
import VendorPackage from "./VendorPackage"
import { useTranslation } from "react-i18next"
import { useAuth, DiyEventContext, DiyEventsContext } from "../../../Providers"

const VendorPackages = ({ vendor }: Props) => {
  const { t } = useTranslation()
  const { loggedIn } = useAuth()
  useEffect(() => {
    if (
      vendor &&
      !vendor.isLoading("vendor_packages") &&
      !vendor.isLoaded("vendor_packages") &&
      vendor.vendor_packages.length === 0
    ) {
      vendor.loadPackages()
    }
  }, [vendor])

  const { loadingDiyEvents, diy_events, loadDiyEvents } =
    useContext(DiyEventsContext)
  useEffect(() => {
    loggedIn && loadDiyEvents()
  }, [loggedIn])

  if (!vendor) return <CircularProgress />

  return (
    <div>
      <DiyEventContext.Provider value={diy_events[0]}>
        <Typography variant='h5'>{t("common.vendorPackage")}</Typography>
        <div>
          <Grid container spacing={2}>
            {vendor.vendor_packages.map((vendorPackage) => (
              <Grid item key={vendorPackage.id} xs={12} md={6}>
                <VendorPackage vendorPackage={vendorPackage} />
              </Grid>
            ))}
          </Grid>
        </div>
      </DiyEventContext.Provider>
    </div>
  )
}

interface Props {
  vendor: IVendor
}

export default flow(observer)(VendorPackages)
