import React from "react"
import { inject, observer } from "mobx-react"
import { IStore } from "../stores"
import { flow } from "lodash"

const ToastProvider = (props: Props & PropsInjected) => {
  const { showError, showSuccess, children } = props

  return (
    <ToastContext.Provider value={{ showError, showSuccess }}>
      {children}
    </ToastContext.Provider>
  )
}

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

const defaultValue = {
  showError: (c: string) => {},
  showSuccess: (c: string) => {},
}

interface PropsInjected {
  showError(c: string)
  showSuccess(c: string)
}

export const ToastContext = React.createContext(defaultValue)
export const useToast = () => React.useContext(ToastContext)

export default flow(
  observer,
  inject<PropsInjected, Props>(
    ({ app_util: { showError, showSuccess } }: IStore) => {
      return {
        showError,
        showSuccess,
      }
    }
  )
)(ToastProvider)
