import Axios from "axios"
import { types, Instance } from "mobx-state-tree"
import { get } from "../../utils"
import { ListIdManager } from "../helpers/ListIdManager"
import { queryString } from "../utils"
import { VendorPackageRequest } from "./vendor_package_request"

export const VendorPackageRequests = types
  .compose(
    "VendorPackageRequests",
    ListIdManager,
    types.model({
      list: types.optional(types.array(VendorPackageRequest), []),
    })
  )
  .actions((self) => ({
    loadList(listName, args) {
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return get(`/api/vendor_package_requests?${queryString(args)}`)
        .then(({ data }) => {
          self.stopLoading(listName)
          return self.setList(listName, data)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(listName)
        })
    },
    loadById(id: number): Promise<any> {
      if (self.isLoading(id)) {
        return Promise.reject(undefined)
      }
      self.startLoading(id)
      return get(`/api/vendor_package_requests/${id}`)
        .then(({ data }) => {
          self.stopLoading(id)
          self.setList(id, [data])
          return self.list.find((v) => v.id === id)
        })
        .catch((err) => {
          console.log(err)
          self.stopLoading(id)
        })
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // self.loadList("list")
    },
  }))

export interface IVendorPackageRequests
  extends Instance<typeof VendorPackageRequests> {}
export default VendorPackageRequests
