import React, { useContext } from "react"
import {
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core"
import { flow } from "lodash"
import { observer } from "mobx-react"
import { IVendorPackage } from "../../../stores/Vendors"
import Comments from "../../../components/Comments/Comments"
import ReactMarkdown from "react-markdown"
import { AuthContext, useAuth, DiyEventContext } from "../../../Providers"
import { Images } from "../../../components/Images"
import EditIcon from "@material-ui/icons/Edit"
import { useParamVendor } from "../../../Providers"
import { VENDOR_SETUP_EDIT_VENDOR_PACKAGE_URL } from "../../.."
import { useHistory } from "react-router"
import DeleteIcon from "@material-ui/icons/Delete"

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const VendorPackage = ({ vendorPackage }: Props) => {
  const diy_event = useContext(DiyEventContext)
  const { vendor, loadingVendor: loading, isOwner } = useParamVendor()
  const auth = useAuth()
  const history = useHistory()
  if (!vendorPackage) {
    return <></>
  }
  return (
    <Card style={{ padding: "1rem" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5'>{vendorPackage?.name}</Typography>
          {isOwner && (
            <IconButton
              aria-label='edit'
              onClick={() =>
                history.push(
                  VENDOR_SETUP_EDIT_VENDOR_PACKAGE_URL(
                    vendor.id,
                    vendorPackage.id
                  )
                )
              }
            >
              <EditIcon />
            </IconButton>
          )}
          {isOwner && (
            <IconButton
              aria-label='edit'
              onClick={() => {
                vendor.deleteVendorPackage(vendorPackage.id)
                // history.push(
                //   VENDOR_SETUP_EDIT_VENDOR_PACKAGE_URL(
                //     vendor.id,
                //     vendorPackage.id
                //   )
                // )
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <Images imageable={vendorPackage} loadImages={false} />
        </Grid> */}
        <Grid item xs={12}>
          {formatter.format(vendorPackage?.price)}{" "}
          {/* /
          {vendorPackage?.quantity}{" "}
          left*/}
          {/* / rating: {vendorPackage.average_rating} */}
        </Grid>
        <Grid item xs={12}>
          <ReactMarkdown>{vendorPackage?.description}</ReactMarkdown>
        </Grid>

        {/* {diy_event && (
          <Button
            variant='contained'
            onClick={() => vendorPackage.requestPackage(diy_event)}
          >
            Request for
          </Button>
        )} */}
        {/* <Grid item xs={12}>
          {vendorPackage && (
            <Comments
              commentable={vendorPackage}
              preLoad={false}
              label={"Comment on Package"}
            />
          )}
        </Grid> */}
      </Grid>
    </Card>
  )
}

interface Props {
  vendorPackage: IVendorPackage
}

export default flow(observer)(VendorPackage)
