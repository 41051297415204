import { types, Instance } from "mobx-state-tree"

const Editable = types
  .model("Editable", {
    dirty: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setDirty: (val: boolean) => {
      self.dirty = val
    },
    updateMe: (val, { dirty = false } = {}) => {
      Object.assign(self, val)
      self.dirty = dirty
    },
  }))

export interface IEditable extends Instance<typeof Editable> {}
export default Editable
