import {
  CircularProgress,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Container,
  Typography,
} from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { VendorsContext, VendorContext, VendorsProvider } from "../../Providers"
import { ProgressLoader } from "../shared/ProgressLoader"
import VendorListItem from "./VendorListItem"
import Vendors from "./Vendors"

export const TitledVendors = ({
  name,
  label,
  filters,
}: {
  name: string
  label?: string
  filters?: FIXME
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {label && <Typography>{label}</Typography>}
      <VendorsProvider listName={name} filters={filters}>
        <Vendors />
      </VendorsProvider>
    </div>
  )
}

export default TitledVendors
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: "1rem auto",
      // height: "100%",
    },
    gridContainer: {
      maxWidth: "70rem",
      margin: "1rem auto",
    },
    grid: {
      // margin: "1rem auto",
    },
  })
)
