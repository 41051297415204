import i18n from "i18next"
import resources from "./locale"

// initialize i18next with catalog and language to use
i18n.init({
  resources,
  lng: "en",
})

export const languageOptions = [
  { key: "English", text: "English", value: "en" },
  { key: "Amharic", text: "Amharic", value: "am" },
]

export default i18n
