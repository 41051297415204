import React, { useContext } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { NotificationContext } from "."
import ReactMarkdown from "react-markdown"
// import { VENDOR_URL } from "../../"

const NotificationListItem = ({ history }: Props & RouteComponentProps) => {
  const notification = useContext(NotificationContext)
  const classes = useStyles()
  return (
    <Card
      className={classes.root}
      // onClick={() => history.push(VENDOR_URL(notification.id))}
    >
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography>{notification.body}</Typography>
        </CardContent>
      </div>
    </Card>
  )
}

interface Props {}

export default withRouter(NotificationListItem)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // minWidth: "25em",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
)
