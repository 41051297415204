import { Button } from "@material-ui/core"
import React, { useContext } from "react"
import { useAuth } from "../../Providers"

import { IUserVendor } from "../../stores"
import { useNewConversations } from "../../Providers"
import { toast } from "react-toastify"

export const MessagableUser = ({
  user,
  children,
}: {
  user: IUserVendor
  children?: FIXME
}) => {
  const { sendMessageTo } = useNewConversations()
  const { loggedIn } = useAuth()

  const handleClick = () => {
    console.log({ sendMessageTo })
    if (loggedIn) {
      sendMessageTo([user.user_id])
    } else {
      toast(`Please Signin to message ${user.user_name}`)
    }
  }

  return <Button onClick={handleClick}>{children || user.user_name}</Button>
}
