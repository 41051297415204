import { Avatar } from "@material-ui/core"
import { AccountCircle } from "@material-ui/icons"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import React from "react"
import { IVendor } from "../../stores"
import { IUser } from "../../stores/auth"

type Props = { user: IUser }

export default function UserAvatar({ user }: Props) {
  return user.avatar_url ? (
    <Avatar src={user.avatar_url} />
  ) : (
    <AccountCircle fontSize='large' />
  )
}
export function VendorAvatar({
  vendor,
  variant = "rounded",
}: {
  vendor: IVendor
  variant?: "circle" | "circular" | "rounded" | "square"
}) {
  return vendor?.images && vendor?.images[0]?.url ? (
    <Avatar variant={variant} src={vendor?.images[0]?.url} />
  ) : (
    <PhotoSizeSelectActualIcon fontSize='large' />
  )
}
