import React, { useContext } from "react"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@material-ui/core"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { ConversationContext, MessagesContext } from "../../Providers"
import { Messages } from "./Messages"
import BoxedContent from "../../components/shared/BoxedContent"

const ConversationListItem = ({ history }: Props & RouteComponentProps) => {
  const conversation = useContext(ConversationContext)
  const classes = useStyles()
  return (
    <BoxedContent>
      <div className={classes.details}>
        {/* <CardContent className={classes.content}> */}
        <Typography>
          By:{" "}
          {conversation.recipients
            .map((user) => `${user.fullName()} (${user.email})`)
            .join(", ")}
        </Typography>
        {/* <Typography>{conversation.subject}</Typography> */}
        <MessagesContext.Provider value={{ messages: conversation.messages }}>
          <Messages />
        </MessagesContext.Provider>
        {/* </CardContent> */}
      </div>
    </BoxedContent>
  )
}

interface Props {}

export default withRouter(ConversationListItem)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // minWidth: "25em",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
)
