import { types } from "mobx-state-tree"
import moment from "moment"

export const IdedObject = types
  .model("IdedObject", {
    id: types.union(types.identifierNumber, types.identifier),
    // created_at: types.string,
  })
  .actions((self) => ({
    updateSelf: (data) => {
      Object.assign(self, data)
    },
  }))
