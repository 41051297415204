import React from "react"
import {
  Container,
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"

interface AppMenuOption {
  label: string
  onSelect()
}

export const AppMenu = ({ children, options, position }: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <span onClick={handleOpenMenu} className={classes.menuButton}>
        {children || (
          <IconButton size='small'>
            <MoreVertIcon fontSize='small' />
          </IconButton>
        )}
      </span>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal:
            position === "left"
              ? "right"
              : position === "right"
              ? "left"
              : "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal:
            position === "left"
              ? "right"
              : position === "right"
              ? "left"
              : "center",
        }}
        getContentAnchorEl={undefined}
      >
        {options.map((mo) => (
          <MenuItem
            key={mo.label}
            onClick={() => {
              handleClose()
              mo.onSelect()
            }}
          >
            <Typography variant='body2' color='textSecondary'>
              {mo.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  options?: AppMenuOption[]
  position?: "left" | "right"
}

export default AppMenu

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // padding: "1rem 0",
    },
    menuButton: {
      // top: "2rem",
      // float: "right",
      // position: "relative",
      // margin: "-1.5rem .5rem 0 0",
    },
  })
)
