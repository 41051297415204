import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  CardActionArea,
  Badge,
  Checkbox,
} from "@material-ui/core"
import { IStore } from "../../../stores"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { zod, zodResolver } from "../../../utils"
import { CheckboxControl, TextFieldControl } from "mui-hook-form-control"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { PROCESS_EVENT_URL } from "../../../utils/constants"
import { MarrageIcon } from "../shared/MarrageIcon"
import { VendorIcon } from "../shared/VendorIcon"

function SignupEventPage({ signup, showError, showSuccess }: PropsInjected) {
  const history = useHistory()
  const { control, handleSubmit, errors } = useForm<SignupSchema>({
    resolver: zodResolver(signupSchema),
  })

  const handleLogin = (data) => {
    // console.log({ data })
    signup(data)
      .then(() => history.push(PROCESS_EVENT_URL))
      .catch(showError)
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div style={{ paddingTop: "2rem", maxWidth: "400px" }}>
          <Typography variant='h4' style={{ marginBottom: "2rem" }}>
            Woo! You’re engaged!{" "}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextFieldControl
                label='First Name'
                name='first_name'
                error={errors?.first_name}
                control={control}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControl
                label='Last Name'
                name='last_name'
                error={errors?.last_name}
                control={control}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                label='Email'
                name='email'
                error={errors?.email}
                control={control}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                label='Password'
                name='password'
                error={errors?.password}
                type={"password"}
                control={control}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                label='Confirm Password'
                name='password_confirmation'
                error={errors?.password_confirmation}
                type={"password"}
                control={control}
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <CheckboxControl
                name='terms'
                control={control}
                error={errors?.terms}
                label={
                  <span>
                    I agree to the{" "}
                    <a href='/' target='_blank'>
                      terms and conditions
                    </a>
                  </span>
                }
              />
            </Grid>
            <Grid item>
              {errors?.terms && (
                <Typography variant='body1' color='error'>
                  Must accept the terms and conditions
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Button variant='outlined' onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='secondary' type='submit'>
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

interface PropsInjected {
  loggedIn: boolean
  signup(data): Promise<any>
  showError(c: string)
  showSuccess(c: string)
}
interface Props {}

export default inject<PropsInjected, Props>(
  ({
    auth: { loggedIn, signup },
    app_util: { showError, showSuccess },
  }: IStore) => {
    return {
      loggedIn,
      signup,
      showError,
      showSuccess,
    }
  }
)(observer(SignupEventPage))

//  Validations
const signupSchema = zod
  .object({
    first_name: zod.string().min(2),
    last_name: zod.string().min(2),
    email: zod.string().email(),
    password: zod.string().min(6),
    password_confirmation: zod.string().min(6),
    terms: zod.boolean(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ["password_confirmation"],
  })
  .refine((data) => data.terms, {
    message: "Must accept the terms",
    path: ["terms"],
  })

// export default SignupSchema
type SignupSchema = zod.infer<typeof signupSchema>
